import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFeatureConfigsQuery, useSetFeatureConfigMutation } from 'src/client/app/api/siteApi';
import { getSelectedSite } from '../../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
import { AutomaticWordPressUpdates } from "./AutomaticWordPressUpdates";
import { WordPressEmailSettings } from "./WordPressEmailSettings";
import { Card } from "src/client/features/ui";
export function WordPressSettings() {
    const selectedSite = useAppSelector(getSelectedSite);
    const [setConfig, { isLoading, isError }] = useSetFeatureConfigMutation();
    const toast = useToaster();
    const { data } = useGetFeatureConfigsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const onChange = async (config) => {
        console.log('settings onchange', config);
        try {
            await setConfig({ config, website: selectedSite.Website }).unwrap();
            toast({
                title: 'Success!',
                message: 'Your configuration change has been saved.',
                type: 'success'
            });
        }
        catch (e) {
            toast({
                title: 'Error!',
                message: e.data.message ?? 'An error occured! Please refresh and try again.',
                type: 'error'
            });
        }
    };
    return (_jsxs("div", { className: "space-y-4 relative", children: [_jsx(AutomaticWordPressUpdates, { configName: WebsiteFeatureConfigFields.WORDPRESS_AUTO_UPDATES, config: data?.config.WORDPRESS_AUTO_UPDATES, website: selectedSite, onChange: onChange }, "wordpress-updates"), _jsx(WordPressEmailSettings, { configName: WebsiteFeatureConfigFields.EMAIL_GATEWAY, config: data?.config.EMAIL_GATEWAY, website: selectedSite, onChange: onChange }, "wordpress-email"), _jsxs(Card, { title: "WordPress", className: "h-fit", collapsable: true, children: ["enable/disable WordPress features", _jsxs("ul", { className: "list-disc pl-4", children: [_jsx("li", { children: "Enable CloudFlare Interoperability" }), _jsx("li", { children: "Replace WordPress Admin logo + URL" }), _jsx("li", { children: "Disable all comments and pingbacks" }), _jsx("li", { children: "Disable XML-RPC, WP-JSON, etc" })] })] }, "wordpress-other")] }));
}
