import { api } from './api';
export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: ['Auth']
        }),
        googleSsoLogin: build.mutation({
            query: (code) => ({
                url: 'auth/login/sso/google',
                method: 'POST',
                body: code,
            }),
            invalidatesTags: ['Auth']
        }),
        logout: build.mutation({
            query: (session) => ({
                url: 'auth/logout',
                method: 'POST',
                body: { accessToken: session.token.token }
            }),
            invalidatesTags: ['Auth', 'Site', 'Organization']
        }),
        startPasswordReset: build.mutation({
            query: (email) => ({
                url: 'auth/start-password-reset',
                method: 'POST',
                body: { email },
            }),
            invalidatesTags: ['Auth']
        }),
        setNewPassword: build.mutation({
            query: (req) => ({
                url: 'auth/set-password',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: ['Auth']
        }),
        refreshToken: build.mutation({
            query: (session) => ({
                url: 'auth/refresh',
                method: 'POST',
                body: {
                    refreshToken: session.token.refresh
                }
            }),
            invalidatesTags: ['Auth']
        })
    })
});
export const { useLoginMutation, useGoogleSsoLoginMutation, useLogoutMutation, useStartPasswordResetMutation, useSetNewPasswordMutation, useRefreshTokenMutation, } = authApi;
export default authApi;
