import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Icons } from './Icon';
import { ToggleTheme } from '../chrome/Header/ToggleTheme';
import { useToaster } from '../toaster/useToaster';
export function AllIcons() {
    const toast = useToaster();
    const allIcons = Object.keys(Icons);
    const copy = (i) => {
        navigator.clipboard.writeText(i);
        toast({
            'type': 'success',
            'title': 'Copied to Clipboard',
            'message': `Copied icon '${i}' to clipboard.`
        });
    };
    return (_jsx("div", { className: "min-h-screen p-4", children: _jsxs("div", { className: "flex flex-col space-y-6", children: [_jsx("div", { className: "flex flex-row", children: _jsxs("div", { className: "flex items-center bg-white dark:bg-gray-900/50 rounded-full shadow-md p-1", children: [_jsx(ToggleTheme, {}), " ", _jsx("span", { className: "px-4 text-xs uppercase", children: "Toggle Color Scheme" })] }) }), _jsx("div", { className: "grid grid-cols-4 gap-4", children: allIcons.map(i => (_jsxs("div", { onClick: () => copy(i), className: "flex flex-col text-center bg-blue-100 dark:bg-gray-900/50 p-4 rounded-lg space-y-4 hover:bg-blue-200 hover:dark:bg-gray-900/60 hover:cursor-pointer group", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Icon, { className: "w-12 h-12 mx-auto", icon: i }), _jsx("br", {})] }), _jsx("span", { className: "text-gray-500 dark:text-gray-400 group-hover:text-gray-900 group-hover:dark:text-white", children: i })] }, i))) })] }) }));
}
