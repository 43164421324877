import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Button, Information, Toggle, Icon, Textarea, Confirm } from 'src/client/features/ui';
import { LastChanged } from '../LastChanged';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
import { useGetFeatureConfigQuery, useSetFeatureConfigMutation } from 'src/client/app/api/siteApi';
import { useAppSelector } from 'src/client/app/hooks';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { getSelectedSite } from '../../siteSlice';
import { useState } from 'react';
export function Ssl() {
    const selectedSite = useAppSelector(getSelectedSite);
    const [setConfig, { isLoading, isError }] = useSetFeatureConfigMutation();
    const [useManagedCertificate, _setUseManagedCertificate] = useState(true);
    const [confirmToggleManagedCertificate, setConfirmToggleManagedCertificate] = useState();
    const setUseManagedCertificate = (newState) => {
        if (newState === true) {
            if (1) {
                setConfirmToggleManagedCertificate('Switching to automatic SSL certificates will immediately and permanently delete your current certificate, and a new Let\'s Encrypt certificate will be created for your website. Are you sure you want to continue?');
            }
            else {
                _setUseManagedCertificate(newState);
            }
        }
        else {
            setConfirmToggleManagedCertificate('Disabling this feature will permanently disable your Let\'s Encrypt certificate, and you will be responsible for manually handling future certificate renewals. Are you sure you want to continue?');
        }
    };
    const toggleUseManagedCertificate = () => {
        _setUseManagedCertificate(!useManagedCertificate);
        setConfirmToggleManagedCertificate(undefined);
    };
    const toast = useToaster();
    const { data } = useGetFeatureConfigQuery({ website: selectedSite.Website, configField: WebsiteFeatureConfigFields.SSL_CERTIFICATE }, { skip: selectedSite.Website === undefined });
    const onToggleConfig = async (value) => {
        try {
            await setConfig({
                config: {
                    configField: WebsiteFeatureConfigFields.SSL_CERTIFICATE,
                    isEnabled: value,
                    configValue: null,
                },
                website: selectedSite.Website
            });
            toast({
                title: 'Success!',
                message: 'Your configuration change has been saved.',
                type: 'success'
            });
        }
        catch (e) {
            toast({
                title: 'Error!',
                message: e.data.message ?? 'An error occured! Please refresh and try again.',
                type: 'error'
            });
        }
    };
    return (_jsxs("div", { className: "space-y-4 relative", children: [_jsx(Confirm, { isOpen: !!confirmToggleManagedCertificate, title: "Are you sure?", message: confirmToggleManagedCertificate, requiredTextToConfirm: "confirm", instructionsRequiredTextToConfirm: "Type 'confirm' in order to continue", onConfirm: toggleUseManagedCertificate, onCancel: () => setConfirmToggleManagedCertificate(undefined) }), _jsxs(Card, { title: "Enable SSL", children: [_jsx(Information, { children: "Enabling SSL is highly recommended. This helps encrypt your website traffic while it's transmitted over the internet, and lets your website visitors know that they've connected to an authentic website." }), _jsx(Toggle, { checked: !!data?.config.SSL_CERTIFICATE?.isEnabled, onChange: onToggleConfig, label: "Enable SSL encryption?", disabled: !!data?.config.SSL_CERTIFICATE?.isLocked }), _jsx(LastChanged, { config: data?.config.SSL_CERTIFICATE })] }), data?.config.SSL_CERTIFICATE?.isEnabled &&
                _jsxs(Card, { title: "Certificate Options", children: [_jsxs(Information, { children: ["We integrate with ", _jsxs("a", { rel: "noreferrer noopener", className: "info-link", href: "https://letsencrypt.org/", target: "_BLANK", children: ["Let's Encrypt", _jsx(Icon, { icon: "openExternal" })] }), " to provision and manage certificates for this site. You can opt to disable this integration and upload your own SSL certificates instead."] }), _jsx("div", { className: "py-6", children: _jsx(Toggle, { checked: useManagedCertificate, onChange: setUseManagedCertificate, label: "Use automatically-provisioned SSL certificates?" }) }), !useManagedCertificate &&
                            _jsxs("div", { className: "bg-gray-100 dark:bg-gray-900/25 rounded rounded-lg text-gray-600 dark:text-gray-400", children: [_jsx("header", { className: "border-b border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-400 font-bold text-sm uppercase p-4", children: "Upload Custom SSL Certificate" }), _jsxs("div", { className: "flex flex-col space-y-6 p-8", children: [_jsxs("div", { children: [_jsx("label", { className: "label", htmlFor: "ssl-certificate", children: _jsx("span", { className: "label-text text-xs", children: "SSL Certificate" }) }), _jsx(Textarea, { id: "ssl-certificate", className: "h-24" })] }), _jsxs("div", { children: [_jsx("label", { className: "label", htmlFor: "ssl-privatekey", children: _jsx("span", { className: "label-text text-xs", children: "Private Key" }) }), _jsx(Textarea, { id: "ssl-privatekey", className: "h-24" })] })] }), _jsxs("footer", { className: "flex flex-row justify-between bg-gray-200 dark:bg-gray-900/25 rounded-b rounded-b-lg p-4", children: [_jsx(LastChanged, { config: data?.config.SSL_CERTIFICATE }), _jsx(Button, { children: "Save SSL Certificate" })] })] })] })] }));
}
