import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useGetFeatureConfigQuery } from 'src/client/app/api/siteApi';
import { Card } from 'src/client/features/ui';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
import { PurgeCache } from "./PurgeCache";
import { Link } from 'react-router-dom';
export function ServerCache() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data: siteConfig } = useGetFeatureConfigQuery({
        website: selectedSite.Website,
        configField: WebsiteFeatureConfigFields.WORDPRESS
    }, { skip: selectedSite.Website === undefined });
    return (_jsxs("div", { className: "space-y-4", children: [_jsx(PurgeCache, {}), siteConfig?.config.WORDPRESS && siteConfig?.config.WORDPRESS.isEnabled &&
                _jsxs(Card, { title: "PHP Object Cache", children: ["Advanced WordPress-specific functionality is enabled for this website.", _jsx("br", {}), "You can configure PHP Object Cache options ", _jsx(Link, { to: "/dashboard/product/sites/wordpress/settings", children: "here" })] })] }));
}
