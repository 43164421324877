export const getReadableFilesize = (size) => {
    const s = 1024;
    let b = Number(size);
    let u = 0;
    while (b >= s || -b >= s) {
        b = b / s;
        u++;
    }
    return (u ? b.toFixed(1) + '' : b) + ' KMGTPEZY'[u] + 'B';
};
