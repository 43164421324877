import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag } from "../../ui";
import { useNavigate } from "react-router";
const ADMIN_TOOLS = [
    {
        tag: 'System',
        title: 'Server Agent Status',
        route: 'agent-status'
    },
    {
        tag: 'UI',
        title: 'All Icons',
        route: 'ui-icons'
    }
];
export function AdminTools() {
    const navigate = useNavigate();
    return (_jsx("div", { className: "flex flex-col space-y-4 py-4", children: ADMIN_TOOLS.map(tool => (_jsxs("div", { onClick: () => navigate(tool.route), className: "bg-blue-100 dark:bg-gray-900/50 p-4 rounded-lg space-y-4 hover:bg-blue-200 hover:dark:bg-gray-900/60 hover:cursor-pointer group", children: [_jsx(Tag, { size: "small", children: tool.tag }), _jsx("p", { children: tool.title })] }, tool.title))) }));
}
