import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Routes, Route, Navigate, useLocation, Link } from 'react-router-dom';
import { Appwrap } from 'src/client/features/ui/chrome/Appwrap';
import { Spinner, Alert, Card } from 'src/client/features/ui';
import { useGetMyOrganizationQuery } from 'src/client/app/api/organizationApi';
import { OrganizationOverview } from './OrganizationOverview';
import { OrganizationMembers } from './OrganizationMembers';
import { OrganizationWebsites } from './OrganizationWebsites';
import { OrganizationEditMember } from './OrganizationEditMember';
import { OrganizationBilling } from './OrganizationBilling';
import { useGetCurrentUserQuery } from 'src/client/app/api/userApi';
const ORG_BASE_PAGES = ['Overview', 'Members', 'Websites'];
const ORG_OWNER_PAGES = ['Billing'];
export function Organization() {
    const { data: currentUser, error: loadUserError, isLoading: isLoadingUser } = useGetCurrentUserQuery();
    const { data: currentOrganization, error: loadOrgError, isLoading: isLoadingOrganization } = useGetMyOrganizationQuery();
    const isLoading = (isLoadingOrganization);
    const location = useLocation();
    let orgPageProps;
    const error = loadUserError ?? loadOrgError;
    let membership = undefined;
    if (currentUser && currentOrganization) {
        const { Users: users, Websites: websites, UserOrganizationMembership: memberships, ...organization } = currentOrganization;
        orgPageProps = { currentUser, users, websites, organization, memberships };
        membership = orgPageProps.memberships.find(m => m.userId === currentUser?.id);
    }
    const ORG_PAGES = [...ORG_BASE_PAGES, ...(membership && membership.role === 'ORGOWNER' ? ORG_OWNER_PAGES : [])];
    return (_jsx(Appwrap, { className: "flex flex-col", children: _jsxs(Card, { title: `Organization Settings ${currentOrganization && `for ${currentOrganization.name}`}`, className: "m-4", children: [isLoading && _jsx(Spinner, {}), (!isLoading && error) && _jsx(Alert, { type: "error", children: "An error occured while loading organization data. Please refresh and try again." }), (!isLoading && !error && orgPageProps) && (_jsxs(_Fragment, { children: [_jsx("ul", { className: "flex flex-wrap font-bold text-sm text-center text-gray-500 dark:text-gray-400 mb-6", children: ORG_PAGES.map((page) => {
                                const url = `/organization/${page.toLowerCase()}`;
                                const isActive = location.pathname.startsWith(url);
                                let classes = 'inline-block cursor-pointer py-2 px-2 mx-2 first:ml-0 hover:text-black dark:hover:text-white uppercase';
                                if (isActive) {
                                    classes += ' active text-black dark:text-white hover:text-black dark:hover:text-white border-b-2 border-blue-600';
                                }
                                return (_jsx("li", { className: "mr-2", children: _jsx(Link, { to: url, className: classes, children: page }) }, page));
                            }) }), _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "overview" }) }), _jsx(Route, { path: "overview", element: _jsx(OrganizationOverview, { ...orgPageProps }) }), _jsx(Route, { path: "members/edit/:userId", element: _jsx(OrganizationEditMember, { ...orgPageProps }) }), _jsx(Route, { path: "members", element: _jsx(OrganizationMembers, { ...orgPageProps }) }), _jsx(Route, { path: "websites", element: _jsx(OrganizationWebsites, { ...orgPageProps }) }), _jsx(Route, { path: "billing", element: _jsx(OrganizationBilling, { ...orgPageProps }) })] })] }))] }) }));
}
