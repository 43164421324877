import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Appwrap } from 'src/client/features/ui/chrome/Appwrap';
import { Card, Spinner } from 'src/client/features/ui';
import { useGetCurrentUserQuery } from 'src/client/app/api/userApi';
import { MyInformation, DisplaySettings, UpdatePassword, TwoFactorAuthentication } from './AccountPageSections';
import { PendingInvitations } from './AccountPageSections/PendingInvitations';
export function Account() {
    const { data: user, isLoading } = useGetCurrentUserQuery();
    return (_jsx(Appwrap, { children: _jsxs(Card, { title: "Account Settings", className: "m-4", children: [isLoading && _jsx(Spinner, {}), (user && !isLoading) &&
                    _jsxs(_Fragment, { children: [_jsx(PendingInvitations, { user: user }), _jsxs("div", { className: "mx-auto divide-y-2 divide-gray-300 dark:divide-gray-700", children: [_jsx(MyInformation, { user: user }), _jsx(DisplaySettings, { user: user }), _jsx(UpdatePassword, { user: user }), _jsx(TwoFactorAuthentication, { user: user })] })] })] }) }));
}
