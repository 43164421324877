import { jsx as _jsx } from "react/jsx-runtime";
const defaultButtonProps = {
    role: 'regular',
    buttonSize: 'normal',
    border: true,
};
export function Button(props) {
    const mergedProps = { ...defaultButtonProps, ...props };
    const roleStyles = {
        regular: `
            hover:bg-blue-500 text-blue-500 hover:text-white border-blue-500
            disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-400
            disabled:dark:bg-gray-600 disabled:dark:border-gray-600 disabled:dark:text-gray-800
            disabled:dark:hover:bg-gray-600 disabled:dark:hover:text-gray-800
        `,
        secondary: `
            text-slate-600 dark:text-gray-400 border-gray-300 dark:border-gray-500
            hover:text-gray-800 hover:border-gray-800
            dark:hover:text-gray-300 dark:hover:border-gray-400
        `,
        primary: 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400',
    };
    const sizeStyles = {
        normal: 'text-sm p-1 px-4',
        large: 'text-md p-2 px-6',
    };
    const className = `
        rounded 
        ${mergedProps.border ? 'border hover:border-transparent ' : ''} 
        ${sizeStyles[mergedProps.buttonSize ?? 'normal']} 
        ${roleStyles[mergedProps.role ?? 'regular']} 
        ${mergedProps.className} 
    `;
    return (_jsx("button", { className: className, onClick: mergedProps.onClick, title: mergedProps.title, disabled: mergedProps.disabled, children: mergedProps.children }));
}
