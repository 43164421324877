import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFeatureConfigsQuery, useGetPropertyQuery } from 'src/client/app/api/siteApi';
import { getSelectedSite } from '../../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { Card, Information } from 'src/client/features/ui';
import { Environment } from 'src/shared/types.g';
import { EnvironmentItem } from './EnvironmentItem';
const getWebsiteForEnvironment = (property, environment) => {
    return property.Websites.find(s => s.environment === environment);
};
export function Environments() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data: features } = useGetFeatureConfigsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const { data: property } = useGetPropertyQuery(selectedSite.id, { skip: selectedSite === undefined });
    return (_jsx("div", { children: _jsxs(Card, { title: "Manage Environments", children: [_jsxs(Information, { children: ["Your website hosting includes multiple environments.", _jsx("br", {}), "These provide handy spaces to work on new website features, try out various settings, and more.", _jsx("br", {}), _jsx("br", {}), "Please note that some advanced features will only be available on your Production environment."] }), property && Object.keys(Environment).map(env => _jsx("div", { children: _jsx(EnvironmentItem, { environment: env, websiteProperty: property, website: getWebsiteForEnvironment(property, env) }) }, env))] }) }));
}
