import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router';
import { authActions } from './authActions';
import { useAppDispatch } from '../../app/hooks';
import { DEFAULT_PUBLIC_ROUTE } from '../../app/app.consts';
import { googleLogout } from '@react-oauth/google';
export function Logout() {
    const dispatch = useAppDispatch();
    dispatch(authActions.logout());
    try {
        googleLogout();
    }
    catch (e) {
    }
    return _jsx(Navigate, { to: DEFAULT_PUBLIC_ROUTE });
}
