import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Alert, Button, Confirm } from "../../ui";
import { useGetMyPendingInvitationsQuery } from 'src/client/app/api/userApi';
import { useCancelInvitationMutation, useAcceptInvitationMutation } from "src/client/app/api/organizationApi";
import { useToaster } from "../../ui/toaster/useToaster";
export function PendingInvitations(props) {
    const { data: invitations, isLoading } = useGetMyPendingInvitationsQuery();
    const [invitationToCancel, setInvitationToCancel] = useState();
    const [cancelInvitation, { isLoading: isCancelling }] = useCancelInvitationMutation();
    const [acceptInvitation, { isLoading: isAccepting }] = useAcceptInvitationMutation();
    const toast = useToaster();
    const getInviterDisplayName = (invitation) => {
        return (invitation.sentBy.firstName && invitation.sentBy.lastName) ?
            `${invitation.sentBy.firstName} ${invitation.sentBy.lastName}` :
            invitation.sentBy.email;
    };
    const doCancelInvitation = async () => {
        if (invitationToCancel) {
            await cancelInvitation(invitationToCancel).unwrap();
        }
        setInvitationToCancel(undefined);
    };
    const doAcceptInvitation = async (invitation) => {
        await acceptInvitation(invitation).unwrap();
        toast({
            type: 'success',
            title: 'Invitation Accepted!',
            message: `You are now a member of ${invitation.newUserOrg.name}`,
        });
    };
    return (_jsxs(_Fragment, { children: [(!invitations || invitations.length === 0) && _jsx(_Fragment, {}), _jsxs("div", { className: "flex flex-col space-y-2 py-4", children: [_jsx(Confirm, { isOpen: !!invitationToCancel, title: "Are you sure?", message: "Are you sure you want to cancel this invitation?", confirmButtonText: isCancelling ? 'Cancelling...' : 'Confirm Cancellation', cancelButtonText: "Go Back", onConfirm: doCancelInvitation, onCancel: () => setInvitationToCancel(undefined) }), invitations?.map((invitation) => (_jsx(Alert, { type: "message", children: _jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { children: [_jsx("strong", { children: getInviterDisplayName(invitation) }), "\u00A0has invited you to join the ", _jsx("strong", { children: invitation.newUserOrg.name }), " organization."] }), _jsxs("div", { children: [_jsx(Button, { role: "secondary", border: false, onClick: () => setInvitationToCancel(invitation), children: "Reject Invitation" }), _jsx(Button, { className: "transition", onClick: () => doAcceptInvitation(invitation), children: "Accept Invitation" })] })] }) }, invitation.id)))] })] }));
}
