import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Card, Button, Input } from '.';
import { useAppSelector } from 'src/client/app/hooks';
import { selectGetTheme } from 'src/client/features/ui/uiSlice';
const defaultProps = {
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
};
export function Confirm(props) {
    const mergedProps = { ...defaultProps, ...props };
    const theme = useAppSelector(selectGetTheme);
    const [isOpen, setIsOpen] = useState(mergedProps.isOpen);
    const [typedConfirmationText, setTypedConfirmationText] = useState('');
    const [canConfirm, setCanConfirm] = useState(mergedProps.requiredTextToConfirm === undefined);
    useEffect(() => setIsOpen(mergedProps.isOpen), [mergedProps.isOpen]);
    useEffect(() => {
        if (mergedProps.requiredTextToConfirm !== undefined) {
            setCanConfirm(typedConfirmationText === mergedProps.requiredTextToConfirm);
        }
    }, [typedConfirmationText, mergedProps.requiredTextToConfirm]);
    const doCancel = () => {
        mergedProps.onCancel && mergedProps.onCancel();
        doClose();
    };
    const doConfirm = () => {
        mergedProps.onConfirm();
        doClose();
    };
    const doClose = () => {
        setTypedConfirmationText('');
        setCanConfirm(mergedProps.requiredTextToConfirm === undefined);
        setIsOpen(false);
    };
    return (_jsxs(Dialog, { open: isOpen, onClose: doCancel, className: "relative z-50", children: [_jsx("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }), _jsx("div", { className: `fixed inset-0 flex items-center justify-center p-4 overflow-x-scroll ${theme}`, children: _jsx(Card, { className: "w-full max-w-lg h-fit mx-auto dark:text-white", title: mergedProps.title, children: _jsxs(Dialog.Panel, { className: "mx-auto max-w", children: [_jsx("p", { children: mergedProps.message }), mergedProps.requiredTextToConfirm &&
                                mergedProps.instructionsRequiredTextToConfirm &&
                                _jsxs(_Fragment, { children: [_jsx("p", { className: "my-4 text-gray-600 dark:text-gray-400", children: mergedProps.instructionsRequiredTextToConfirm }), _jsx("p", { className: "my-4 text-gray-600 dark:text-gray-400", children: _jsx(Input, { value: typedConfirmationText, onChange: (e) => setTypedConfirmationText(e.target.value), placeholder: "Type the text to confirm" }) })] }), _jsxs("div", { className: "mt-4 text-right space-x-4 bg-gray-200 dark:bg-gray-700/50 -m-4 rounded-b py-4 px-2", children: [_jsx(Button, { role: "secondary", border: false, onClick: doCancel, children: mergedProps.cancelButtonText }), _jsx(Button, { disabled: !canConfirm, className: "transition", onClick: doConfirm, children: mergedProps.confirmButtonText })] })] }) }) })] }));
}
