import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Alert, Spinner } from '../ui';
import { useAppSelector } from '../../app/hooks';
import { selectIsAuthenticated } from './authSlice';
import { useGetInvitationOverviewQuery } from 'src/client/app/api/organizationApi';
import { Login } from './Login';
import { Signup } from './Signup';
export function AcceptInvite() {
    const [errorMessage, setErrorMessage] = useState();
    const { invitationId } = useParams();
    const { data: invitation, isLoading: isInvitationLoading, isFetching, isError, refetch, error } = useGetInvitationOverviewQuery(invitationId || '', { skip: !invitationId });
    const isLoading = isInvitationLoading || isFetching;
    const isExistingUser = !!invitation?.userId;
    if (useAppSelector(selectIsAuthenticated)) {
    }
    const submit = async (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
    };
    if (error && error.status === 404) {
        return _jsx(Navigate, { replace: true, to: '/auth/login' });
    }
    return (_jsxs("div", { className: "space-y-4", children: [_jsx("h1", { className: "text-xl font-bold leading-tight tracking-tight md:text-2xl text-white", children: "Accept Invitation" }), isLoading && _jsx(Spinner, {}), (!isLoading && invitation) && _jsxs(_Fragment, { children: [_jsxs(Alert, { type: "info", title: "", className: "mt-4", children: ["Please ", isExistingUser ? 'log in' : 'create an account in order', " to accept this invitation and join ", _jsx("strong", { children: invitation.newUserOrg.name }), "."] }), (!isExistingUser) && _jsxs(_Fragment, { children: [_jsx(Signup, { hideHeader: true, email: invitation.newUserEmail, redirectTo: '/account', invitation: invitation }), _jsxs("div", { className: "text-center mt-4 text-sm text-gray-400", children: [_jsx("strong", { children: "Already have an account?" }), " Please ensure your invitation is sent to the same email address as your existing account."] })] }), (isExistingUser) && _jsx(Login, { hideHeader: true, email: invitation.newUserEmail, redirectTo: '/account' })] })] }));
}
