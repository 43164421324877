import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from './icon/Icon';
import { Menu, Transition } from '@headlessui/react';
export function Dropdown(props) {
    return (_jsx("div", { className: "relative", children: _jsx(Menu, { children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs(Menu.Button, { className: `
                            p-2 0 rounded-lg relative text-gray-500 hover:text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 
                            dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600 
                            ui-open:rounded-t-lg ui-open:rounded-b-none ui-open:bg-gray-100 dark:ui-open:bg-gray-700 z-0
                            ${props.className}
                        `, title: props.title, children: [_jsx("span", { className: "sr-only", children: props.title }), _jsx(Icon, { icon: props.icon, className: 'w-6 h-6' }), props.showIndicator &&
                                _jsx("span", { className: "inline-block w-2 h-2 bg-red-600 rounded-full absolute bottom-1 right-1" })] }), _jsx(Transition, { show: open, afterLeave: props.onClose, enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", className: "relative z-50", children: _jsx(Menu.Items, { className: "absolute right-0 z-50 my-4 -mt-1 min-w-fit text-base list-none bg-gray-50 shadow-lg dark:bg-gray-700 rounded rounded-tr-nonew", children: props.children }) })] })) }) }));
}
