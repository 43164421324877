import { jsx as _jsx } from "react/jsx-runtime";
export function Title(props) {
    const levelClasses = {
        1: 'text-4xl py-2 my-4',
        2: 'text-2xl py-1 my-2',
        3: 'text-lg font-bold my-4 pb-2 text-gray-600 dark:text-gray-400 border-none',
        4: 'text-md font-bold mt-4 pb-1 -mb-3 text-gray-600 dark:text-gray-200 dark:text-gray-400',
    };
    const classes = `uppercase border-b border-brand-primary ${levelClasses[props.level ?? 1]} ${props.className}`;
    switch (props.level) {
        case 3:
            return _jsx("h3", { className: classes, children: props.children });
        case 2:
            return _jsx("h2", { className: classes, children: props.children });
    }
    return _jsx("h1", { className: classes, children: props.children });
}
