import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDoGenerateLogFileMutation } from 'src/client/app/api/siteApi';
import { Datepicker, Spinner, Button, Modal, Select, Alert } from 'src/client/features/ui';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { LogFileType } from 'src/shared/types.g';
const logFileExportTypeOptions = [
    { value: 'HTTP_ACCESS', label: 'Web Server Access Logs' },
    { value: 'HTTP_ERROR', label: 'Web Server Error Logs' },
];
export function CreateLogExport(props) {
    const [generateExport, { isLoading, error: generateExportError }] = useDoGenerateLogFileMutation();
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const [logType, setLogType] = useState(LogFileType.HTTP_ACCESS);
    const [error, setError] = useState(null);
    const toast = useToaster();
    const doClose = () => {
        props.onClose();
    };
    const doGenerateExport = async (e) => {
        e.preventDefault();
        if (!dateRange) {
            return setError('Date range is required.');
        }
        if (!dateRange.endDate) {
            return setError('End date is required.');
        }
        if (!dateRange.startDate) {
            return setError('Start date is required.');
        }
        const now = new Date();
        const from = new Date(dateRange?.startDate);
        const to = new Date(dateRange?.endDate);
        if (from >= to) {
            return setError('Start date must be before end date.');
        }
        if (from >= now || to > now) {
            return setError('Dates can\'t be in the future!');
        }
        console.log(props.website);
        await generateExport({
            website: props.website,
            logFileArgs: {
                type: logType,
                from,
                to,
            }
        }).unwrap();
        if (generateExportError) {
            console.error(generateExportError);
            toast({
                title: 'Error',
                message: 'An error occured when starting this log export. Please try again.',
                type: 'error'
            });
        }
        else {
            toast({
                title: 'Export started!',
                message: 'Your log export has been started! You\'ll be notified once it is ready for download.',
                type: 'success'
            });
        }
        doClose();
        props.onSubmit();
    };
    return (_jsx(Modal, { isOpen: props.isOpen, onClose: doClose, title: "Generate Log Export", subtitle: "Please select a date range to generate a log file export. Exported files are available on this page to download.", children: _jsxs("div", { className: "flex flex-col space-y-4 justify-around mt-4", children: [error &&
                    _jsxs(Alert, { type: "error", children: [_jsx("strong", { children: "Error" }), " ", error] }), _jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "dates", children: _jsx("span", { className: "label-text uppercase", children: "Select Date Range" }) }), _jsx(Datepicker, { id: "dates", value: dateRange, onChange: (v) => { setError(null); setDateRange(v); }, maxDate: new Date() })] }), _jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "logType", children: _jsx("span", { className: "label-text uppercase", children: "Log File Type" }) }), _jsx(Select, { id: "logType", onChange: (e) => setLogType(e.target.value), value: logType, options: logFileExportTypeOptions })] }), _jsxs("div", { className: "mt-4 text-right space-x-4", children: [_jsx(Button, { role: "secondary", border: false, onClick: doClose, children: "Cancel" }), _jsxs(Button, { disabled: !!error || isLoading, onClick: doGenerateExport, children: [isLoading && _jsx(Spinner, {}), "Generate Export"] })] })] }) }));
}
