import { createSlice } from '@reduxjs/toolkit';
import { organizationApi } from 'src/client/app/api/organizationApi';
const initialState = { selectedSite: {} };
export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        selectSite: (state, action) => {
            state.selectedSite = { ...action.payload };
        },
        setSiteConfig: (state, action) => {
            state.config = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(organizationApi.endpoints.changeSelectedOrganization.matchFulfilled, () => {
            return initialState;
        });
    }
});
export const { selectSite, setSiteConfig } = siteSlice.actions;
export const getSelectedSite = (state) => state.site.selectedSite;
export const getConfigs = (state) => state.config;
export default siteSlice.reducer;
