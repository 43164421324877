import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFeatureConfigsQuery } from 'src/client/app/api/siteApi';
import { getSelectedSite } from '../../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { Aliases } from './Aliases';
import { PhpOptions } from './PhpOptions';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
export function WebServer() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data } = useGetFeatureConfigsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const onChange = async (config) => {
        if (config.configField) {
        }
    };
    return (_jsxs("div", { className: "space-y-4 relative", children: [_jsx(Aliases, { website: selectedSite }), _jsx(PhpOptions, { configName: WebsiteFeatureConfigFields.PHP_VERSION, config: data?.config.PHP_VERSION, website: selectedSite, onChange: onChange })] }));
}
