import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { format } from 'date-fns';
import cronstrue from 'cronstrue';
import { BackupType } from 'src/shared/types.g';
import { getSelectedSite } from '../siteSlice';
import { useAppSelector, useAppDispatch } from 'src/client/app/hooks';
import siteApi, { useGetBackupsQuery, useGetTasksByTypeQuery } from 'src/client/app/api/siteApi';
import { Alert, Spinner, Button, Card, NoContent, Tag } from 'src/client/features/ui';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { getReadableFilesize } from 'src/client/app/helpers/get-readable-filesize';
import { CreateBackupModal } from './CreateBackupModal';
export function Backups() {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetBackupsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const { data: tasks, error: taskLoadingError } = useGetTasksByTypeQuery({ website: selectedSite.Website, taskType: 'CREATE_BACKUP' }, { skip: selectedSite.Website === undefined });
    if (error) {
        console.log(error);
    }
    if (taskLoadingError) {
        console.log(taskLoadingError);
    }
    const getHumanReadableCronSchedule = (task, verbose = true) => {
        if (!task) {
            return '';
        }
        if (task.schedule) {
            let schedule = task.schedule;
            if (schedule.split(' ').length === 6) {
                schedule = schedule.replace(/^\*\s/, '');
            }
            return cronstrue.toString(schedule, { verbose })
                .replace('At', 'at')
                .replace(/(\d{2}):(\d{2}) (\w{2})/, '$1:$2$3')
                .replace(/, only on (\w+)/, ' on $1s')
                .replace(/, every day/, ' every day')
                .replace(/ on (\w+)s and (\w+)/, ' on $1s and $2s')
                .replace(/0(\d:)/, '$1');
        }
        return '';
    };
    const getScheduledTask = (tasks) => {
        return tasks.find(t => t.repeatable && t.schedule);
    };
    const doDownload = async (backup) => {
        const file = await dispatch(siteApi.endpoints.getBackupFile.initiate({ website: selectedSite.Website, backup }));
        if (file.data) {
            window.open(file.data.url);
        }
    };
    const CreateBackupButton = (props) => (_jsx(Button, { role: props.role, onClick: () => setIsModalOpen(true), children: "+ Create A New Backup" }));
    return _jsxs(_Fragment, { children: [_jsx(CreateBackupModal, { isOpen: isModalOpen, website: selectedSite.Website, onClose: () => setIsModalOpen(false) }), (tasks && getScheduledTask(tasks)) &&
                _jsxs(Alert, { type: 'info', children: [_jsx("strong", { children: "This website is backed up automatically!" }), _jsx("br", {}), "Website backups are scheduled to occur at ", getHumanReadableCronSchedule(getScheduledTask(tasks))] }), isLoading && _jsx(Spinner, {}), (data && data.length > 0) &&
                _jsxs(_Fragment, { children: [_jsx("div", { className: "text-right my-4", children: _jsx(CreateBackupButton, {}) }), _jsx(Card, { title: "Latest Backups", children: data.map((backup, key) => (_jsxs("div", { className: "p-4 border-b border-gray-200 last:border-b-0 dark:border-gray-600 flex flex-row justify-between items-center", children: [_jsx("div", { children: _jsxs("div", { className: "flex flex-col space-y-2 items-start", children: [_jsxs(Tag, { size: "small", type: "info", children: [backup.type === BackupType.COMPLETE && _jsxs(_Fragment, { children: [" ", backup.createdBy ? 'Full site backup' : 'Automated full-site backup'] }), backup.type === BackupType.DATABASE && _jsx(_Fragment, { children: "Database-only backup" }), backup.type === BackupType.WEBSITE && _jsx(_Fragment, { children: "Standard backup - database and files" })] }), backup.label && _jsx("strong", { children: backup.label }), _jsxs("div", { className: "text-gray-500 dark:text-gray-400", children: [backup.completedAt && _jsx(_Fragment, { children: format(new Date(backup.completedAt), DEFAULT_DATE_FORMAT) }), !backup.completedAt && _jsxs(_Fragment, { children: ["Started at: ", format(new Date(backup.createdAt), DEFAULT_DATE_FORMAT), " "] })] })] }) }), _jsx("div", { children: _jsx(Button, { buttonSize: 'large', disabled: !backup.completedAt && !backup.failedAt, onClick: () => doDownload(backup), children: _jsxs(_Fragment, { children: [(!backup.completedAt && !backup.failedAt) && 'Backup in progress...', (backup.completedAt && backup.payload) &&
                                                        _jsxs(_Fragment, { children: ["Download Backup", backup.size && _jsxs(_Fragment, { children: [" (", getReadableFilesize(backup.size), ")"] })] })] }) }) })] }, key))) })] }), (!data || !data.length) &&
                _jsx(NoContent, { title: "No Site Backups Found", text: "No site backups were found. You can create a new backup now by clicking the button below.", icon: "folderArrowDown", children: _jsx("div", { className: "text-center mb-8", children: _jsx(CreateBackupButton, { role: "primary" }) }) })] });
}
