import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Card, Information, Input, Code, Button, Toggle } from "src/client/features/ui";
import { LastChanged } from '../../Advanced/LastChanged';
export function WordPressEmailSettings(props) {
    const [fromEmail, setFromEmail] = useState('');
    const [fromName, setFromName] = useState('');
    const [isEnabled, _setIsEnabled] = useState(false);
    useEffect(() => {
        if (props.config !== undefined) {
            const configValue = (props.config.configValue || {});
            if (configValue.fromName) {
                setFromName(configValue.fromName);
            }
            if (configValue.fromEmail) {
                setFromEmail(configValue.fromEmail);
            }
            _setIsEnabled(!!props.config?.isEnabled);
        }
    }, [props.config]);
    const submit = (v) => {
        const configValue = Object.fromEntries(Object.entries({ fromName, fromEmail }).filter(([_, v]) => v != null && v != ''));
        const config = {
            configField: props.configName,
            configValue: Object.keys(configValue).length > 0 ? configValue : null,
            isEnabled: v ?? isEnabled
        };
        props.onChange(config);
    };
    const setIsEnabled = (value) => {
        _setIsEnabled(value);
        submit(value);
    };
    return (_jsxs(Card, { title: "Email Settings", collapsable: true, children: [_jsxs(Information, { children: [_jsxs("p", { children: ["You can choose to send emails from your WordPress website (e.g. those sent with ", _jsx(Code, { children: "wp_mail()" }), ") through our email gateway."] }), (props.config?.configValue && (props.config?.configValue).sendQuota) &&
                        _jsxs("p", { className: "mt-4", children: [_jsx("strong", { children: "Important:" }), " You currently have a quota of ", +props.config.configValue.sendQuota, " emails per day. Please contact Support to change this quota."] })] }), _jsxs("div", { className: "space-y-4", children: [_jsx("div", { className: "flex items-center", children: _jsx(Toggle, { checked: isEnabled, onChange: setIsEnabled, label: "Send emails from WordPress through the W&B email service?", disabled: props.config !== undefined && props.config.isLocked }) }), !isEnabled &&
                        _jsxs(_Fragment, { children: [_jsx("div", { className: "bg-gray-100 dark:bg-gray-900/25 rounded rounded-lg text-gray-600 dark:text-gray-400 p-4", children: _jsx("em", { children: "Configuration options are available when this option is enabled." }) }), _jsx(LastChanged, { config: props.config })] }), isEnabled &&
                        _jsxs("div", { className: "bg-gray-100 dark:bg-gray-900/25 rounded rounded-lg text-gray-600 dark:text-gray-400", children: [_jsx("header", { className: "border-b border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-400 font-bold text-sm uppercase p-4", children: "Additional Options" }), _jsxs("div", { className: "flex flex-col space-y-6 p-8", children: [_jsxs("div", { children: [_jsx("label", { className: "label", htmlFor: "smtp-from-name", children: _jsx("span", { className: "label-text text-xs", children: "From Name:" }) }), _jsx(Input, { disabled: props.config !== undefined && props.config.isLocked, type: "text", id: "smtp-from-name", placeholder: "My Website", value: fromName, onChange: (e) => setFromName(e.target.value) })] }), _jsxs("div", { children: [_jsx("label", { className: "label", htmlFor: "smtp-from-email", children: _jsx("span", { className: "label-text text-xs", children: "From E-mail Address:" }) }), _jsx(Input, { disabled: props.config !== undefined && props.config.isLocked, type: "email", id: "smtp-from-email", placeholder: "no-reply@mywebsite.com", value: fromEmail, onChange: (e) => setFromEmail(e.target.value) })] })] }), _jsxs("footer", { className: "flex flex-row justify-between bg-gray-200 dark:bg-gray-900/25 rounded-b rounded-b-lg p-4", children: [_jsx(LastChanged, { config: props.config }), _jsx(Button, { disabled: props.config !== undefined && props.config.isLocked, onClick: () => submit(), children: "Save Options" })] })] })] })] }));
}
