import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from '../../../../app/hooks';
import { selectSession } from '../../../auth/authSlice';
import { useGetAllOrganizationsQuery, useChangeSelectedOrganizationMutation } from 'src/client/app/api/organizationApi';
import { Select } from '../../Select';
import { DEFAULT_PRIVATE_ROUTE } from "src/client/app/app.consts";
export function OrganizationSelector() {
    const session = useAppSelector(selectSession);
    const [doChangeSelectedOrganization] = useChangeSelectedOrganizationMutation();
    const { data: organizations, error, isLoading } = useGetAllOrganizationsQuery();
    const navigate = useNavigate();
    if (!session || !session.subjects.organization || organizations?.length === 1) {
        return _jsx(_Fragment, {});
    }
    const selectOrganizationOptions = organizations?.map(o => ({ label: o.name, value: o.id }));
    const doChangeOrganization = async (e) => {
        const newOrganizationId = e.target.value;
        if (newOrganizationId && newOrganizationId !== session.subjects.organization.id) {
            await doChangeSelectedOrganization(newOrganizationId).unwrap();
            return navigate(DEFAULT_PRIVATE_ROUTE);
        }
    };
    return (_jsx("div", { className: "relative", children: _jsx(Select, { title: "Select Organization...", options: selectOrganizationOptions, value: session.subjects.organization.id, onChange: doChangeOrganization, className: "!rounded-lg !text-xs !p-2 !bg-gray-100 dark:!bg-gray-800 !w-60 !border-gray-50 dark:!border-gray-700" }) }));
}
