import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Information, Toggle } from 'src/client/features/ui';
import { LastChanged } from '../LastChanged';
export function ManagedWordPress(props) {
    const onChange = (value) => {
        const config = {
            configField: props.configName,
            configValue: null,
            isEnabled: value
        };
        if (props.config?.isEnabled && !config.isEnabled) {
        }
        if (!props.config?.isEnabled && config.isEnabled) {
        }
        props.onChange(config);
    };
    return (_jsxs(Card, { title: "Managed WordPress", collapsable: true, children: [_jsxs(Information, { children: [_jsx("p", { children: "Managed WordPress will magically handle all the WordPress-specific administration of your website:" }), _jsxs("ul", { className: "list-disc p-2 pl-4", children: [_jsx("li", { children: "Automatic updates for plugins, themes, and WordPress itself" }), _jsx("li", { children: "Continuous advanced security monitoring to keep your site safe" }), _jsx("li", { children: "Performance optimizations to keep your site speedy" }), _jsx("li", { children: "So much more!" })] })] }), _jsx("div", { className: "flex items-center", children: _jsx(Toggle, { checked: !!props.config?.isEnabled, onChange: onChange, label: "Enable Managed WordPress?", disabled: !!props.config?.isLocked }) }), _jsx(LastChanged, { config: props.config })] }));
}
