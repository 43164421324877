
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal
} = require('./runtime/index-browser')


const Prisma = {}

exports.Prisma = Prisma

/**
 * Prisma Client JS version: 3.15.2
 * Query Engine version: 461d6a05159055555eb7dfb337c9fb271cbd4d7e
 */
Prisma.prismaVersion = {
  client: "3.15.2",
  engine: "461d6a05159055555eb7dfb337c9fb271cbd4d7e"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.validator = () => (val) => val

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = 'DbNull'
Prisma.JsonNull = 'JsonNull'
Prisma.AnyNull = 'AnyNull'

/**
 * Enums
 */
// Based on
// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275
function makeEnum(x) { return x; }

exports.Prisma.OrganizationScalarFieldEnum = makeEnum({
  id: 'id',
  name: 'name',
  slug: 'slug',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.UserScalarFieldEnum = makeEnum({
  id: 'id',
  email: 'email',
  password: 'password',
  firstName: 'firstName',
  lastName: 'lastName',
  avatar: 'avatar',
  active: 'active',
  isSuperAdmin: 'isSuperAdmin',
  defaultOrganizationId: 'defaultOrganizationId',
  phoneNumber: 'phoneNumber',
  otpEnabled: 'otpEnabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.UserOrganizationMembershipScalarFieldEnum = makeEnum({
  id: 'id',
  userId: 'userId',
  organizationId: 'organizationId',
  active: 'active',
  role: 'role',
  isOrgTechContact: 'isOrgTechContact',
  isOrgBillingContact: 'isOrgBillingContact',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.UserOtpSettingsScalarFieldEnum = makeEnum({
  id: 'id',
  userId: 'userId',
  secret: 'secret',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.UserInviteScalarFieldEnum = makeEnum({
  id: 'id',
  sentAt: 'sentAt',
  sentById: 'sentById',
  newUserEmail: 'newUserEmail',
  newUserOrgId: 'newUserOrgId',
  newUserRole: 'newUserRole',
  existingUserId: 'existingUserId',
  message: 'message',
  fullfilledByIp: 'fullfilledByIp',
  cancelledAt: 'cancelledAt',
  fulfilledAt: 'fulfilledAt'
});

exports.Prisma.SessionScalarFieldEnum = makeEnum({
  id: 'id',
  userId: 'userId',
  loginAt: 'loginAt',
  loginIP: 'loginIP',
  userAgent: 'userAgent',
  token: 'token',
  expires: 'expires',
  refresh: 'refresh'
});

exports.Prisma.PasswordResetScalarFieldEnum = makeEnum({
  id: 'id',
  userId: 'userId',
  ipAddress: 'ipAddress',
  createdAt: 'createdAt',
  resetToken: 'resetToken',
  fulfilledAt: 'fulfilledAt'
});

exports.Prisma.PortalAuthenticationLogScalarFieldEnum = makeEnum({
  id: 'id',
  username: 'username',
  ipAddress: 'ipAddress',
  userAgent: 'userAgent',
  status: 'status',
  timestamp: 'timestamp'
});

exports.Prisma.UserWebsitePermissionsScalarFieldEnum = makeEnum({
  id: 'id',
  userId: 'userId',
  websiteId: 'websiteId',
  permission: 'permission',
  creatorId: 'creatorId',
  createdAt: 'createdAt'
});

exports.Prisma.MwpClusterScalarFieldEnum = makeEnum({
  id: 'id',
  name: 'name',
  location: 'location',
  active: 'active',
  createdAt: 'createdAt'
});

exports.Prisma.MwpServerScalarFieldEnum = makeEnum({
  id: 'id',
  clusterId: 'clusterId',
  name: 'name',
  publicFqdn: 'publicFqdn',
  type: 'type',
  active: 'active',
  ipv4_private: 'ipv4_private',
  ipv4_public: 'ipv4_public',
  createdAt: 'createdAt'
});

exports.Prisma.ServerAgentHeartbeatScalarFieldEnum = makeEnum({
  id: 'id',
  serverId: 'serverId',
  version: 'version',
  type: 'type',
  timestamp: 'timestamp'
});

exports.Prisma.SSHPublicKeyScalarFieldEnum = makeEnum({
  id: 'id',
  keyname: 'keyname',
  pubKey: 'pubKey',
  websiteId: 'websiteId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.DatabaseScalarFieldEnum = makeEnum({
  id: 'id',
  databaseName: 'databaseName',
  databaseUser: 'databaseUser',
  databasePassword: 'databasePassword',
  databaseHost: 'databaseHost',
  tablePrefix: 'tablePrefix',
  type: 'type',
  ssl: 'ssl',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsitePropertyScalarFieldEnum = makeEnum({
  id: 'id',
  name: 'name',
  slug: 'slug',
  orgId: 'orgId',
  repositoryId: 'repositoryId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteScalarFieldEnum = makeEnum({
  id: 'id',
  systemId: 'systemId',
  hostname: 'hostname',
  active: 'active',
  environment: 'environment',
  clusterId: 'clusterId',
  docroot: 'docroot',
  databaseId: 'databaseId',
  websitePropertyId: 'websitePropertyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteClientApiKeyScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  isEnabled: 'isEnabled',
  apiKey: 'apiKey',
  createdAt: 'createdAt'
});

exports.Prisma.WebsiteFeatureConfigScalarFieldEnum = makeEnum({
  id: 'id',
  configField: 'configField',
  configValue: 'configValue',
  isEnabled: 'isEnabled',
  isLocked: 'isLocked',
  websiteId: 'websiteId',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteAliasScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  alias: 'alias',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteHttpdConfigDirectivesScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  name: 'name',
  value: 'value',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteHttpAuthCredentialScalarFieldEnum = makeEnum({
  id: 'id',
  username: 'username',
  password: 'password',
  websiteId: 'websiteId'
});

exports.Prisma.WebsiteSslCertificateScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  issuer: 'issuer',
  fingerprint: 'fingerprint',
  public: 'public',
  private: 'private',
  intermediate: 'intermediate',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  expiresAt: 'expiresAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteMonitorScalarFieldEnum = makeEnum({
  id: 'id',
  vendor: 'vendor',
  status: 'status',
  apiKey: 'apiKey',
  url: 'url',
  websiteId: 'websiteId',
  isEnabled: 'isEnabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteMonitorStatusLogScalarFieldEnum = makeEnum({
  id: 'id',
  monitorId: 'monitorId',
  status: 'status',
  statusChanged: 'statusChanged',
  data: 'data',
  comment: 'comment',
  createdAt: 'createdAt'
});

exports.Prisma.RepositoryScalarFieldEnum = makeEnum({
  id: 'id',
  name: 'name',
  url: 'url',
  provider: 'provider',
  createdAt: 'createdAt'
});

exports.Prisma.CommitScalarFieldEnum = makeEnum({
  id: 'id',
  repositoryId: 'repositoryId',
  message: 'message',
  author: 'author',
  type: 'type',
  createdAt: 'createdAt'
});

exports.Prisma.ReleaseScalarFieldEnum = makeEnum({
  id: 'id',
  commitId: 'commitId',
  title: 'title',
  comment: 'comment',
  createdBy: 'createdBy',
  createdAt: 'createdAt'
});

exports.Prisma.SupportTicketScalarFieldEnum = makeEnum({
  id: 'id',
  title: 'title',
  regarding: 'regarding',
  priority: 'priority',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  closedAt: 'closedAt',
  creatorId: 'creatorId',
  closedById: 'closedById',
  organizationId: 'organizationId',
  assigneeId: 'assigneeId'
});

exports.Prisma.SupportTicketMessageScalarFieldEnum = makeEnum({
  id: 'id',
  ticketId: 'ticketId',
  message: 'message',
  creatorId: 'creatorId',
  isFromStaff: 'isFromStaff',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.NotificationScalarFieldEnum = makeEnum({
  id: 'id',
  message: 'message',
  link: 'link',
  priority: 'priority',
  userId: 'userId',
  readAt: 'readAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.EmailScalarFieldEnum = makeEnum({
  id: 'id',
  subject: 'subject',
  html: 'html',
  text: 'text',
  sendTo: 'sendTo',
  sentFrom: 'sentFrom',
  sentAt: 'sentAt',
  sentStatus: 'sentStatus',
  createdAt: 'createdAt'
});

exports.Prisma.TaskScalarFieldEnum = makeEnum({
  id: 'id',
  type: 'type',
  state: 'state',
  progress: 'progress',
  dependsOnId: 'dependsOnId',
  targetCluster: 'targetCluster',
  targetServerType: 'targetServerType',
  targetWebsite: 'targetWebsite',
  repeatable: 'repeatable',
  schedule: 'schedule',
  payload: 'payload',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.TaskLogScalarFieldEnum = makeEnum({
  id: 'id',
  taskId: 'taskId',
  timestamp: 'timestamp',
  startedAt: 'startedAt',
  completedAt: 'completedAt',
  failedAt: 'failedAt',
  state: 'state',
  message: 'message'
});

exports.Prisma.BackupScalarFieldEnum = makeEnum({
  id: 'id',
  type: 'type',
  label: 'label',
  websiteId: 'websiteId',
  taskId: 'taskId',
  payload: 'payload',
  size: 'size',
  completedAt: 'completedAt',
  failedAt: 'failedAt',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteTrafficStatScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  startTime: 'startTime',
  endTime: 'endTime',
  txBytes: 'txBytes',
  pageViews: 'pageViews',
  uniqueVisitors: 'uniqueVisitors',
  blockedVisitors: 'blockedVisitors',
  cacheHits: 'cacheHits',
  cacheMisses: 'cacheMisses',
  cachedBytes: 'cachedBytes',
  serverErrors: 'serverErrors',
  clientErrors: 'clientErrors',
  totalErrors: 'totalErrors',
  createdAt: 'createdAt'
});

exports.Prisma.GeneratedLogFileScalarFieldEnum = makeEnum({
  id: 'id',
  from: 'from',
  to: 'to',
  type: 'type',
  payload: 'payload',
  size: 'size',
  creatorId: 'creatorId',
  websiteId: 'websiteId',
  taskId: 'taskId',
  startedAt: 'startedAt',
  readyAt: 'readyAt'
});

exports.Prisma.BrowserCacheOptionScalarFieldEnum = makeEnum({
  id: 'id',
  name: 'name',
  extensions: 'extensions',
  ttl: 'ttl',
  creatorId: 'creatorId',
  websiteId: 'websiteId',
  startedAt: 'startedAt'
});

exports.Prisma.WordPressComponentScalarFieldEnum = makeEnum({
  id: 'id',
  slug: 'slug',
  name: 'name',
  version: 'version',
  type: 'type',
  comment: 'comment',
  websiteScanOperationExecutionId: 'websiteScanOperationExecutionId',
  createdAt: 'createdAt'
});

exports.Prisma.WordPressAuditActionLogScalarFieldEnum = makeEnum({
  id: 'id',
  action: 'action',
  wpUserId: 'wpUserId',
  subjectId: 'subjectId',
  userIp: 'userIp',
  subjectSlug: 'subjectSlug',
  timestamp: 'timestamp'
});

exports.Prisma.BannedWordPressComponentScalarFieldEnum = makeEnum({
  id: 'id',
  slug: 'slug',
  name: 'name',
  type: 'type',
  comment: 'comment',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.BannedWordPressComponentExceptionScalarFieldEnum = makeEnum({
  id: 'id',
  comment: 'comment',
  bannedWordPressComponentId: 'bannedWordPressComponentId',
  websiteId: 'websiteId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WordPressVulnerabilityScalarFieldEnum = makeEnum({
  id: 'id',
  title: 'title',
  source: 'source',
  componentType: 'componentType',
  componentName: 'componentName',
  componentSlug: 'componentSlug',
  cve: 'cve',
  fromVersion: 'fromVersion',
  fromVersionInclusive: 'fromVersionInclusive',
  toVersion: 'toVersion',
  toVersionInclusive: 'toVersionInclusive',
  patched: 'patched',
  patchedVersion: 'patchedVersion',
  publishedAt: 'publishedAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WordPressAutomaticUpdateScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  componentType: 'componentType',
  componentName: 'componentName',
  componentOldVersion: 'componentOldVersion',
  componentNewVersion: 'componentNewVersion',
  fixesScanFindingId: 'fixesScanFindingId',
  updatedOk: 'updatedOk',
  commitId: 'commitId',
  message: 'message',
  updatedAt: 'updatedAt'
});

exports.Prisma.WordPressLockedUpdateComponentScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  componentType: 'componentType',
  componentName: 'componentName',
  componentVersion: 'componentVersion',
  message: 'message',
  createdBy: 'createdBy',
  createdAt: 'createdAt'
});

exports.Prisma.WebsiteScanWorkflowScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  state: 'state',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteScanWorkflowOperationScalarFieldEnum = makeEnum({
  id: 'id',
  websiteScanWorkflowId: 'websiteScanWorkflowId',
  operationType: 'operationType'
});

exports.Prisma.WebsiteScanOperationExecutionScalarFieldEnum = makeEnum({
  id: 'id',
  websiteScanWorkflowOperationId: 'websiteScanWorkflowOperationId',
  state: 'state',
  createdAt: 'createdAt',
  startedAt: 'startedAt',
  endedAt: 'endedAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.WebsiteScanExecutionLogScalarFieldEnum = makeEnum({
  id: 'id',
  websiteScanWorkflowOperationId: 'websiteScanWorkflowOperationId',
  timestamp: 'timestamp',
  startedAt: 'startedAt',
  completedAt: 'completedAt',
  failedAt: 'failedAt',
  state: 'state',
  message: 'message'
});

exports.Prisma.WebsiteScanOperationFindingScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  severity: 'severity',
  title: 'title',
  description: 'description',
  remediation: 'remediation',
  websiteScanOperationExecutionId: 'websiteScanOperationExecutionId',
  componentType: 'componentType',
  componentName: 'componentName',
  componentVersion: 'componentVersion',
  cve: 'cve',
  cvssVector: 'cvssVector',
  cvssScore: 'cvssScore',
  cweId: 'cweId',
  enabled: 'enabled',
  updateAvailable: 'updateAvailable',
  updateVersion: 'updateVersion',
  createdAt: 'createdAt'
});

exports.Prisma.EmailGatewayConfigScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  enabled: 'enabled',
  vendor: 'vendor',
  config: 'config',
  createdAt: 'createdAt'
});

exports.Prisma.EmailGatewayMessageScalarFieldEnum = makeEnum({
  id: 'id',
  emailGatewayConfigId: 'emailGatewayConfigId',
  subject: 'subject',
  recipient: 'recipient'
});

exports.Prisma.WebsiteCustomVclScalarFieldEnum = makeEnum({
  id: 'id',
  websiteId: 'websiteId',
  userId: 'userId',
  sub: 'sub',
  code: 'code',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
});

exports.Prisma.SortOrder = makeEnum({
  asc: 'asc',
  desc: 'desc'
});

exports.Prisma.NullableJsonNullValueInput = makeEnum({
  DbNull: 'DbNull',
  JsonNull: 'JsonNull'
});

exports.Prisma.JsonNullValueInput = makeEnum({
  JsonNull: 'JsonNull'
});

exports.Prisma.JsonNullValueFilter = makeEnum({
  DbNull: 'DbNull',
  JsonNull: 'JsonNull',
  AnyNull: 'AnyNull'
});
exports.UserRole = makeEnum({
  USER: 'USER',
  ORGOWNER: 'ORGOWNER',
  ORGADMIN: 'ORGADMIN'
});

exports.AuthAttemptStatus = makeEnum({
  OK: 'OK',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
  MISSING_2FA: 'MISSING_2FA',
  INCORRECT_2FA: 'INCORRECT_2FA',
  ERROR: 'ERROR'
});

exports.UserActionPermission = makeEnum({
  READ_ONLY: 'READ_ONLY',
  READ_WRITE: 'READ_WRITE',
  MANAGE: 'MANAGE'
});

exports.ServerType = makeEnum({
  CONTROLLER: 'CONTROLLER',
  VARNISH_CACHE: 'VARNISH_CACHE',
  APACHE_HTTPD_PRIMARY: 'APACHE_HTTPD_PRIMARY',
  APACHE_HTTPD_SECONDARY: 'APACHE_HTTPD_SECONDARY',
  MYSQL_DATABASE: 'MYSQL_DATABASE',
  EDGE_LOADBALANCER: 'EDGE_LOADBALANCER'
});

exports.HeartbeatType = makeEnum({
  START: 'START',
  RUN: 'RUN',
  SHUTDOWN: 'SHUTDOWN'
});

exports.DatabaseType = makeEnum({
  MYSQL: 'MYSQL',
  POSTGRESQL: 'POSTGRESQL',
  MONGODB: 'MONGODB'
});

exports.Environment = makeEnum({
  PRODUCTION: 'PRODUCTION',
  STAGING: 'STAGING',
  DEVELOPMENT: 'DEVELOPMENT'
});

exports.WebsiteFeatureConfigFields = makeEnum({
  WORDPRESS: 'WORDPRESS',
  WORDPRESS_AUTO_UPDATES: 'WORDPRESS_AUTO_UPDATES',
  WORDPRESS_FORCE_SSL: 'WORDPRESS_FORCE_SSL',
  WORDPRESS_CUSTOMIZE_LOGIN_PAGE: 'WORDPRESS_CUSTOMIZE_LOGIN_PAGE',
  BASIC_AUTH: 'BASIC_AUTH',
  CUSTOM_VCL: 'CUSTOM_VCL',
  BROWSER_EXPIRE_HEADERS: 'BROWSER_EXPIRE_HEADERS',
  PHP_VERSION: 'PHP_VERSION',
  SFTP_ACCESS: 'SFTP_ACCESS',
  SHELL_ACCESS: 'SHELL_ACCESS',
  AUTOMATIC_BACKUPS: 'AUTOMATIC_BACKUPS',
  EMAIL_GATEWAY: 'EMAIL_GATEWAY',
  SSL_CERTIFICATE: 'SSL_CERTIFICATE',
  SECURITY_SCANNER: 'SECURITY_SCANNER'
});

exports.WebsiteMonitorVendor = makeEnum({
  UPTIME_ROBOT: 'UPTIME_ROBOT'
});

exports.WebsiteMonitorStatus = makeEnum({
  UNKNOWN: 'UNKNOWN',
  OK: 'OK',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
});

exports.RepositoryProvider = makeEnum({
  GITLAB: 'GITLAB',
  GITHUB: 'GITHUB'
});

exports.CommitType = makeEnum({
  MANUAL: 'MANUAL',
  AUTO_UPGRADE: 'AUTO_UPGRADE',
  TAG: 'TAG',
  RELEASE: 'RELEASE'
});

exports.SupportTicketPriority = makeEnum({
  EMERGENCY: 'EMERGENCY',
  NORMAL: 'NORMAL',
  LOW: 'LOW'
});

exports.NotificationPriority = makeEnum({
  HIGH: 'HIGH',
  NORMAL: 'NORMAL'
});

exports.TaskType = makeEnum({
  CREATE_SITE: 'CREATE_SITE',
  DELETE_SITE: 'DELETE_SITE',
  UPDATE_WP_COMPONENTS_CACHE: 'UPDATE_WP_COMPONENTS_CACHE',
  UPDATE_WP_COMPONENTS: 'UPDATE_WP_COMPONENTS',
  UPDATE_WP_CORE: 'UPDATE_WP_CORE',
  UPDATE_TRAFFIC_STATS: 'UPDATE_TRAFFIC_STATS',
  CREATE_TRAFFIC_LOGS: 'CREATE_TRAFFIC_LOGS',
  CREATE_MYSQL_DATABASE: 'CREATE_MYSQL_DATABASE',
  CREATE_NEW_WEBSITE: 'CREATE_NEW_WEBSITE',
  CREATE_BACKUP: 'CREATE_BACKUP',
  DELETE_OLD_BACKUPS: 'DELETE_OLD_BACKUPS',
  UPDATE_SSH_KEYS: 'UPDATE_SSH_KEYS',
  UPDATE_VHOST: 'UPDATE_VHOST',
  UPDATE_VCL: 'UPDATE_VCL',
  UPDATE_WORDPRESS_VULNERABILITY_DATA: 'UPDATE_WORDPRESS_VULNERABILITY_DATA',
  CREATE_LOG_FILE_EXPORT: 'CREATE_LOG_FILE_EXPORT',
  ROTATE_HTTPD_LOGS: 'ROTATE_HTTPD_LOGS',
  SEND_QUEUED_EMAILS: 'SEND_QUEUED_EMAILS',
  DO_SCAN_WORKFLOW: 'DO_SCAN_WORKFLOW',
  ENABLE_WEBSITE_SHELL_ACCESS: 'ENABLE_WEBSITE_SHELL_ACCESS',
  DISABLE_WEBSITE_SHELL_ACCESS: 'DISABLE_WEBSITE_SHELL_ACCESS',
  CHECK_WEBSITE_UPTIME_MONITORS: 'CHECK_WEBSITE_UPTIME_MONITORS',
  PURGE_VARNISH_CACHE: 'PURGE_VARNISH_CACHE'
});

exports.TaskState = makeEnum({
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
});

exports.BackupType = makeEnum({
  COMPLETE: 'COMPLETE',
  WEBSITE: 'WEBSITE',
  DATABASE: 'DATABASE'
});

exports.LogFileType = makeEnum({
  HTTP_ACCESS: 'HTTP_ACCESS',
  HTTP_ERROR: 'HTTP_ERROR'
});

exports.WebsiteComponentType = makeEnum({
  PLUGIN: 'PLUGIN',
  THEME: 'THEME',
  TRANSLATION: 'TRANSLATION',
  CORE: 'CORE',
  FILE: 'FILE'
});

exports.WordPressAuditAction = makeEnum({
  NEW_USER: 'NEW_USER',
  LOGIN: 'LOGIN',
  BLOCK_LOGIN: 'BLOCK_LOGIN',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CREATE_POST: 'CREATE_POST',
  EDIT_POST: 'EDIT_POST',
  DELETE_POST: 'DELETE_POST',
  PUBLISH_POST: 'PUBLISH_POST',
  PLUGIN_ACTIVATED: 'PLUGIN_ACTIVATED',
  PLUGIN_DEACTIVATED: 'PLUGIN_DEACTIVATED',
  THEME_ACTIVATED: 'THEME_ACTIVATED'
});

exports.WebsiteScanOperationType = makeEnum({
  WORDPRESS: 'WORDPRESS',
  NUCLEI: 'NUCLEI',
  WORDPRESS_VULNERABILITY: 'WORDPRESS_VULNERABILITY',
  MALWARE: 'MALWARE',
  WORDPRESS_COMPONENT: 'WORDPRESS_COMPONENT'
});

exports.WebsiteScanFindingSeverity = makeEnum({
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  INFORMATIONAL: 'INFORMATIONAL'
});

exports.EmailGatewaySendingVendor = makeEnum({
  SMTP: 'SMTP',
  MAILGUN: 'MAILGUN',
  SES: 'SES'
});

exports.VclSub = makeEnum({
  VCL_RECV: 'VCL_RECV',
  VCL_BACKEND_RESPONSE: 'VCL_BACKEND_RESPONSE',
  VCL_DELIVER: 'VCL_DELIVER',
  VCL_HIT: 'VCL_HIT'
});

exports.Prisma.ModelName = makeEnum({
  Organization: 'Organization',
  User: 'User',
  UserOrganizationMembership: 'UserOrganizationMembership',
  UserOtpSettings: 'UserOtpSettings',
  UserInvite: 'UserInvite',
  Session: 'Session',
  PasswordReset: 'PasswordReset',
  PortalAuthenticationLog: 'PortalAuthenticationLog',
  UserWebsitePermissions: 'UserWebsitePermissions',
  MwpCluster: 'MwpCluster',
  MwpServer: 'MwpServer',
  ServerAgentHeartbeat: 'ServerAgentHeartbeat',
  SSHPublicKey: 'SSHPublicKey',
  Database: 'Database',
  WebsiteProperty: 'WebsiteProperty',
  Website: 'Website',
  WebsiteClientApiKey: 'WebsiteClientApiKey',
  WebsiteFeatureConfig: 'WebsiteFeatureConfig',
  WebsiteAlias: 'WebsiteAlias',
  WebsiteHttpdConfigDirectives: 'WebsiteHttpdConfigDirectives',
  WebsiteHttpAuthCredential: 'WebsiteHttpAuthCredential',
  WebsiteSslCertificate: 'WebsiteSslCertificate',
  WebsiteMonitor: 'WebsiteMonitor',
  WebsiteMonitorStatusLog: 'WebsiteMonitorStatusLog',
  Repository: 'Repository',
  Commit: 'Commit',
  Release: 'Release',
  SupportTicket: 'SupportTicket',
  SupportTicketMessage: 'SupportTicketMessage',
  Notification: 'Notification',
  Email: 'Email',
  Task: 'Task',
  TaskLog: 'TaskLog',
  Backup: 'Backup',
  WebsiteTrafficStat: 'WebsiteTrafficStat',
  GeneratedLogFile: 'GeneratedLogFile',
  BrowserCacheOption: 'BrowserCacheOption',
  WordPressComponent: 'WordPressComponent',
  WordPressAuditActionLog: 'WordPressAuditActionLog',
  BannedWordPressComponent: 'BannedWordPressComponent',
  BannedWordPressComponentException: 'BannedWordPressComponentException',
  WordPressVulnerability: 'WordPressVulnerability',
  WordPressAutomaticUpdate: 'WordPressAutomaticUpdate',
  WordPressLockedUpdateComponent: 'WordPressLockedUpdateComponent',
  WebsiteScanWorkflow: 'WebsiteScanWorkflow',
  WebsiteScanWorkflowOperation: 'WebsiteScanWorkflowOperation',
  WebsiteScanOperationExecution: 'WebsiteScanOperationExecution',
  WebsiteScanExecutionLog: 'WebsiteScanExecutionLog',
  WebsiteScanOperationFinding: 'WebsiteScanOperationFinding',
  EmailGatewayConfig: 'EmailGatewayConfig',
  EmailGatewayMessage: 'EmailGatewayMessage',
  WebsiteCustomVcl: 'WebsiteCustomVcl'
});

/**
 * Create the Client
 */
class PrismaClient {
  constructor() {
    throw new Error(
      `PrismaClient is unable to be run in the browser.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
    )
  }
}
exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
