import { toasterSlice } from './toasterSlice';
export const toasterActions = {
    addToast: (toast) => (dispatch, getState) => {
        dispatch(toasterSlice.actions.addToast(toast));
    },
    removeToast: (i) => (dispatch, getState) => {
        dispatch(toasterSlice.actions.removeToast(i));
    },
    removeToastByProperties: (toast) => (dispatch, getState) => {
        dispatch(toasterSlice.actions.removeToastByProperties(toast));
    }
};
