import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Card, Input, Textarea, Button, Select, Spinner } from "../../ui";
import { useCreateMessageMutation, useCreateTicketMutation } from "src/client/app/api/supportApi";
const regardingOptions = ['Website Hosting', 'Dialkit VoIP', 'Subscriptions', 'Account & Billing', 'Other'].map((v) => ({ label: v, value: v }));
export function NewSupportTicket() {
    const [createTicket] = useCreateTicketMutation();
    const [createTicketMessage] = useCreateMessageMutation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [regarding, setRegarding] = useState('Other');
    const [message, setMessage] = useState('');
    const doOnSave = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const ticket = await createTicket({ title, regarding }).unwrap();
            if (ticket && ticket.id) {
                await createTicketMessage({ ...ticket, message });
                return navigate(`/dashboard/support/ticket/${ticket.id}`);
            }
        }
        catch (e) {
            console.log('ERROR', e);
            setIsLoading(false);
        }
    };
    return (_jsx("div", { className: "py-4", children: _jsx(Card, { title: "New Support Ticket", children: _jsxs("form", { onSubmit: doOnSave, className: "flex flex-col space-y-4", children: [_jsxs("p", { className: "form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "title", children: _jsx("span", { className: "label-text", children: "Ticket Title" }) }), _jsx(Input, { id: "username", type: "text", placeholder: "Title", defaultValue: title, onChange: (e) => setTitle(e.target.value) })] }), _jsxs("p", { className: "form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "regarding", children: _jsx("span", { className: "label-text", children: "What is this ticket about?" }) }), _jsx(Select, { id: "priority", options: regardingOptions, defaultValue: regarding, onChange: (e) => setRegarding(e.target.value) })] }), _jsxs("p", { className: "form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "message", children: _jsx("span", { className: "label-text", children: "Message" }) }), _jsx(Textarea, { id: "message", className: "h-48", defaultValue: message, onChange: (e) => setMessage(e.target.value) })] }), _jsx("div", { className: "text-right", children: _jsxs(Button, { role: "primary", disabled: isLoading, children: [isLoading && _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Submitting..."] }), !isLoading && 'Submit'] }) })] }) }) }));
}
