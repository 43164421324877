import { Mutex } from 'async-mutex';
import { DEFAULT_PUBLIC_ROUTE } from '../app.consts';
import { authActions } from '../../features/auth/authActions';
const mutex = new Mutex();
export function getBaseQueryWithTokenRefresh(baseQuery) {
    const baseQueryWithTokenRefresh = async (args, api, extraOptions) => {
        await mutex.waitForUnlock();
        let result = await baseQuery(args, api, extraOptions);
        if (result.error && result.error.status === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const session = api.getState()?.auth?.session;
                    if (session) {
                        const { token, refresh } = session.token;
                        const refreshResult = await baseQuery({
                            url: 'auth/refresh',
                            method: 'POST',
                            body: {
                                accessToken: token,
                                refreshToken: refresh
                            }
                        }, api, extraOptions);
                        if (refreshResult.data) {
                            api.dispatch(authActions.refresh(refreshResult.data));
                            result = await baseQuery(args, api, extraOptions);
                        }
                        else {
                            api.dispatch(authActions.logout());
                            window.location.href = DEFAULT_PUBLIC_ROUTE;
                        }
                    }
                    else {
                        result = await baseQuery(args, api, extraOptions);
                    }
                }
                finally {
                    release();
                }
            }
            else {
                await mutex.waitForUnlock();
                result = await baseQuery(args, api, extraOptions);
            }
        }
        return result;
    };
    return baseQueryWithTokenRefresh;
}
;
