import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { Icon } from '../ui/icon/Icon';
export function Sidebar(props) {
    const location = useLocation();
    return (_jsx("aside", { className: "top-0 left-0 bottom-0 w-72 h-full shrink-0", "aria-label": "Sidenav", children: _jsxs("div", { className: "overflow-y-auto h-full bg-white border-r border-slate-100 dark:bg-gray-800 dark:border-gray-700", children: [_jsx("ul", { children: props.links.map((link, key) => {
                        const isActive = (new RegExp(`^${props.baseUrl}[\\w\\.\\-]+\/${link.url}`)).test(location.pathname);
                        const baseClasses = 'flex items-center p-5 text-base font-normal hover:text-gray-900 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group';
                        const additionalClasses = (isActive || link.collapsable === false) ?
                            'text-gray-900 dark:text-white bg-slate-100 dark:bg-gray-700 border-l-4 border-brand-primary' :
                            'text-gray-400 dark:text-gray-400';
                        const classes = `${baseClasses} ${additionalClasses}`;
                        return (_jsxs("li", { children: [!link.children &&
                                    _jsxs(Link, { to: link.url, className: classes, children: [_jsx(Icon, { icon: link.icon, className: "w-6 h-6 transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white" }), _jsx("span", { className: "ml-3 uppercase", children: link.name })] }), link.children &&
                                    _jsx(Menu, { children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs(Menu.Button, { className: `block w-full ui-open:text-gray-900 dark:ui-open-text-white ${classes}`, children: [_jsx(Icon, { icon: link.icon, className: "w-6 h-6 transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white" }), _jsx("span", { className: "flex-1 ml-3 text-left uppercase whitespace-nowrap", children: link.name }), !isActive && link.collapsable !== false && _jsx(Icon, { icon: "caretRight", "aria-hidden": "true", className: "w-6 h-6 ui-open:rotate-180" })] }), _jsx(Menu.Items, { static: isActive || link.collapsable === false, children: _jsx("ul", { className: "py-2 space-y-2", children: link.children && link.children.map((child, childKey) => {
                                                            const isChildActive = location.pathname.startsWith(props.baseUrl) && location.pathname.includes(`/${link.url}/${child.url}`);
                                                            const baseChildClasses = 'block p-2 pl-14 w-full text-base font-normal transition duration-75';
                                                            const additionalChildClasses = isChildActive ? 'text-gray-900 dark:text-white' : 'text-gray-400 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white';
                                                            return (_jsx("li", { children: _jsx(Link, { to: `${link.url}/${child.url}`, className: `${baseChildClasses} ${additionalChildClasses}`, children: child.name }) }, childKey));
                                                        }) }) })] })) })] }, key));
                    }) }), props.children &&
                    _jsx("div", { className: "p-4 mt-4 pt-8 text-center space-y-2 border-t border-gray-200 dark:border-gray-700", children: props.children })] }) }));
}
