import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { selectSite, getSelectedSite } from '../siteSlice';
import { useGetPropertiesQuery } from 'src/client/app/api/siteApi';
import { useAppDispatch } from 'src/client/app/hooks';
import { Tag, Spinner, Icon, Button, Card } from 'src/client/features/ui';
import { getEnvTagType } from './SiteSelectorBar';
import { SiteSelectorFilter } from './SiteSelectorFilter';
import { useAppSelector } from 'src/client/app/hooks';
const defaultProps = {
    allowCancel: true,
    showTitle: true,
    onClose: () => null,
    onSelect: () => null,
};
export function SiteSelector(props = defaultProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetPropertiesQuery();
    const [websiteProperties, setWebsiteProperties] = useState(data);
    const { hostname } = useParams();
    const doClose = useCallback(() => {
        if (props.onClose !== undefined) {
            props.onClose();
        }
    }, []);
    const doSelectSite = useCallback((propertyWithSites, site) => {
        const { Websites: _, ...websiteProperty } = propertyWithSites;
        const selectedSite = { ...websiteProperty, Website: site };
        dispatch(selectSite(selectedSite));
        if (hostname !== site.hostname) {
            navigate(`/dashboard/sites/${selectedSite.Website.hostname}/overview`);
        }
        if (props.onSelect !== undefined) {
            props.onSelect();
        }
        doClose();
    }, [props, doClose, dispatch, hostname, navigate]);
    useEffect(() => {
        if (hostname && websiteProperties && (!selectedSite || !selectedSite.Website)) {
            const website = websiteProperties.flatMap((p) => p.Websites).find((w) => w.hostname === hostname);
            const property = websiteProperties.find((p) => p.id === website?.websitePropertyId);
            if (website && property) {
                return doSelectSite(property, website);
            }
        }
    }, [hostname, selectedSite, websiteProperties, doSelectSite]);
    if (error) {
        console.log('Site selector error', error);
    }
    const cardTitleProps = props.showTitle === false ? {} : { title: 'Select Website', subtitle: 'Please select a website to manage' };
    return (_jsxs(Card, { ...cardTitleProps, children: [data && _jsx(SiteSelectorFilter, { properties: data, onChange: (setWebsiteProperties), hideWhenFewerThan: 6 }), _jsxs("div", { className: "mt-6 space-y-6 overflow-x-scroll h-96 w-full", children: [isLoading && _jsx(Spinner, {}), data &&
                        websiteProperties &&
                        websiteProperties.map((webProperty, key) => (_jsx("div", { className: "bg-gray-100 p-4 dark:bg-blue-300/10 rounded-lg", children: _jsx(Menu, { children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs(Menu.Button, { className: "flex w-full", children: [_jsx("h3", { className: "flex-1 text-left uppercase font-bold", children: webProperty.name }), _jsx(Icon, { icon: "caretRight", "aria-hidden": "true", className: `w-6 h-6 ${open ? 'rotate-180' : ''}` })] }), _jsx(Menu.Items, { className: "mt-2 flex flex-col space-y-2", children: webProperty.Websites.map((website, i) => (_jsxs("div", { className: "p-4 bg-black/10 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-brand-primary dark:hover:text-brand-primary rounded-lg cursor-pointer flex flex-col items-start", onClick: () => doSelectSite(webProperty, website), children: [_jsx(Tag, { type: getEnvTagType(website.environment), size: "small", children: website.environment }), website.hostname] }, i))) })] })) }, key) }, key)))] }), props.allowCancel &&
                _jsx("div", { className: "text-right mt-4", children: _jsx(Button, { onClick: doClose, children: "Cancel" }) })] }));
}
