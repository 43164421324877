import { api } from './api';
export const notificationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getNotifications: build.query({
            query: () => 'notifications',
            keepUnusedDataFor: 8,
            providesTags: ['Notification']
        }),
        setNotificationAsRead: build.mutation({
            query: (notification) => ({
                url: `notifications/notification/${notification.id}/read`,
                method: 'PATCH',
                body: { read: new Date() }
            }),
            invalidatesTags: ['Notification']
        }),
    })
});
export const { useGetNotificationsQuery, useSetNotificationAsReadMutation, } = notificationApi;
export default notificationApi;
