import { api } from './api';
export const organizationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMyOrganization: build.query({
            query: () => 'organization/my-org',
            providesTags: ['Organization']
        }),
        getAllOrganizations: build.query({
            query: () => 'organization/organizations',
            providesTags: ['Organization']
        }),
        changeSelectedOrganization: build.mutation({
            query: (organizationId) => ({
                url: 'organization/change-selected-organization',
                method: 'POST',
                body: { organizationId }
            }),
            invalidatesTags: ['Auth', 'Organization', 'Site']
        }),
        inviteNewUser: build.mutation({
            query: (params) => ({
                url: 'organization/member/invite',
                method: 'PUT',
                body: params
            }),
            invalidatesTags: ['Organization']
        }),
        getInvitationOverview: build.query({
            query: (invitationId) => `organization/member/invite/${invitationId}/overview`,
            providesTags: ['Organization']
        }),
        getPendingInvitations: build.query({
            query: () => `organization/member/invite/pending`,
            providesTags: ['Organization']
        }),
        cancelInvitation: build.mutation({
            query: (invite) => ({
                url: `organization/member/invite/${invite.id}/cancel`,
                method: 'POST',
                body: invite
            }),
            invalidatesTags: ['Organization', 'User']
        }),
        acceptInvitation: build.mutation({
            query: (invite) => ({
                url: `organization/member/invite/${invite.id}/accept`,
                method: 'POST',
                body: invite
            }),
            invalidatesTags: ['Organization', 'User']
        }),
        getMembershipForUser: build.query({
            query: (userId) => `organization/membership/user/${userId}`,
            providesTags: ['Organization']
        }),
        editMembershipForUser: build.mutation({
            query: (params) => ({
                url: `organization/membership/user/${params.userId}`,
                method: 'POST',
                body: params.membershipUpdate
            }),
            invalidatesTags: ['Organization']
        })
    })
});
export const { useGetMyOrganizationQuery, useGetAllOrganizationsQuery, useChangeSelectedOrganizationMutation, useInviteNewUserMutation, useGetInvitationOverviewQuery, useGetPendingInvitationsQuery, useCancelInvitationMutation, useAcceptInvitationMutation, useGetMembershipForUserQuery, useEditMembershipForUserMutation, } = organizationApi;
export default organizationApi;
