import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Button } from '../../ui';
import { Sidebar } from '../Sidebar';
import { NewSupportTicket } from './NewSupportTicket';
import { SupportTickets } from './SupportTickets';
import { SupportTicketDetail } from './SupportTicketDetail';
import { SupportTicketStatus } from 'src/shared/types';
export const navLinks = [
    {
        name: 'Support Tickets',
        icon: '',
        url: 'tickets',
        collapsable: false,
        children: [
            { name: 'Open Tickets', url: '' },
            { name: 'Closed Tickets', url: 'closed' },
        ]
    }
];
export function Support() {
    const navigate = useNavigate();
    return (_jsxs("div", { className: "flex flex-row flex-grow", children: [_jsx(Sidebar, { baseUrl: "/dashboard/support", links: navLinks, children: _jsx(Button, { role: "primary", title: "New Support Ticket", onClick: (() => navigate('/dashboard/support/ticket/new')), children: "+ New Support Ticket" }) }), _jsx("div", { className: "grow p-4 pt-0", children: _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "tickets" }) }), _jsx(Route, { path: "tickets", element: _jsx(SupportTickets, { status: SupportTicketStatus.OPEN }) }), _jsx(Route, { path: "tickets/closed", element: _jsx(SupportTickets, { status: SupportTicketStatus.CLOSED }) }), _jsx(Route, { path: "ticket/new", element: _jsx(NewSupportTicket, {}) }), _jsx(Route, { path: "ticket/:id", element: _jsx(SupportTicketDetail, {}) })] }) })] }));
}
