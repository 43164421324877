import { jsx as _jsx } from "react/jsx-runtime";
import { SettingsGroup } from "../SettingsGroup";
import { useAppSelector, useAppDispatch } from 'src/client/app/hooks';
import { selectGetTheme, toggleTheme } from 'src/client/features/ui/uiSlice';
import { Toggle } from "../../ui";
export function DisplaySettings(props) {
    const theme = useAppSelector(selectGetTheme);
    const dispatch = useAppDispatch();
    const toggle = () => dispatch(toggleTheme());
    return (_jsx(SettingsGroup, { title: "Display Settings", description: "These settings only apply in your current browser.", children: _jsx(Toggle, { checked: theme === 'dark', onChange: (v) => toggle(), label: "Use dark theme?" }) }));
}
