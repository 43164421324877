export function getErrorMessage(error, defaultMessage) {
    if (error.data && error.data.message) {
        return error.data.message;
    }
    if (error.message) {
        return error.message;
    }
    if (defaultMessage) {
        return defaultMessage;
    }
    return JSON.stringify(error);
}
