import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { authActions } from '../../../auth/authActions';
import { api } from '../../../../app/api/api';
import { Dropdown, Spinner } from '../..';
import { useGetCurrentUserQuery } from 'src/client/app/api/userApi';
export function UserMenu() {
    const dispatch = useAppDispatch();
    const { data: user, isLoading } = useGetCurrentUserQuery();
    const doLogout = (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to log out?')) {
            dispatch(api.util.resetApiState());
            dispatch(authActions.logout());
            window.location.reload();
        }
    };
    return (_jsxs(Dropdown, { icon: "user", title: "Open user menu", children: [isLoading && _jsx(Spinner, {}), (!isLoading && user) &&
                _jsxs("div", { className: "py-3 px-4 mb-2 border-b border-gray-100 dark:border-gray-600", children: [_jsxs("span", { className: "block text-sm font-semibold text-gray-900 dark:text-white", children: [user.firstName, " ", user.lastName] }), _jsx("span", { className: "block text-sm font-light text-gray-500 truncate dark:text-gray-400", children: user.email })] }), _jsx(Link, { to: "/organization", className: "block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white", children: "My organization" }), _jsx(Link, { to: "/account", className: "block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white", children: "Account settings" }), _jsx(Link, { to: "/auth/logout", onClick: doLogout, className: "block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white border-t mt-2 border-gray-100 dark:border-gray-600", children: "Log out" })] }));
}
