import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useGetDatabaseQuery } from 'src/client/app/api/siteApi';
import { Card, Spinner } from 'src/client/features/ui';
export function Database(props) {
    const { data, isLoading } = useGetDatabaseQuery(props.website.Website, { skip: props.website === undefined });
    const getDatabasePassword = async () => {
        setLoadingDbPassword(true);
        const doLoadPassword = () => {
            setLoadingDbPassword(false);
            setDbPassword(data?.databasePassword ?? '');
        };
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(doLoadPassword()), 3000);
        });
    };
    const [loadingDbPassword, setLoadingDbPassword] = useState(false);
    const [dbPassword, setDbPassword] = useState('');
    return (_jsxs(Card, { title: "Database", collapsable: true, children: [isLoading && _jsx(Spinner, {}), data &&
                _jsxs("p", { className: "text-gray-600 dark:text-gray-400", children: [_jsx("strong", { children: "Type:" }), " MySQL", _jsx("br", {}), _jsx("strong", { children: "Hostname:" }), " ", data.databaseHost, _jsx("br", {}), _jsx("strong", { children: "Database:" }), " ", data.databaseName, _jsx("br", {}), _jsx("strong", { children: "Username:" }), " ", data.databaseUser, _jsx("br", {}), _jsx("strong", { children: "Password:" }), "\u00A0", loadingDbPassword && _jsx(Spinner, { className: "inline" }), (!loadingDbPassword && !dbPassword) && (_jsx("span", { onClick: getDatabasePassword, children: _jsx("em", { className: "underline cursor-pointer", children: "click to show" }) })), (!loadingDbPassword && dbPassword) && (dbPassword), _jsx("br", {}), _jsx("strong", { children: "Table Prefix:" }), " ", data.tablePrefix, _jsx("br", {})] }), !data && (_jsx("p", { className: "italic text-gray-600 dark:text-gray-400", children: "There is no database set up for this website. Please contact Support to create a database." }))] }));
}
