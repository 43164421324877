import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { WebsiteScanFindingSeverity } from 'src/shared/types.g';
const FINDING_SEVERITY_CLASSES = {
    CRITICAL: 'bg-red-700',
    HIGH: 'bg-orange-400',
    MEDIUM: 'bg-yellow-300',
    LOW: 'bg-blue-400',
    INFORMATIONAL: 'bg-green-400',
};
export function FindingAggregateCountBarGraph(props) {
    const total = useMemo(() => Object.values(props.aggregateCounts).reduce((t, c) => t + c, 0), [props.aggregateCounts]);
    const severityWidth = (severity) => Math.round((props.aggregateCounts[severity] / total) * 100);
    const severities = [
        WebsiteScanFindingSeverity.CRITICAL,
        WebsiteScanFindingSeverity.HIGH,
        WebsiteScanFindingSeverity.MEDIUM,
        WebsiteScanFindingSeverity.LOW,
        WebsiteScanFindingSeverity.INFORMATIONAL
    ];
    return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "w-full bg-gray-100 h-4 border-4 border-gray-200 dark:border-gray-800/90 rounded-lg flex flex-row mb-2", children: severities.map((s, i) => _jsx("div", { className: `h-full shadow-lg ${FINDING_SEVERITY_CLASSES[s]} ${i === 0 && 'rounded-l'} ${i === 3 && 'rounded-r'}`, style: { width: `${severityWidth(s)}%` } }, i)) }), _jsx("div", { className: "flex flex-row space-x-8 justify-center", children: severities.map((s, i) => _jsxs("div", { className: "flex flex-row space-x-2 items-center", children: [_jsx("div", { className: `h-4 w-4 rounded-full border-4 border-gray-200/75 dark:border-gray-800/75 ${FINDING_SEVERITY_CLASSES[s]}` }), _jsxs("span", { className: "capitalize text-sm", children: [s.toLowerCase(), " (", props.aggregateCounts[s] || 0, ")"] })] }, i)) })] }));
}
