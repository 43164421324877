export const RoleTitles = {
    ORGOWNER: 'Organization Owner',
    ORGADMIN: 'Organization Administrator',
    USER: 'Standard User',
};
export const RoleCapabilityDescriptions = {
    ORGOWNER: 'Create, update, delete, and access all resources in this Organization, including all owners, all users, and billing information.',
    ORGADMIN: 'Create, update, delete, and access all assigned resources in this organization, but can\'t modify Owners and billing information.',
    USER: 'View and access all assigned resources. You can\'t add new members or create new websites.',
};
