import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useGetRepositoryQuery, useGetRepositoryBranchesQuery } from 'src/client/app/api/siteApi';
import { useAppSelector } from 'src/client/app/hooks';
import { Alert, Button, Card, Select, Icon, Spinner } from '../../../ui';
import { getSelectedSite } from '../siteSlice';
export function CodeRepository() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data: repository, error: repositoryError, isLoading: isLoadingRepository } = useGetRepositoryQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const { data: branches, error: branchesError, isLoading: isLoadingBranches, refetch } = useGetRepositoryBranchesQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const selectBranchOptions = branches?.branches.map((branch) => ({ label: branch, value: branch }));
    return (_jsxs("div", { children: [repositoryError && repositoryError.status === 400 &&
                _jsx(Alert, { type: "info", children: "This website does not have a connected code repository. Please contact support in order to connect a repository." }), repository &&
                _jsxs("div", { className: "space-y-6", children: [_jsxs(Card, { title: "Repository Information", children: [_jsxs("p", { className: "py-4", children: [_jsx("strong", { children: "Provider:" }), " Wire & Byte + GitLab", _jsx("a", { href: 'https://git.wireandbyte.com', target: "_BLANK", rel: "noreferrer noopener", title: "Open repository provider in new tab", children: _jsx(Icon, { icon: "openExternal", className: "color-brand-primary inline w-4 h-4 ml-2 -mt-1" }) }), _jsx("br", {}), _jsx("strong", { children: "Repository URL:" }), " ", repository.url, _jsx("br", {}), _jsx("strong", { children: "Repository Name:" }), " ", repository.name] }), _jsx("small", { children: _jsx("em", { children: "Your website plan includes one Git repository, powered by GitLab and hosted by Wire & Byte. Contact Support if you want to use an external Git repository (e.g. on GitHub)." }) })] }), _jsx(Card, { title: "Continuous Deployment", children: _jsxs("div", { className: "space-y-4", children: [_jsxs("div", { children: [_jsxs("div", { className: "flex items-center", children: [_jsx("input", { id: "checked-checkbox", type: "checkbox", value: "", className: "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" }), _jsx("label", { htmlFor: "checked-checkbox", className: "ml-2 font-bold text-gray-900 dark:text-gray-300", children: "Enable Continuous Deployment?" })] }), _jsx("p", { children: _jsx("small", { className: "text-gray-600", children: _jsx("em", { children: "Last changed 6 months ago" }) }) })] }), _jsx("p", { children: "When enabled, any commits to the selected Git branch will be automatically deployed to this environment." }), isLoadingBranches && _jsx(Spinner, {}), selectBranchOptions &&
                                        _jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-row items-center space-x-4", children: [_jsx("label", { className: "label block", htmlFor: "branches", children: "Select a branch to track:" }), _jsx(Select, { className: "w-fit", id: "branches", options: selectBranchOptions })] }) }), _jsx("div", { className: "flex flex-row justify-end", children: _jsx(Button, { className: "self-end", type: "submit", role: "primary", children: "Submit" }) })] }) })] })] }));
}
