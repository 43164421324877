import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFeatureConfigsQuery, useSetFeatureConfigMutation } from 'src/client/app/api/siteApi';
import { getSelectedSite } from '../../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { ManagedWordPress } from './ManagedWordPress';
import { AutomaticBackups } from './AutomaticBackups';
import { Database } from './Database';
import { CodeRepository } from './CodeRepository';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
export function Features() {
    const selectedSite = useAppSelector(getSelectedSite);
    const [setConfig, { isLoading, isError }] = useSetFeatureConfigMutation();
    const toast = useToaster();
    const { data } = useGetFeatureConfigsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const onChange = async (config) => {
        try {
            await setConfig({ config, website: selectedSite.Website }).unwrap();
            toast({
                title: 'Success!',
                message: 'Your configuration change has been saved.',
                type: 'success'
            });
        }
        catch (e) {
            toast({
                title: 'Error!',
                message: e.data.message ?? 'An error occured! Please refresh and try again.',
                type: 'error'
            });
        }
    };
    return (_jsxs("div", { className: "space-y-4 relative", children: [_jsx(ManagedWordPress, { configName: WebsiteFeatureConfigFields.WORDPRESS, config: data?.config.WORDPRESS, website: selectedSite, onChange: onChange }), _jsx(AutomaticBackups, { configName: WebsiteFeatureConfigFields.AUTOMATIC_BACKUPS, config: data?.config.AUTOMATIC_BACKUPS, website: selectedSite, onChange: onChange }), _jsx(Database, { website: selectedSite }), _jsx(CodeRepository, { property: selectedSite })] }));
}
