import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from 'src/client/app/hooks';
import { ToastNotification } from './ToastNotification';
import { selectAllToasts } from './toasterSlice';
import { toasterActions } from './toasterActions';
export function ToastNotificationContainer() {
    const toasts = useAppSelector(selectAllToasts);
    const dispatch = useAppDispatch();
    const removeToast = (toast) => {
        dispatch(toasterActions.removeToastByProperties(toast));
    };
    return (_jsx("div", { "aria-live": "assertive", className: "pointer-events-none fixed bottom-0 right-0 w-1/4 flex items-end px-4 py-6 sm:items-start sm:p-6", children: _jsx("div", { className: "flex w-full flex-col items-center space-y-4 sm:items-end", children: toasts.map((toast, i) => _jsx(ToastNotification, { ...toast, onHide: () => removeToast(toast) }, i)) }) }));
}
