import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { selectGetTheme, toggleTheme } from '../../uiSlice';
import { Icon } from '../../icon/Icon';
export function ToggleTheme() {
    const theme = useAppSelector(selectGetTheme);
    const dispatch = useAppDispatch();
    const toggle = () => dispatch(toggleTheme());
    return (_jsx("div", { onClick: toggle, className: "\n        p-2 0 rounded-full text-gray-500 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 \n        dark:text-gray-100 dark:hover:text-white dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-600 \n        cursor-pointer\n        ", title: "Toggle Color Scheme", children: _jsx(Icon, { icon: theme === 'dark' ? 'moon' : 'sun', className: "h-6 w-6" }) }));
}
