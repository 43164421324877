import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsGroup } from "../SettingsGroup";
import { Input, Button, Spinner } from "../../ui";
import { useUpdateUserMutation } from "src/client/app/api/userApi";
import { PasswordStrengthMeter } from "./PasswordStrengthMeter";
import { useToaster } from "../../ui/toaster/useToaster";
export function UpdatePassword(props) {
    const [updateUser, { isLoading, error }] = useUpdateUserMutation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const toast = useToaster();
    const doSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        try {
            if (!password) {
                throw ('Password is required.');
            }
            if (password !== confirmPassword) {
                throw ('Passswords do not match.');
            }
            try {
                await updateUser({
                    userId: props.user.id,
                    data: { password }
                }).unwrap();
                toast({
                    title: 'Password Changed',
                    message: 'Your password has been successfully changed.',
                    type: 'success'
                });
            }
            catch (e) {
                throw (e.data?.message ?? 'An unknown error occured. Please refresh and try again.');
            }
        }
        catch (e) {
            setErrorMessage(e);
        }
    };
    return (_jsx(SettingsGroup, { title: "Update Password", description: _jsx(PasswordStrengthMeter, { password: password }), children: _jsxs("form", { className: "flex flex-col space-y-8", onSubmit: doSubmit, children: [_jsxs("div", { className: "flex flex-row space-x-4", children: [_jsxs("div", { className: "w-1/2", children: [_jsx("label", { htmlFor: "newPassword", children: "New Password" }), _jsx(Input, { type: "password", name: "newPassword", id: "newPassword", onChange: (e) => setPassword(e.target.value) })] }), _jsxs("div", { className: "w-1/2", children: [_jsx("label", { htmlFor: "reconfirmNewPassword", children: "Re-enter New Password" }), _jsx(Input, { type: "password", name: "reconfirmNewPassword", id: "reconfirmNewPassword", onChange: (e) => setConfirmPassword(e.target.value) })] })] }), errorMessage && _jsx("div", { className: "text-red-500", children: errorMessage }), _jsx("div", { children: _jsx(Button, { role: "primary", type: "submit", buttonSize: "large", disabled: isLoading, children: _jsx("div", { className: "flex flex-row justify-center", children: isLoading ? _jsxs(_Fragment, { children: [_jsx(Spinner, {}), "Updating..."] }) : _jsx(_Fragment, { children: "Update Password" }) }) }) })] }) }));
}
