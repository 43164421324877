import { api } from './api';
export const siteApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSites: build.query({
            query: () => 'site/sites',
            providesTags: ['Site']
        }),
        getSite: build.query({
            query: (id) => `site/site/${id}`,
            providesTags: ['Site']
        }),
        getProperties: build.query({
            query: () => 'site/properties',
            providesTags: ['Site', 'Property']
        }),
        getProperty: build.query({
            query: (id) => `site/property/${id}`,
            providesTags: ['Site', 'Property']
        }),
        getSiteMonitor: build.query({
            query: (website) => `/site/site/${website.id}/monitor`,
            providesTags: ['Site', 'Monitor']
        }),
        getTasks: build.query({
            query: (website) => `/site/site/${website.id}/tasks`,
            providesTags: ['Site', 'Task']
        }),
        getTrafficStats: build.query({
            query: (website) => `site/site/${website.id}/traffic-stats`,
            providesTags: ['Site']
        }),
        getAliases: build.query({
            query: (website) => `site/site/${website.id}/aliases`,
            providesTags: ['Site', 'Alias']
        }),
        addAlias: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/aliases/alias`,
                method: 'POST',
                body: { alias: payload.alias },
            }),
            invalidatesTags: ['Site', 'Alias']
        }),
        deleteAlias: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/aliases/alias/${payload.aliasId}`,
                method: 'DELETE',
                body: {}
            }),
            invalidatesTags: ['Site', 'Alias']
        }),
        createBackup: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/backups`,
                method: 'POST',
                body: { label: payload.label }
            }),
            invalidatesTags: ['Site', 'Backups']
        }),
        getBackups: build.query({
            query: (website) => `site/site/${website.id}/backups`,
            providesTags: ['Site', 'Backups']
        }),
        getBackupFile: build.query({
            query: (arg) => `site/site/${arg.website.id}/backup/${arg.backup.id}/file`
        }),
        getRepository: build.query({
            query: (website) => `site/site/${website.id}/repository`
        }),
        getRepositoryBranches: build.query({
            query: (website) => `site/site/${website.id}/repository/branches`
        }),
        getFeatureConfigs: build.query({
            query: (website) => `site/site/${website.id}/config`,
            providesTags: ['Site', 'FeatureConfigs']
        }),
        getFeatureConfig: build.query({
            query: (arg) => `site/site/${arg.website.id}/config/${arg.configField}`,
            providesTags: ['Site', 'FeatureConfigs']
        }),
        setFeatureConfig: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/config/${payload.config.configField}`,
                method: 'PATCH',
                body: payload.config
            }),
            invalidatesTags: ['Site', 'FeatureConfigs']
        }),
        getTasksByType: build.query({
            query: (arg) => `task/tasks?taskType=${arg.taskType || ''}&resourceType=Website&resourceId=${arg.website.id}`,
            providesTags: ['Site', 'Task']
        }),
        getSSHPublicKeys: build.query({
            query: (website) => `site/site/${website.id}/ssh-keys`,
            providesTags: ['SSHKey']
        }),
        getSSHPublicKey: build.query({
            query: (arg) => `site/site/${arg.website.id}/ssh-keys/key/${arg.keyId}`,
            providesTags: ['SSHKey']
        }),
        addSSHPublicKey: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/ssh-keys/key`,
                method: 'POST',
                body: { ...payload.key }
            }),
            invalidatesTags: ['SSHKey']
        }),
        deleteSSHPublicKey: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/ssh-keys/key/${payload.keyId}`,
                method: 'DELETE',
                body: {}
            }),
            invalidatesTags: ['SSHKey']
        }),
        getDatabase: build.query({
            query: (website) => `site/site/${website.id}/database`
        }),
        getCustomVcl: build.query({
            query: (website) => `site/site/${website.id}/vcl`,
            providesTags: ['Site', 'Vcl']
        }),
        setCustomVcl: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/vcl`,
                method: 'POST',
                body: { ...payload.vcl },
            }),
            invalidatesTags: ['Site', 'Vcl']
        }),
        doPurgeServerCache: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.id}/cache/purge`,
                method: 'POST'
            }),
            invalidatesTags: ['Site']
        }),
        getGeneratedLogFiles: build.query({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/log-files?skip=${payload.skip}&take=${payload.take}`,
            }),
            providesTags: ['Site', 'Logs']
        }),
        getGeneratedLogFile: build.query({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/log-files/${payload.id}/file`,
            }),
            providesTags: ['Site', 'Logs']
        }),
        doGenerateLogFile: build.mutation({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/log-files/new`,
                body: { ...payload.logFileArgs },
                method: 'POST',
            }),
            invalidatesTags: ['Site', 'Logs']
        }),
        getWordPressSecurityScansWithFindings: build.query({
            query: (payload) => ({
                url: `site/site/${payload.id}/security/scans/wordpress`,
            }),
            providesTags: ['Site', 'Security', 'WordPress']
        }),
        getWordPressSecurityScanWithFindings: build.query({
            query: (payload) => ({
                url: `site/site/${payload.website.id}/security/scans/wordpress/${payload.scanId}`,
            }),
            providesTags: ['Site', 'Security', 'WordPress']
        }),
        doProvisionNewEnvironment: build.mutation({
            query: (payload) => ({
                url: `site/property/${payload.property.id}/create`,
                body: payload.newEnvironment,
                method: 'POST',
            }),
            invalidatesTags: ['Site']
        }),
        getWordPressUpdateActivity: build.query({
            query: (payload) => ({
                url: `site/site/${payload.id}/wordpress/updates`,
            }),
            providesTags: ['Site', 'WordPress']
        })
    })
});
export const { useGetSitesQuery, useGetSiteQuery, useGetPropertyQuery, useGetPropertiesQuery, useGetSiteMonitorQuery, useGetTrafficStatsQuery, useGetAliasesQuery, useAddAliasMutation, useDeleteAliasMutation, useCreateBackupMutation, useGetBackupsQuery, useGetBackupFileQuery, useGetRepositoryQuery, useGetRepositoryBranchesQuery, useSetFeatureConfigMutation, useGetFeatureConfigsQuery, useGetFeatureConfigQuery, useGetTasksQuery, useGetTasksByTypeQuery, useGetSSHPublicKeysQuery, useGetSSHPublicKeyQuery, useAddSSHPublicKeyMutation, useDeleteSSHPublicKeyMutation, useGetDatabaseQuery, useGetCustomVclQuery, useSetCustomVclMutation, useDoPurgeServerCacheMutation, useGetGeneratedLogFileQuery, useGetGeneratedLogFilesQuery, useDoGenerateLogFileMutation, useGetWordPressSecurityScansWithFindingsQuery, useGetWordPressSecurityScanWithFindingsQuery, useDoProvisionNewEnvironmentMutation, useGetWordPressUpdateActivityQuery, } = siteApi;
export default siteApi;
