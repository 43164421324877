import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { format, formatDistanceToNowStrict } from 'date-fns';
import { useGetAgentStatusQuery } from 'src/client/app/api/platformApi';
import { Alert, Spinner } from '../../ui';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { useEffect, useState } from 'react';
const REFRESH_INTERVAL_SECONDS = 10;
export function AgentStatus() {
    const { data, isLoading, error, refetch } = useGetAgentStatusQuery();
    const [refreshIn, setRefreshIn] = useState(REFRESH_INTERVAL_SECONDS);
    useEffect(() => {
        const timer = setTimeout(() => setRefreshIn(refreshIn - 1), 1000);
        if (refreshIn === 0) {
            setRefreshIn(REFRESH_INTERVAL_SECONDS);
            refetch();
        }
        return () => clearTimeout(timer);
    }, [refreshIn]);
    return (_jsxs("div", { className: "p-4", children: [_jsxs(Alert, { type: "info", children: ["Refreshing in ", refreshIn, " seconds..."] }), isLoading && _jsx(Spinner, {}), (!isLoading && data) && (_jsxs("table", { className: "w-full text-sm text-left text gray-500 dark:text-gray-400", children: [_jsx("thead", { className: "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-4", children: _jsxs("tr", { children: [_jsx("th", { children: "Hostname" }), _jsx("th", { children: "Agent Version" }), _jsx("th", { children: "Heartbeat Type" }), _jsx("th", { children: "Last Seen" })] }) }), _jsx("tbody", { children: data.map((hb) => (_jsxs("tr", { className: "bg-white border-b dark:bg-gray-800 dark:border-gray-700", children: [_jsx("td", { children: hb.serverName }), _jsx("td", { children: hb.version }), _jsx("td", { children: hb.type }), _jsx("td", { children: _jsx("abbr", { title: format(new Date(hb.timestamp), DEFAULT_DATE_FORMAT), children: formatDistanceToNowStrict(new Date(hb.timestamp), { addSuffix: true, unit: 'minute' }) }) })] }, hb.id))) })] }))] }));
}
