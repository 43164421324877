import { Bell, CaretRight, Chart, CheckCircle, ChevronDown, ChevronRight, Code, EnvelopeOpen, ExclamationCircle, FolderArrowDown, Gear, GitHub, Help, Information, Items, Key, Lightning, List, Locked, Menu, Moon, OpenExternal, Pencil, PlusCircle, Servers, Shield, Sun, Trash, Unlocked, User, Warning, Websites, WordPress, WrenchScrewdriver, X } from "./icons";
export const Icons = {
    bell: Bell,
    caretRight: CaretRight,
    chart: Chart,
    checkCircle: CheckCircle,
    chevronDown: ChevronDown,
    chevronRight: ChevronRight,
    code: Code,
    envelopeOpen: EnvelopeOpen,
    exclamationCircle: ExclamationCircle,
    folderArrowDown: FolderArrowDown,
    gear: Gear,
    github: GitHub,
    help: Help,
    information: Information,
    items: Items,
    key: Key,
    lightning: Lightning,
    list: List,
    locked: Locked,
    menu: Menu,
    moon: Moon,
    openExternal: OpenExternal,
    pencil: Pencil,
    plusCircle: PlusCircle,
    servers: Servers,
    shield: Shield,
    sun: Sun,
    trash: Trash,
    unlocked: Unlocked,
    user: User,
    warning: Warning,
    websites: Websites,
    wordpress: WordPress,
    wrenchScrewdriver: WrenchScrewdriver,
    x: X,
};
export const defaultProps = {
    className: 'h-6 w-6'
};
export function Icon(props) {
    const p = { ...props, ...defaultProps };
    return Icons[p.icon] ? (Icons[p.icon])(props) : null;
}
