import { jsx as _jsx } from "react/jsx-runtime";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}
function generateMockDataset(length, max = 150) {
    return Array.from({ length }, () => getRandomInt(1, max));
}
export function DailyStatusCodeStats(props) {
    const days = props.stats.slice(-props.days);
    const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const chartLabels = days.map(d => (DAYS_OF_WEEK[new Date(d.startTime).getDay()]));
    const chartOptions = {
        responsive: true,
        scales: {
            x: { stacked: true },
            y: { stacked: true }
        },
        plugins: {
            legend: { position: 'right' }
        }
    };
    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '2xx (OK)',
                data: generateMockDataset(7),
                borderColor: 'rgba(50, 255, 132, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(99, 255, 132, .8)");
                    gradient.addColorStop(1, "rgba(99, 255, 132, .1)");
                    return gradient;
                },
            },
            {
                label: '3xx (Redirection)',
                data: generateMockDataset(7),
                borderColor: 'rgba(54, 162, 235, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(54, 162, 235, .8)");
                    gradient.addColorStop(1, "rgba(54, 162, 235, .1)");
                    return gradient;
                },
            },
            {
                label: '4xx (Client Error)',
                data: generateMockDataset(7, 20),
                borderColor: 'rgba(236, 157, 54, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(236, 157, 54, .8)");
                    gradient.addColorStop(1, "rgba(236, 157, 54, .1)");
                    return gradient;
                },
            },
            {
                label: '5xx (Server Error)',
                data: generateMockDataset(7, 10),
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(255, 99, 132, .8)");
                    gradient.addColorStop(1, "rgba(255, 99, 132, .1)");
                    return gradient;
                },
            },
        ]
    };
    return (_jsx(Bar, { datasetIdKey: 'id', data: data, options: chartOptions }));
}
