import api, { authApi } from '../../app/api/authApi';
import { authSlice } from './authSlice';
import { PERSISTENCE_ROOT_KEY } from '../../app/app.consts';
import storage from 'redux-persist/lib/storage';
export const authActions = {
    logout: () => (dispatch, getState) => {
        const session = (getState()).auth.session;
        storage.removeItem(`persist:${PERSISTENCE_ROOT_KEY}:${authSlice.name}`);
        dispatch(api.util.resetApiState());
        if (session) {
            dispatch(authApi.endpoints.logout.initiate(session));
        }
        else {
            dispatch(authSlice.actions.logout);
        }
    },
    refresh: (session) => (dispatch, getState) => {
        dispatch(authSlice.actions.refresh(session));
    },
};
