import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag } from '../ui';
import { getEnvTagType } from '../dashboard/sites/SiteSelector/SiteSelectorBar';
import { useGetClusterInfoQuery } from 'src/client/app/api/platformApi';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
export function OrganizationWebsitesListRowItem(props) {
    const { data: cluster, error, isLoading } = useGetClusterInfoQuery(props.website.clusterId || 0);
    return (_jsxs("tr", { className: "bg-white border-b dark:bg-gray-800 dark:border-gray-700", children: [_jsx("td", { children: props.website.hostname }), _jsx("td", { children: _jsx(Tag, { type: getEnvTagType(props.website.environment), size: "small", children: props.website.environment }) }), _jsx("td", { children: format(new Date(props.website.createdAt), DEFAULT_DATE_FORMAT) }), _jsx("td", { children: (cluster && cluster.id === props.website.clusterId) ? `${cluster.name} (${cluster.location})` : props.website.clusterId }), _jsx("td", { children: props.website.databaseId ? 'Yes' : 'No' })] }, props.website.id));
}
