import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { format } from 'date-fns';
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { Spinner, Button, Card, NoContent } from 'src/client/features/ui';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { getReadableFilesize } from 'src/client/app/helpers/get-readable-filesize';
import { useGetGeneratedLogFilesQuery } from 'src/client/app/api/siteApi';
import { CreateLogExport } from './CreateLogExport';
import { LogFileType } from 'src/shared/types.g';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
export function Logs() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetGeneratedLogFilesQuery({ website: selectedSite.Website }, { skip: selectedSite.Website === undefined });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToaster();
    const onSubmitNewExportRequest = () => {
        toast({
            title: 'Log File Export',
            message: "Your log file is on the way! We'll send you an email notification when it's ready for you to download.",
            type: 'success'
        });
    };
    return (_jsxs(_Fragment, { children: [selectedSite.Website && _jsx(CreateLogExport, { isOpen: isModalOpen, onClose: () => setIsModalOpen(false), onSubmit: onSubmitNewExportRequest, website: selectedSite.Website }), isLoading && _jsx(Spinner, {}), (!isLoading && data && data.length > 0) &&
                _jsxs("div", { children: [_jsx("div", { className: "text-right my-4", children: _jsx(Button, { onClick: () => setIsModalOpen(true), children: "+ Create New Log Export" }) }), _jsx(Card, { title: "Latest Log Exports", children: data.map((logFile, key) => (_jsxs("div", { className: "p-4 border-b border-gray-200 last:border-b-0 dark:border-gray-600 flex flex-row justify-between items-center", children: [_jsxs("div", { children: [_jsxs("div", { className: "font-bold mb-2", children: [logFile.type === LogFileType.HTTP_ACCESS && _jsx(_Fragment, { children: "Web Server Access Logs: " }), logFile.type === LogFileType.HTTP_ERROR && _jsx(_Fragment, { children: "Web Server Error Logs: " }), _jsxs("span", { children: [format(new Date(logFile.from), 'PP'), " to ", format(new Date(logFile.to), 'PP')] })] }), _jsxs("div", { className: "flex flex-col font-sm text-gray-600 dark:text-gray-400", children: [_jsxs("div", { className: "flex flex-row space-x-2", children: [logFile.startedAt && _jsxs("span", { children: ["Requested on ", format(new Date(logFile.startedAt), DEFAULT_DATE_FORMAT)] }), _jsx("span", { children: " | " }), logFile.readyAt && _jsxs("span", { children: ["Ready on ", format(new Date(logFile.readyAt), DEFAULT_DATE_FORMAT), " "] }), !logFile.readyAt && _jsx("span", { children: "Export in progress: not yet available for download. " })] }), logFile.creator &&
                                                        _jsxs("div", { className: "font-sm", children: ["Exported by ", logFile.creator.firstName, " ", logFile.creator.lastName, " (", logFile.creator.email, ")"] })] })] }), _jsx(Button, { disabled: !logFile.readyAt, onClick: () => alert(logFile.id), children: _jsxs(_Fragment, { children: [!logFile.readyAt && 'Exporting..', logFile.readyAt &&
                                                    _jsxs(_Fragment, { children: ["Download Log File", logFile.size && _jsxs(_Fragment, { children: [" (", getReadableFilesize(logFile.size), ")"] })] })] }) })] }, key))) })] }), !isLoading && (!data || !data.length) &&
                _jsx(NoContent, { title: "No Log Exports Found", text: "You can start a new export by clicking the button below.", icon: "list", buttonText: "+ Start New Log Export", buttonOnClick: () => setIsModalOpen(true) })] }));
}
