import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../app/api/authApi';
import { organizationApi } from '../../app/api/organizationApi';
const initialState = { authenticated: false, session: undefined };
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => initialState,
        refresh: (state, action) => { state.session = action.payload; },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
            state.authenticated = true;
            state.session = action.payload;
        })
            .addMatcher(authApi.endpoints.googleSsoLogin.matchFulfilled, (state, action) => {
            state.authenticated = true;
            state.session = action.payload;
        })
            .addMatcher(authApi.endpoints.login.matchRejected, () => initialState)
            .addMatcher(authApi.endpoints.googleSsoLogin.matchRejected, () => initialState)
            .addMatcher(authApi.endpoints.logout.matchFulfilled, () => initialState)
            .addMatcher(authApi.endpoints.logout.matchRejected, () => initialState)
            .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, action) => { state.session = action.payload; })
            .addMatcher(organizationApi.endpoints.changeSelectedOrganization.matchFulfilled, (state, action) => {
            state.session = action.payload;
        });
    },
});
export const selectIsAuthenticated = (state) => state.auth.authenticated;
export const selectSession = (state) => state.auth.session;
export default authSlice.reducer;
