import { api } from './api';
export const platformApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPlatformStatus: build.query({
            query: () => 'platform/status',
        }),
        getClusterInfo: build.query({
            query: (clusterId) => `platform/cluster/${clusterId}`,
        }),
        getClusterCapabilities: build.query({
            query: (clusterId) => `platform/cluster/${clusterId}/capabilities`,
        }),
        getAgentStatus: build.query({
            query: () => 'platform/agent-status',
            keepUnusedDataFor: 0,
        })
    })
});
export const { useGetPlatformStatusQuery, useGetClusterInfoQuery, useGetClusterCapabilitiesQuery, useGetAgentStatusQuery, } = platformApi;
export default platformApi;
