import { jsx as _jsx } from "react/jsx-runtime";
import ReactTailwindDatepicker from 'react-tailwindcss-datepicker';
const defaultProps = {
    displayFormat: 'MM/DD/YYYY',
    separator: 'to',
    classNames: {
        input: () => `
            relative w-full border border-1 sm:text-sm 
            bg-white border-gray-100 placeholder-gray-400 text-gray-600
            dark:bg-gray-700 dark:border-gray-600 dark:text-white rounded-none
            focus:border-brand-primary dark:focus:border-brand-primary focus:ring-0
        `,
        container: () => undefined,
        toggleButton: () => `absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed null`,
        footer: () => undefined
    }
};
export function Datepicker(props) {
    const mergedProps = {
        ...defaultProps,
        ...props
    };
    return (_jsx(ReactTailwindDatepicker, { ...mergedProps }));
}
