import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { SettingsGroup } from "../SettingsGroup";
import { Button, Icon, Spinner, Confirm } from 'src/client/features/ui';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { useDisableOTPMutation } from "src/client/app/api/otpApi";
import { useRefreshTokenMutation } from 'src/client/app/api/authApi';
import { useAppSelector } from 'src/client/app/hooks';
import { selectSession } from 'src/client/features/auth/authSlice';
import { OtpSetupModal } from './OtpSetupModal';
export function TwoFactorAuthentication(props) {
    const session = useAppSelector(selectSession);
    const [isSetupOtpModalOpen, setIsSetupOtpModalOpen] = useState(false);
    const [isDisableOtpConfirmationOpen, setIsDisableOtpConfirmationOpen] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const toast = useToaster();
    const [refreshSession, { isLoading }] = useRefreshTokenMutation();
    const [disableOtp] = useDisableOTPMutation();
    const isOtpEnabled = session.subjects.user.otpEnabled;
    const onCloseModal = async () => {
        setIsBusy(true);
        await refreshSession(session).unwrap();
        setIsSetupOtpModalOpen(false);
        setIsBusy(false);
    };
    const doDisable2FA = async () => {
        setIsBusy(true);
        try {
            await disableOtp();
            toast({
                title: '2FA Disabled',
                message: 'Two-Factor Authentication has been disabled.',
                type: 'success'
            });
        }
        catch (e) {
            toast({
                title: 'Error',
                message: 'An error occured while attempting to disable Two-Factor Authentication.',
                type: 'success'
            });
        }
        await refreshSession(session).unwrap();
        setIsBusy(false);
    };
    return (_jsxs(SettingsGroup, { title: "Two-Factor Authentication", description: "For stronger account security, you can use Authy, Google Authenticator, or another compatible 2FA app on your phone.", children: [!isOtpEnabled &&
                _jsx(OtpSetupModal, { title: "Set Up Two-Factor Authentication", isOpen: isSetupOtpModalOpen, onClose: onCloseModal }), _jsxs("div", { children: [(isLoading || isBusy) && _jsx(Spinner, {}), (!isLoading && !isBusy) &&
                        _jsxs("div", { className: "flex flex-col space-y-6", children: [isOtpEnabled &&
                                    _jsxs(_Fragment, { children: [_jsx(Confirm, { title: "Disable 2FA", isOpen: isDisableOtpConfirmationOpen, message: "Are you sure you want to disable 2FA? This will greatly decrease the security of your account.", onConfirm: doDisable2FA, onCancel: () => setIsDisableOtpConfirmationOpen(false) }), _jsx("p", { children: "Two-factor authentication is ENABLED for your account." }), _jsx("a", { onClick: () => setIsDisableOtpConfirmationOpen(true), className: "text-red-600 dark:text-red-400 hover:cursor-pointer", children: "Disable 2FA" })] }), !isOtpEnabled &&
                                    _jsxs("div", { children: [_jsxs("p", { className: "mb-2", children: ["Two-factor authentication is not set up for your account.", _jsx("br", {}), "We ", _jsx("strong", { children: "highly recommend" }), " enabling this feature."] }), _jsxs(Button, { role: "primary", buttonSize: "large", onClick: () => setIsSetupOtpModalOpen(true), className: "flex space-x-2 items-center", children: [_jsx(Icon, { icon: "shield", className: "w-4 h-4" }), _jsx("span", { children: "Setup Two-Factor Authentication" })] })] })] })] })] }));
}
