import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getSelectedSite } from '../siteSlice';
import { SiteSelectorModal } from './SiteSelectorModal';
import { useAppSelector } from 'src/client/app/hooks';
import { Button, Card, Tag, Icon } from 'src/client/features/ui';
import { Environment } from 'src/shared/types.g';
export const getEnvTagType = (env) => {
    const envTagTypes = {
        [Environment.DEVELOPMENT]: 'success',
        [Environment.STAGING]: 'info',
        [Environment.PRODUCTION]: 'important',
    };
    return envTagTypes[env];
};
export function SiteSelectorBar() {
    const selectedSite = useAppSelector(getSelectedSite);
    const [isDialogOpen, setDialogIsOpen] = useState(false);
    const onSelectSite = () => {
        setDialogIsOpen(false);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "text-center py-4", children: [isDialogOpen &&
                    _jsx(SiteSelectorModal, { isOpen: true, allowCancel: true, onClose: onSelectSite }), _jsx(Card, { children: _jsxs("div", { className: "flex justify-between items-center", children: [(!selectedSite || !selectedSite.Website) && _jsx(_Fragment, { children: "No website selected." }), (selectedSite && selectedSite.Website) &&
                                _jsxs(Tag, { type: "regular", className: "flex space-x-2 items-center rounded-md", children: [_jsx("span", { children: selectedSite.name }), _jsx("span", { className: "opacity-60", children: "\u00BB" }), _jsx("span", { children: selectedSite.Website.hostname }), _jsx(Tag, { className: "ml-3", type: getEnvTagType(selectedSite.Website.environment), size: "small", children: selectedSite.Website.environment }), _jsx("a", { href: `//${selectedSite.Website.hostname}`, target: "_BLANK", rel: "noreferrer noopener", title: "Open this website in a new tab", className: "text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white", children: _jsx(Icon, { icon: "openExternal", className: "w-5 h-5" }) })] }), _jsx(Button, { onClick: () => setDialogIsOpen(true), children: "Select Site" })] }) })] }) }));
}
