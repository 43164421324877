import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale } from 'chart.js';
import 'chart.js/auto';
import { NoContent } from 'src/client/features/ui';
ChartJS.register(LineController, LineElement, PointElement, LinearScale);
const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export function TrafficGraph(props) {
    const days = props.stats.slice(-props.days);
    const barChartOptions = {
        elements: {
            line: {
                tension: 0.3
            }
        },
        scales: { x: {}, y: { min: 10 } },
        plugins: {
            legend: { display: true },
            filler: { propagate: false }
        },
        interaction: { intersect: true }
    };
    const barChartLabels = days.map(d => DAYS_OF_WEEK[new Date(d.startTime).getDay()]);
    const barChartData = {
        labels: barChartLabels,
        datasets: [
            {
                data: days.map(d => Number(d.uniqueVisitors)),
                label: 'Unique Visitors',
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(255, 99, 132, .5)");
                    gradient.addColorStop(1, "rgba(255, 99, 132, .1)");
                    return gradient;
                },
            },
            {
                data: days.map(d => Number(d.pageViews)),
                label: 'Requests',
                borderColor: 'rgba(54, 162, 235, 1)',
                fill: 'start',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 100, 0, 800);
                    gradient.addColorStop(0, "rgba(54, 162, 235, .5)");
                    gradient.addColorStop(1, "rgba(54, 162, 235, .1)");
                    return gradient;
                },
            }
        ]
    };
    return (_jsxs(_Fragment, { children: [props.stats.length === 0 &&
                _jsx(NoContent, { className: "my-8", icon: "exclamationCircle", title: "Error loading traffic graphs!", text: "Please refresh and try again." }), props.stats.length > 0 && _jsx(Chart, { type: 'line', data: barChartData, options: barChartOptions })] }));
}
