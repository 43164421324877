import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from './app/hooks';
import { selectIsAuthenticated } from './features/auth/authSlice';
import { DEFAULT_PUBLIC_ROUTE } from './app/app.consts';
import { App } from './App';
import { Auth } from './features/auth/Auth';
import { Account } from './features/account/Account';
import { Organization } from './features/organization/Organization';
import { Dashboard } from './features/dashboard/Dashboard';
import { ErrorPage } from './features/ErrorPage';
const PrivateRoutes = () => (useAppSelector(selectIsAuthenticated) ? _jsx(Outlet, {}) : _jsx(Navigate, { to: DEFAULT_PUBLIC_ROUTE }));
export const rootRouter = createBrowserRouter([
    {
        path: '/', element: _jsx(App, {}), errorElement: _jsx(ErrorPage, {}),
        children: [
            { path: '/auth/*', element: _jsx(Auth, {}) },
            { path: '/user/*', element: _jsx(Auth, {}) },
            { element: _jsx(PrivateRoutes, {}), children: [
                    { path: '/account/*', element: _jsx(Account, {}) },
                    { path: '/organization/*', element: _jsx(Organization, {}) },
                    { path: '/dashboard/*', element: _jsx(Dashboard, {}) },
                ] }
        ]
    },
]);
