export const links = [
    {
        name: 'Overview',
        icon: 'chart',
        url: 'overview',
    },
    {
        name: 'Performance',
        icon: 'lightning',
        url: 'performance',
        children: [
            { name: 'Server Caching', url: 'server-cache' },
            { name: 'Browser Caching', url: 'client-cache' },
            { name: 'Custom VCL', url: 'vcl' }
        ]
    },
    {
        name: 'Site Backups',
        icon: 'folderArrowDown',
        url: 'backups',
    },
    {
        name: 'Site Security',
        icon: 'shield',
        url: 'security',
    },
    {
        name: 'Managed WordPress',
        icon: 'wordpress',
        url: 'wordpress',
        children: [
            { name: 'Automatic Updates', url: 'updates' },
            { name: 'Security', url: 'security' },
            { name: 'Settings', url: 'settings' },
        ]
    },
    {
        name: 'Traffic & Error Logs',
        icon: 'list',
        url: 'logs',
    },
    {
        name: 'Version Control',
        icon: 'code',
        url: 'code',
        children: [
            { name: 'Site Deployments', url: 'deployments' },
            { name: 'Code Repository', url: 'repository' },
        ]
    },
    {
        name: 'SSH Keys',
        icon: 'key',
        url: 'ssh-keys',
    },
    {
        name: 'Advanced Settings',
        icon: 'gear',
        url: 'advanced',
        children: [
            { name: 'Additional Features', url: 'features' },
            { name: 'Web Server', url: 'webserver' },
            { name: 'SSL / TLS', url: 'ssl' },
            { name: 'Manage Environments', url: 'environments' },
        ]
    }
];
