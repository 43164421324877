import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Modal, Button, Input, Spinner } from "src/client/features/ui";
import { useToaster } from "src/client/features/ui/toaster/useToaster";
import { useCreateBackupMutation } from 'src/client/app/api/siteApi';
export function CreateBackupModal(props) {
    const [label, setLabel] = useState('');
    const toast = useToaster();
    const [createBackup, { isLoading, error }] = useCreateBackupMutation();
    const close = () => {
        setLabel('');
        props.onClose();
    };
    const doCreateBackup = async () => {
        await createBackup({ website: props.website, label }).unwrap();
        if (error) {
            console.error(error);
            toast({
                title: 'Error',
                message: 'An error occured while attempting to backup your website. Please refresh and try again.',
                type: 'error'
            });
        }
        else {
            toast({
                title: 'Backup started!',
                message: 'Your backup has been started! You\'ll be notified once it is ready for download.',
                type: 'success'
            });
        }
        close();
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        doCreateBackup();
    };
    return (_jsx(Modal, { isOpen: props.isOpen, onClose: props.onClose, title: "Create Backup", subtitle: "Create a full backup now of all website files and databases.", children: _jsxs("form", { onSubmit: onSubmit, children: [_jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "keyname", children: _jsx("span", { className: "label-text", children: "LABEL" }) }), _jsx(Input, { id: "label", type: "text", placeholder: "My Backup", value: label, onChange: (e) => setLabel(e.target.value) })] }), error && _jsx("div", { className: "text-red-700 font-bold text-center", children: JSON.stringify(error) }), _jsxs("div", { className: "mt-4 text-right space-x-4", children: [_jsx(Button, { role: "secondary", border: false, onClick: close, children: "Cancel" }), _jsx(Button, { type: "submit", disabled: !label || isLoading, children: isLoading ? _jsx(Spinner, {}) : 'Submit' })] })] }) }));
}
