import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
const MAX_TOASTS = 4;
export const toasterSlice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        addToast: (state, action) => {
            const t = [...state];
            const newToast = action.payload;
            t.push(action.payload);
            const size = t.length;
            if (size >= MAX_TOASTS) {
                const start = Math.max(size - MAX_TOASTS, 0);
                return t.slice(start, start + MAX_TOASTS);
            }
            else {
                return t;
            }
        },
        removeToast: (state, action) => {
            state.splice(action.payload, 0);
            return state;
        },
        removeToastByProperties: (state, action) => {
            const i = state.findIndex(t => (t.title === action.payload.title &&
                t.message === action.payload.message &&
                t.type === action.payload.type));
            if (i >= 0) {
                state.splice(i, 1);
            }
            return state;
        }
    }
});
export const selectAllToasts = (state) => state.toaster;
export const { addToast, removeToast } = toasterSlice.actions;
export default toasterSlice.reducer;
