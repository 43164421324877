import { api } from './api';
export const otpApi = api.injectEndpoints({
    endpoints: (build) => ({
        generateSecret: build.mutation({
            query: () => ({
                url: 'auth/otp/generate-secret',
                method: 'POST',
            }),
            invalidatesTags: ['OTP']
        }),
        enableOTP: build.mutation({
            query: (token) => ({
                url: 'auth/otp/enable',
                method: 'POST',
                body: { token }
            }),
            invalidatesTags: ['OTP']
        }),
        disableOTP: build.mutation({
            query: () => ({
                url: 'auth/otp/disable',
                method: 'POST',
                body: {}
            }),
            invalidatesTags: ['OTP']
        })
    })
});
export const { useGenerateSecretMutation, useEnableOTPMutation, useDisableOTPMutation, } = otpApi;
export default otpApi;
