import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useGetFeatureConfigQuery, useSetFeatureConfigMutation } from 'src/client/app/api/siteApi';
import { Alert, Card, Select } from 'src/client/features/ui';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
const CACHE_OPTION_TTLS = {
    'None': 0,
    '5 minutes': 300,
    '30 minutes': 1800,
    '1 hour': 3600,
    '4 hours': 14400,
    '12 hours': 43200,
    '1 day (24hr)': 86400,
    '1 week': 604800,
    '2 weeks': 1209600,
    '4 weeks': 2419200
};
const PREDEFINED_CACHE_GROUPS = [
    {
        title: 'Default',
        extensions: ['*']
    },
    {
        title: 'Images',
        extensions: ['jpeg', 'jpg', 'png', 'bmp', 'webp', 'svg']
    },
    {
        title: 'CSS and JS files',
        extensions: ['css', 'js']
    }
];
const CACHE_OPTION_TTLS_SELECT = Object.keys(CACHE_OPTION_TTLS).map((k) => {
    return ({ label: k, value: CACHE_OPTION_TTLS[k] });
});
export function ClientCache() {
    const selectedSite = useAppSelector(getSelectedSite);
    const [doSave, { isLoading }] = useSetFeatureConfigMutation();
    const [defaultCacheTTL, setDefaultCacheTTL] = useState(CACHE_OPTION_TTLS.None);
    const [imageCacheTTL, setImageCacheTTL] = useState(CACHE_OPTION_TTLS.None);
    const [cssJsAssetCacheTTL, setCssJsAssetCacheTTL] = useState(CACHE_OPTION_TTLS.None);
    const { data: siteConfig } = useGetFeatureConfigQuery({
        website: selectedSite.Website,
        configField: WebsiteFeatureConfigFields.BROWSER_EXPIRE_HEADERS
    }, { skip: selectedSite.Website === undefined });
    return (_jsxs("div", { className: "space-y-4", children: [siteConfig && _jsx("pre", { children: JSON.stringify(siteConfig) }), _jsxs(Card, { title: "Browser Cache Options", children: [_jsxs(Alert, { type: "info", children: ["Select how long a user's browser should be instructed to cache specific file types.", _jsx("br", {}), "The browser will load these files from its own cache when possible, which speeds up website load times."] }), _jsxs("div", { className: "space-y-6", children: [_jsxs("div", { children: [_jsx("label", { className: "label block", htmlFor: "cache_default_ttl", children: "Default:" }), _jsx(Select, { className: "w-64", id: "cache_default_ttl", options: CACHE_OPTION_TTLS_SELECT, value: defaultCacheTTL, onChange: (e) => setDefaultCacheTTL(+e.target.value) })] }), _jsxs("div", { children: [_jsxs("label", { className: "label block", htmlFor: "cache_media_ttl", children: ["Images (", _jsx("em", { children: ".jpeg, .jpg, .png, .bmp, .svg" }), "):"] }), _jsx(Select, { className: "w-64", id: "cache_media_ttl", options: CACHE_OPTION_TTLS_SELECT, value: imageCacheTTL, onChange: (e) => setImageCacheTTL(+e.target.value) })] }), _jsxs("div", { children: [_jsx("label", { className: "label block", htmlFor: "cache_assets_ttl", children: "CSS and JS files:" }), _jsx(Select, { className: "w-64", id: "cache_assets_ttl", options: CACHE_OPTION_TTLS_SELECT, value: cssJsAssetCacheTTL, onChange: (e) => setCssJsAssetCacheTTL(+e.target.value) })] })] })] })] }));
}
