import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Select, Information } from "src/client/features/ui";
import { useGetClusterCapabilitiesQuery } from "src/client/app/api/platformApi";
import { LastChanged } from '../LastChanged';
const DEFAULT_PHP_VERSIONS = ['7.3', '7.4', '8.0', '8.1', '8.2'];
export function PhpOptions(props) {
    const { data } = useGetClusterCapabilitiesQuery(props.website.Website.clusterId ?? 0);
    const phpVersions = (data?.php?.versions !== undefined ? data.php.versions : DEFAULT_PHP_VERSIONS).map((v) => ({ label: v, value: v }));
    return (_jsxs(Card, { title: "PHP Version", collapsable: true, children: [_jsxs(Information, { children: ["Please select the version of PHP to use for this site.", _jsx("br", {}), "Newer versions provide more features, improved security, and better performance.", _jsx("br", {}), "Older versions provide better compatibility with older code.", _jsx("br", {}), _jsx("br", {}), _jsx("strong", { children: "Don't see the version you need?" }), " Submit a support ticket and we'll help."] }), _jsx("div", { className: "flex flex-row space-x-4 mt-4", children: _jsx(Select, { className: "w-40", options: phpVersions, defaultValue: props.config?.configValue }) }), _jsx(LastChanged, { config: props.config })] }));
}
