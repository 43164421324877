import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useGetTasksByTypeQuery } from 'src/client/app/api/siteApi';
import { Card, Information, Toggle } from 'src/client/features/ui';
import { LastChanged } from '../LastChanged';
import cronstrue from 'cronstrue';
export function AutomaticBackups(props) {
    const { data: tasks, error: taskLoadingError } = useGetTasksByTypeQuery({ website: props.website.Website, taskType: 'CREATE_BACKUP' });
    const getHumanReadableCronSchedule = (task, verbose = true) => {
        if (!task) {
            return '';
        }
        if (task.schedule) {
            let schedule = task.schedule;
            if (schedule.split(' ').length === 6) {
                schedule = schedule.replace(/^\*\s/, '');
            }
            return cronstrue.toString(schedule, { verbose })
                .replace('At', 'at')
                .replace(/(\d{2}):(\d{2}) (\w{2})/, '$1:$2$3')
                .replace(/, only on (\w+)/, ' on $1s')
                .replace(/, every day/, ' every day')
                .replace(/ on (\w+)s and (\w+)/, ' on $1s and $2s')
                .replace(/0(\d:)/, '$1');
        }
        return '';
    };
    const getScheduledTask = (tasks) => {
        return tasks.find(t => t.repeatable && t.schedule);
    };
    const onChange = (value) => {
        const config = {
            configField: props.configName,
            configValue: null,
            isEnabled: value
        };
        props.onChange(config);
    };
    return (_jsxs(Card, { title: "Automatic Backups", collapsable: true, children: [props.website.Website.environment === 'PRODUCTION' &&
                _jsxs(_Fragment, { children: [_jsx(Information, { children: (tasks && getScheduledTask(tasks)) ?
                                _jsxs("div", { children: [_jsx("strong", { children: "This website is backed up automatically!" }), _jsx("br", {}), "Website backups are scheduled to occur at ", getHumanReadableCronSchedule(getScheduledTask(tasks))] }) :
                                _jsx("div", { children: "When enabled, your website files and database will be automatically backed up daily. Automatic backups are retained for 30 days." }) }), _jsx(Toggle, { checked: !!props.config?.isEnabled, onChange: onChange, label: "Enable daily backups?", disabled: !!props.config?.isLocked })] }), props.website.Website.environment !== 'PRODUCTION' &&
                _jsx("div", { className: "p-4 italic text-gray-600 dark:text-gray-400", children: "Automatic backups are not available on non-production websites." }), _jsx(LastChanged, { config: props.config })] }));
}
