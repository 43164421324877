import { fetchBaseQuery, createApi, } from '@reduxjs/toolkit/query/react';
import { getBaseQueryWithTokenRefresh } from './baseQueryWithTokenRefresh';
const apiHost = process.env.REACT_APP_API_HOST ?? 'http://localhost:8080';
export const ApiCacheTags = [
    'Auth', 'Support', 'Site', 'Property', 'Monitor', 'Task', 'Alias', 'Backups', 'FeatureConfigs',
    'Vcl', 'Logs', 'Organization', 'SSHKey', 'Notification', 'OTP', 'Security',
    'WordPress', 'User'
];
const baseQuery = fetchBaseQuery({
    baseUrl: `${apiHost}/api/`,
    mode: 'cors',
    prepareHeaders: (headers, { getState }) => {
        const session = getState().auth.session;
        if (session) {
            const { token } = session.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
        }
        return headers;
    },
});
export const api = createApi({
    reducerPath: 'wnbApi',
    baseQuery: getBaseQueryWithTokenRefresh(baseQuery),
    tagTypes: ApiCacheTags,
    endpoints: () => ({}),
});
