import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useGetWordPressSecurityScansWithFindingsQuery } from 'src/client/app/api/siteApi';
import { useAppSelector } from 'src/client/app/hooks';
import { Card, Spinner, Alert, NoContent } from 'src/client/features/ui';
import { getSelectedSite } from '../siteSlice';
export const getScanFindingAggregateCounts = (findings) => {
    const counts = {
        CRITICAL: 0,
        HIGH: 0,
        MEDIUM: 0,
        LOW: 0,
        INFORMATIONAL: 0,
    };
    findings.forEach(f => {
        console.log(f.severity);
        counts[f.severity] += 1;
    });
    return counts;
};
export function WordPressSecurity() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetWordPressSecurityScansWithFindingsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Spinner, {}), error && _jsx(Alert, { type: "warning", children: "An error occurred. Please refresh and try again." }), (data && data.length === 0) &&
                _jsx(NoContent, { title: "WordPress Security", text: "WordPress Security scan results aren't available yet for this website. Please check back later.", icon: "wordpress" }), data &&
                _jsx("div", { className: "space-y-2", children: data.map((scan) => (_jsx(Card, { title: `WordPress Scan: ${scan.startedAt}`, children: _jsx(Link, { to: `./scan/${scan.id}`, children: JSON.stringify(getScanFindingAggregateCounts(scan.WebsiteScanOperationFinding)) }) }))) })] }));
}
