import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const PASSWORD_STRENGTH_RULES = {
    'At least 8 characters': /.{8}/,
    'At least one uppercase letter': /[A-Z]/,
    'At least one lowercase letter': /[a-z]/,
    'At least one number': /[\d]/,
    'At least one special character': /\W/,
};
export function PasswordStrengthMeter(props) {
    return _jsxs("div", { className: props.className, children: ["Your new password must contain:", Object.keys(PASSWORD_STRENGTH_RULES).map((rule, i) => (_jsxs("div", { className: "flex ml-2 items-center", children: [_jsx("span", { className: "mr-2", children: props.password.match(PASSWORD_STRENGTH_RULES[rule]) ? String.fromCharCode(10003) : String.fromCharCode(8226) }), rule] }, i)))] });
}
