import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useRef } from 'react';
const defaultProps = {
    size: 6,
    onChange: (code) => { console.error('OTPInput onChange is not set!'); }
};
export function OTPInput(props) {
    const mergedProps = { ...defaultProps, ...props };
    const [code, _setCode] = useState(Array(mergedProps.size));
    const fields = [...Array(mergedProps.size).keys()];
    const authCodeFieldRefs = useRef([]);
    const setCode = (digit, position) => {
        const _code = [...code];
        _code[position] = digit;
        _setCode(_code);
        mergedProps.onChange(_code);
    };
    const onKeyUp = (event, idx) => {
        event.currentTarget.value = event.currentTarget.value.replace(/[^\d]/, '');
        if (event.key === 'Backspace') {
            event.currentTarget.value = '';
            if (idx > 0) {
                authCodeFieldRefs.current[idx - 1].focus();
            }
            return true;
        }
        if (event.keyCode >= 48 && event.keyCode <= 57) {
            event.currentTarget.value = event.key;
            setCode(event.currentTarget.value, idx);
            if (idx < ((mergedProps.size - 1))) {
                authCodeFieldRefs.current[idx + 1].focus();
            }
        }
    };
    return (_jsx("div", { id: "otp", className: "flex flex-row justify-center text-center px-2 mt-5", children: fields.map((_, key) => (_jsx("input", { tabIndex: key + 1, ref: (el) => (authCodeFieldRefs.current[key] = el), className: `
                    mx-0.5 my-2 p-2.5 font-mono md:text-xl h-12 w-12 text-center border text-sm
                    first:rounded-l-lg last:rounded-r-lg
                    focus:ring-blue-500 focus:border-blue-500
                    bg-white dark:bg-gray-700 border-gray-600 placeholder-gray-400
                    text-gray-600 dark:text-white focus:ring-blue-800
                `, type: "text", maxLength: 1, onKeyUp: (e) => onKeyUp(e, key) }, key))) }));
}
