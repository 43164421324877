import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetTasksQuery } from "src/client/app/api/siteApi";
import { useAppSelector } from 'src/client/app/hooks';
import { Card, Spinner } from '../../ui';
import { getSelectedSite } from './siteSlice';
export function Tasks() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetTasksQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [isLoading && _jsx(Spinner, {}), data && data.map((t) => _jsx(Card, { children: _jsx("pre", { className: "overflow-x", children: JSON.stringify(t, null, 2) }) }, t.id))] }));
}
