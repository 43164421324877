import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { Code, Input } from 'src/client/features/ui';
import { SettingsGroup } from '../account/SettingsGroup';
import { RoleTitles, RoleCapabilityDescriptions } from './RoleInfo';
export function OrganizationOverview(props) {
    const { currentUser, organization, memberships } = props;
    const membership = memberships.find(m => m.userId === currentUser.id);
    return (_jsxs("div", { className: "mx-auto divide-y-2 divide-gray-300 dark:divide-gray-700", children: [_jsx(SettingsGroup, { title: "Organization Details", description: "Please contact Support in order to change your organization's name", children: _jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("div", { className: "w-1/2", children: [_jsx("label", { className: "label", htmlFor: "orgName", children: "Organization Name" }), _jsx(Input, { type: "text", name: "orgName", id: "orgName", disabled: true, value: organization.name })] }), _jsxs("div", { className: "w-1/2", children: [_jsx("label", { className: "label", htmlFor: "orgCreatedOn", children: "Created On" }), _jsx(Input, { type: "text", name: "orgName", id: "orgName", disabled: true, value: format(new Date(organization.createdAt), 'PPPP') })] }), _jsxs("div", { className: "w-1/2", children: [_jsx("label", { className: "label", htmlFor: "orgId", children: "Organization ID" }), _jsx(Code, { children: organization.slug })] })] }) }), _jsxs(SettingsGroup, { title: "Your Access", children: [membership &&
                        _jsxs("h3", { className: "text-lg mb-4", children: ["You are a member of the ", _jsx("strong", { children: organization.name }), " organization."] }), !membership &&
                        _jsxs("h3", { className: "text-lg mb-4", children: ["You are not a member of the ", _jsx("strong", { children: organization.name }), " organization.", currentUser.isSuperAdmin && _jsx("div", { children: "You are a W&B administrator, so you can access this organization." })] }), _jsx("div", { className: "text-gray-600 dark:text-gray-400", children: membership && _jsxs(_Fragment, { children: ["Your user role is ", _jsx("strong", { children: RoleTitles[membership.role] }), ". This means you can:", _jsx("br", {}), _jsx("ul", { className: "list-disc my-1 mx-8", children: _jsx("li", { children: RoleCapabilityDescriptions[membership.role] }) })] }) })] })] }));
}
