import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "src/client/app/app.consts";
import { Dropdown } from "../../Dropdown";
import { Spinner, Icon } from "../../../ui";
import { useGetNotificationsQuery, useSetNotificationAsReadMutation } from "src/client/app/api/notificationApi";
export function NotificationsMenu() {
    const { data, isLoading, refetch } = useGetNotificationsQuery();
    const navigate = useNavigate();
    const [markNotificationAsRead] = useSetNotificationAsReadMutation();
    const showUnreadIndicator = !!data?.some(n => n.readAt === null);
    const onClick = (notification) => {
        if (notification.link) {
            return navigate(notification.link);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => refetch(), 8000);
        return () => clearInterval(interval);
    }, [refetch]);
    const onClose = () => {
        data?.forEach(notification => markNotificationAsRead(notification));
    };
    return (_jsxs(Dropdown, { onClose: onClose, icon: "bell", title: "View notifications", showIndicator: showUnreadIndicator, children: [_jsx("div", { className: "block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 w-96", children: "Notifications" }), isLoading && _jsx(Spinner, {}), !isLoading && (!data || !data.length) && (_jsx("div", { className: "text-center text-sm p-4 italic text-gray-500 dark:text-gray-400", children: "There are no notifications." })), _jsx("div", { children: data && data.map(notification => {
                    return (_jsxs("div", { onClick: () => onClick(notification), className: `
                            flex items-center py-3 px-4
                            border-b dark:border-gray-600 last:border-b-none
                            hover:bg-gray-100 dark:hover:bg-gray-600 
                            ${notification.link ? 'cursor-pointer' : ''}
                        `, children: [_jsx("div", { className: "rounded-full p-2 bg-gray-100 dark:bg-gray-800 text-gray-400", children: _jsx(Icon, { icon: "envelopeOpen", className: "w-6 h-6" }) }), _jsxs("div", { className: "pl-3 w-full", children: [_jsxs("div", { className: "text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400", children: [notification.priority === 'HIGH' &&
                                                _jsx("div", { className: "text-xs font-medium text-red-600 dark:text-red-500 mb-1.5", children: "High Priority" }), notification.message] }), _jsx("div", { className: "text-xs font-medium text-brand-primary", title: format(new Date(notification.createdAt), DEFAULT_DATE_FORMAT), children: formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true }) })] })] }, notification.id));
                }) })] }));
}
