import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@headlessui/react';
import { Card } from "./Card";
import { useAppSelector } from 'src/client/app/hooks';
import { selectGetTheme } from 'src/client/features/ui/uiSlice';
export function Modal(props) {
    const theme = useAppSelector(selectGetTheme);
    const doClose = () => {
        props.onClose();
    };
    return (_jsxs(Dialog, { open: props.isOpen, onClose: doClose, children: [_jsx("div", { className: "fixed inset-0 bg-black/50", "aria-hidden": "true" }), _jsx("div", { className: `fixed inset-0 flex items-center justify-center p-4 overflow-x-scroll ${theme}`, children: (props.useInnerCard !== undefined && props.useInnerCard === false) ?
                    _jsx("div", { className: "w-full max-w-lg h-fit mx-auto dark:text-white", children: props.children }) :
                    _jsx(Card, { className: "w-full max-w-lg h-fit mx-auto dark:text-white", title: props.title, subtitle: props.subtitle, children: props.children }) })] }));
}
