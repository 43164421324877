import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from "react-router-dom";
import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { SetPassword } from './SetPassword';
import { Logout } from './Logout';
import { AcceptInvite } from "./AcceptInvite";
export function Auth() {
    const currentYear = (new Date()).getFullYear();
    return (_jsx("div", { className: "flex flex-row min-h-screen justify-center items-center bg-gray-900 text-white dark", children: _jsxs("div", { className: "p-8 w-full max-w-lg", children: [_jsxs("div", { className: "flex flex-row justify-center items-center uppercase space-x-8 pb-4", children: [_jsx("img", { src: `${process.env.REACT_APP_PUBLIC_URL}/assets/images/logo.png`, className: "w-1/3 object-contain", alt: "Wire & Byte" }), _jsx("div", { className: "border-l border-gray-600 text-[1.5em] pl-8 py-4 font-condensed", children: "Client Portal" })] }), _jsx("div", { className: "w-full rounded-lg shadow border md:mt-0 xl:p-0 bg-gray-800 border-gray-700", children: _jsx("div", { className: "p-6 space-y-4 md:space-y-6 sm:p-8", children: _jsxs(Routes, { children: [_jsx(Route, { path: "login", element: _jsx(Login, {}) }), _jsx(Route, { path: "forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "set-password/:token", element: _jsx(SetPassword, {}) }), _jsx(Route, { path: "logout", element: _jsx(Logout, {}) }), _jsx(Route, { path: "invite/:invitationId", element: _jsx(AcceptInvite, {}) }), _jsx(Route, { path: "*", element: _jsx("span", { children: "Invalid route!" }) })] }) }) }), _jsx("div", { children: _jsxs("div", { className: "text-xs pt-4 text-center", children: ["\u00A9 Copyright 2021-", currentYear, " Wire & Byte, LLC."] }) })] }) }));
}
