import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Spinner } from 'src/client/features/ui';
import { StatsAtAGlance } from './StatsAtAGlance';
import { HealthStatus } from './HealthStatus';
import { TrafficGraph } from './TrafficGraph';
import { DailyStatusCodeStats } from './DailyStatusCodeStats';
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useGetTrafficStatsQuery } from 'src/client/app/api/siteApi';
const DAYS_OF_STATS = 7;
export function Overview() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetTrafficStatsQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-row items-stretch lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6 mb-6", children: [_jsxs(Card, { title: `Stats at a glance (past ${DAYS_OF_STATS} days)`, subtitle: data?.length ? 'Data is updated once every 24 hours' : '', className: "lg:w-2/3", children: [isLoading && _jsx(Spinner, {}), data && _jsx(StatsAtAGlance, { stats: data, days: DAYS_OF_STATS })] }), _jsx(Card, { title: "Health Status", className: "lg:w-1/3", children: _jsx(HealthStatus, { website: selectedSite.Website }) })] }), _jsxs(Card, { title: `Response Codes Per Day (past ${DAYS_OF_STATS} days)`, subtitle: "Data is updated once every 24 hours", className: "w-full mb-6", children: [isLoading && _jsx(Spinner, {}), data && _jsx(DailyStatusCodeStats, { stats: data, days: DAYS_OF_STATS })] }), _jsxs(Card, { title: `Website Traffic Overview (past ${DAYS_OF_STATS} days)`, subtitle: "Data is updated once every 24 hours", className: "w-full", children: [isLoading && _jsx(Spinner, {}), data && _jsx(TrafficGraph, { stats: data, days: DAYS_OF_STATS })] }), _jsxs("div", { className: "text-xs text-right mt-4 text-gray-400 dark:text-gray-500", children: ["W&B Portal v", process.env.REACT_APP_VERSION] })] }));
}
