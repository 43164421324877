import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useCallback } from 'react';
import { Transition } from '@headlessui/react';
import { Icon } from '../icon/Icon';
const DEFAULT_HIDE_AFTER_MS = 15000;
export function ToastNotification(props) {
    const [visible, setVisible] = useState(true);
    const hideToast = useCallback(() => {
        props.onHide && props.onHide(props);
    }, [props]);
    useEffect(() => {
        const hideAfterMs = (props.hideAfterMs && props.hideAfterMs > 0) ? props.hideAfterMs : DEFAULT_HIDE_AFTER_MS;
        const timeout = setTimeout(() => hideToast(), hideAfterMs);
        return () => clearTimeout(timeout);
    }, [hideToast, props.hideAfterMs]);
    return (_jsx(Transition, { show: visible, as: Fragment, enter: "transform ease-out duration-300 transition", enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2", enterTo: "translate-y-0 opacity-100 sm:translate-x-0", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", afterLeave: hideToast, children: _jsx("div", { className: "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5", children: _jsx("div", { className: "p-4", children: _jsxs("div", { className: "flex items-start", children: [_jsxs("div", { className: "flex-shrink-0", children: [props.type === 'success' && _jsx(Icon, { icon: "checkCircle", className: "h-6 w-6 text-green-400", "aria-hidden": "true" }), props.type === 'error' && _jsx(Icon, { icon: "exclamationCircle", className: "h-6 w-6 text-red-600", "aria-hidden": "true" }), props.type === 'info' && _jsx(Icon, { icon: "checkCircle", className: "h-6 w-6 text-gray-600", "aria-hidden": "true" })] }), _jsxs("div", { className: "ml-3 w-0 flex-1 pt-0.5", children: [_jsx("p", { className: "text-sm font-bold text-gray-900 dark:text-white", children: props.title }), _jsx("p", { className: "mt-1 text-sm text-gray-500 dark:text-gray-300", children: props.message })] }), _jsx("div", { className: "ml-4 flex flex-shrink-0", children: _jsxs("button", { type: "button", className: "inline-flex rounded-md bg-white dark:bg-slate-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-100", onClick: () => setVisible(false), children: [_jsx("span", { className: "sr-only", children: "Close" }), _jsx(Icon, { icon: "x", className: "h-5 w-5", "aria-hidden": "true" })] }) })] }) }) }) }));
}
