import { api } from './api';
export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query({
            query: (id) => `user/${id}`,
            providesTags: ['User']
        }),
        getCurrentUser: build.query({
            query: () => `user/me`,
            providesTags: ['User']
        }),
        updateUser: build.mutation({
            query: (params) => ({
                url: `user/${params.userId}`,
                method: 'POST',
                body: params.data
            }),
            invalidatesTags: ['Auth', 'User']
        }),
        createUser: build.mutation({
            query: (params) => ({
                url: `user`,
                method: 'PUT',
                body: params
            }),
            invalidatesTags: ['Auth', 'User']
        }),
        getMyPendingInvitations: build.query({
            query: () => 'user/invitations/pending',
            providesTags: ['User']
        })
    })
});
export const { useGetUserQuery, useGetCurrentUserQuery, useUpdateUserMutation, useCreateUserMutation, useGetMyPendingInvitationsQuery, } = userApi;
export default userApi;
