import { UserRole } from "src/shared/types.g";
export const canUserEditUser = (actor, actorMemberships, subject, subjectMemberships, organization) => {
    if (subject.isSuperAdmin && actor.id !== subject.id) {
        return false;
    }
    if (actor.isSuperAdmin) {
        return true;
    }
    const PERMISSIONS = {
        ORGOWNER: [UserRole.ORGOWNER, UserRole.ORGADMIN, UserRole.USER],
        ORGADMIN: [UserRole.ORGADMIN, UserRole.USER],
        USER: []
    };
    const actorOrgMembership = actorMemberships.find(m => m.organizationId === organization.id && m.userId === actor.id);
    const subjectMembership = subjectMemberships.find(m => m.organizationId === organization.id && m.userId === subject.id);
    if (actorOrgMembership && subjectMembership) {
        if (PERMISSIONS[actorOrgMembership.role] &&
            PERMISSIONS[actorOrgMembership.role]?.includes(subjectMembership.role)) {
            return true;
        }
    }
    return false;
};
