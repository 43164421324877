import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Alert, Spinner, OTPInput, Icon } from '../ui';
import { useLoginMutation, useGoogleSsoLoginMutation } from '../../app/api/authApi';
import { useAppSelector } from '../../app/hooks';
import { selectIsAuthenticated } from './authSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { DEFAULT_PRIVATE_ROUTE } from 'src/client/app/app.consts';
export function Login(props) {
    const [doLogin, { isLoading: isLoginLoading }] = useLoginMutation();
    const [doGoogleSsoLogin, { isLoading: isGoogleSsoLoading }] = useGoogleSsoLoginMutation();
    const [email, setEmail] = useState(props.email ?? '');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const [otp, setOtp] = useState();
    const [form, _setForm] = useState('email');
    const onGoogleSsoLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (authCodeResponse) => {
            setError(undefined);
            try {
                const resp = await doGoogleSsoLogin(authCodeResponse).unwrap();
            }
            catch (e) {
                setError(e.data?.message ?? 'Could not log in via Google. Please try a different method.');
            }
        },
        onError: () => {
            setError('Could not log in via Google. Please try a different method.');
        }
    });
    const setForm = (f, error = null) => {
        setError(error);
        _setForm(f);
    };
    const validateRedirectUrl = (url) => {
        return /^\/[\w\/\-\?\=\&\.]+/.test(url);
    };
    if (useAppSelector(selectIsAuthenticated)) {
        const route = (props.redirectTo && validateRedirectUrl(props.redirectTo)) ? props.redirectTo : DEFAULT_PRIVATE_ROUTE;
        return _jsx(Navigate, { replace: true, to: route });
    }
    const isLoading = isLoginLoading || isGoogleSsoLoading;
    const submit = async (e) => {
        e.preventDefault();
        setError(undefined);
        if (form === 'email') {
            return setForm('auth');
        }
        try {
            await doLogin({
                otp: otp?.join('') ?? undefined,
                email,
                password,
            }).unwrap();
        }
        catch (e) {
            if (e.data?.headers && e.data.headers['x-wireandbyte-otp']) {
                return setForm('2fa', (otp && otp.length) ? (e.data?.message ?? 'The provided code is invalid') : null);
            }
            setError(e.data?.message ?? 'Invalid username or password!');
        }
    };
    return (_jsxs("div", { className: "space-y-4", children: [props.hideHeader !== true &&
                _jsx("h1", { className: "text-xl font-bold leading-tight tracking-tight md:text-2xl text-white", children: "Sign in to your account" }), _jsxs("form", { className: "space-y-4 md:space-y-6", onSubmit: submit, children: [error && _jsx(Alert, { type: "error", title: "Error!", children: error }), ['email', 'auth'].includes(form) &&
                        _jsxs("div", { children: [_jsx("label", { htmlFor: "email", className: "block mb-2 text-sm font-bold text-white", children: "Your email" }), _jsx("input", { type: "email", name: "email", id: "email", className: "border text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-800", placeholder: "name@company.com", required: true, defaultValue: email, onChange: (e) => setEmail(e.target.value) })] }), form === 'auth' &&
                        _jsxs("div", { children: [_jsx("label", { htmlFor: "password", className: "block mb-2 text-sm font-bold text-white", children: "Password" }), _jsx("input", { autoFocus: true, type: "password", name: "password", id: "password", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", className: "border text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-800", required: true, defaultValue: password, onChange: (e) => setPassword(e.target.value) })] }), form === '2fa' &&
                        _jsxs("div", { children: [_jsx("p", { children: "Please enter the two-factor authentication code generated by your 2FA app." }), _jsx(OTPInput, { onChange: (n) => setOtp(n), size: 6 })] }), _jsx("button", { type: "submit", className: "w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-800 font-medium rounded-lg text-sm px-5 py-2.5", disabled: isLoading, children: _jsxs("div", { className: "flex flex-row justify-center", children: [isLoading && _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Signing in..."] }), !isLoading && _jsx(_Fragment, { children: "Sign in" })] }) })] }), (['email', 'auth'].includes(form)) &&
                _jsx("div", { className: "text-right mt-2", children: _jsx(Link, { to: "/auth/forgot-password", className: "text-sm font-medium text-primary-500 hover:underline", children: "Forgot Password?" }) }), form === '2fa' &&
                _jsx("div", { className: "text-left mt-2", children: _jsx("a", { onClick: () => setForm('email'), className: "text-sm font-medium text-primary-500 hover:underline", children: "\u00AB Back to login" }) }), (['email', 'auth'].includes(form)) &&
                _jsxs("div", { className: "bg-gray-900/25 p-2 py-3 rounded-lg", children: [_jsx("div", { className: "text-white text-sm uppercase text-center pb-2 text-xs uppercase", children: "Sign In With Cloud Provider:" }), _jsxs("div", { className: "flex flex-row items-between space-x-4", children: [_jsxs("button", { className: "text-white bg-primary-600/50 hover:bg-primary-700 flex flex-row space-x-2 items-center rounded-lg p-2", onClick: () => onGoogleSsoLogin(), children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24", width: "24", viewBox: "0 0 24 24", children: [_jsx("path", { d: "M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z", fill: "#4285F4" }), _jsx("path", { d: "M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z", fill: "#34A853" }), _jsx("path", { d: "M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z", fill: "#FBBC05" }), _jsx("path", { d: "M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z", fill: "#EA4335" }), _jsx("path", { d: "M1 1h22v22H1z", fill: "none" })] }), _jsx("div", { className: "text-sm", children: "Sign in with Google" })] }), _jsxs("button", { disabled: true, className: "text-gray-400 bg-gray-600/50 flex flex-row space-x-2 items-center rounded-lg p-2", children: [_jsx(Icon, { icon: 'github', className: "w-6 h-6 text-gray-400" }), _jsx("div", { className: "text-sm", children: "Sign in with Github" })] })] })] })] }));
}
