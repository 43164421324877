import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from 'src/shared/types.g';
import { Button, Confirm, Spinner } from '../ui';
import { canUserEditUser } from "src/client/app/helpers/can-edit-user";
import { RoleTitles } from "./RoleInfo";
import { InviteUserModal } from "./InviteUserModal";
import { useGetPendingInvitationsQuery, useCancelInvitationMutation } from 'src/client/app/api/organizationApi';
export function OrganizationMembers(props) {
    const { currentUser, users, organization, memberships } = props;
    const { data: pendingInvitations, error: loadInvitationsError, isLoading: isLoadingInvitations } = useGetPendingInvitationsQuery();
    const [cancelInvitation, { isLoading: isCancelling }] = useCancelInvitationMutation();
    const navigate = useNavigate();
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [invitationToCancel, setInvitationToCancel] = useState();
    const canInviteUsers = () => {
        if (currentUser.isSuperAdmin) {
            return true;
        }
        const perms = [
            UserRole.ORGADMIN,
            UserRole.ORGOWNER
        ];
        const membership = memberships.find(m => m.userId === currentUser.id);
        return membership && perms.some(r => r === membership.role);
    };
    const canEditUser = useCallback((subject) => (canUserEditUser(currentUser, memberships, subject, memberships, organization)), [currentUser, memberships, organization]);
    const startCancelInvitation = async () => {
        if (invitationToCancel) {
            await cancelInvitation(invitationToCancel).unwrap();
        }
        setInvitationToCancel(undefined);
    };
    return (_jsxs("div", { children: [canInviteUsers() && _jsxs(_Fragment, { children: [_jsx(Confirm, { isOpen: !!invitationToCancel, title: "Are you sure?", message: "Are you sure you want to cancel this invitation? If you change your mind, you will need to re-invite this person.", confirmButtonText: isCancelling ? 'Cancelling...' : 'Confirm Cancellation', cancelButtonText: "Go Back", onConfirm: startCancelInvitation, onCancel: () => setInvitationToCancel(undefined) }), _jsx("div", { className: "text-right py-4", children: _jsx(Button, { onClick: () => setIsInviteModalOpen(true), children: "+ Invite User" }) }), _jsx(InviteUserModal, { isOpen: isInviteModalOpen, onClose: () => setIsInviteModalOpen(false), organizaion: organization })] }), _jsxs("table", { className: "w-full text-sm text-left text-gray-500 dark:text-gray-400", children: [_jsx("thead", { className: "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-4", children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Email" }), _jsx("th", { children: "2FA Enabled?" }), _jsx("th", { children: "Role" }), _jsx("th", { children: "Tech Contact?" }), _jsx("th", { children: "Billing Contact?" }), _jsx("th", { children: "Actions" })] }) }), _jsxs("tbody", { children: [users.map((user, idx) => {
                                const userMembership = memberships.find(m => m.userId === user.id);
                                return (_jsxs("tr", { className: "bg-white border-b dark:bg-gray-800 dark:border-gray-700", children: [_jsxs("td", { children: [user.firstName, " ", user.lastName] }), _jsx("td", { children: user.email }), _jsx("td", { children: user.otpEnabled ? 'Yes' : 'No' }), _jsx("td", { children: userMembership?.role ? (RoleTitles[userMembership.role] ?? userMembership.role) : _jsx("em", { children: "None" }) }), _jsx("td", { children: userMembership?.isOrgTechContact ? 'Yes' : 'No' }), _jsx("td", { children: userMembership?.isOrgBillingContact ? 'Yes' : 'No' }), _jsxs("td", { children: [canEditUser(user) && _jsx(Button, { role: "secondary", onClick: () => navigate(`/organization/members/edit/${user.id}`), children: "Edit" }), (!canEditUser(user) && currentUser.id === user.id) && _jsx(Button, { role: "secondary", onClick: () => navigate(`/organization/members/edit/${user.id}`), children: "View" })] })] }, idx));
                            }), isLoadingInvitations && _jsx("tr", { children: _jsx("td", { children: _jsx(Spinner, {}) }) }), (!isLoadingInvitations && pendingInvitations) && pendingInvitations.map((invitation, idx) => (_jsxs("tr", { className: "bg-white border-b dark:bg-gray-800 dark:border-gray-700", children: [_jsx("td", { children: _jsx("em", { children: "Invitation Pending..." }) }), _jsx("td", { children: invitation.newUserEmail }), _jsx("td", {}), _jsx("td", { children: RoleTitles[UserRole.USER] }), _jsx("td", { children: "No" }), _jsx("td", { children: "No" }), _jsx("td", { children: canInviteUsers() &&
                                            _jsx(Button, { role: "secondary", onClick: () => setInvitationToCancel(invitation), children: "Cancel Invitation" }) })] }, idx)))] })] })] }));
}
