import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Routes, Route, Navigate } from 'react-router-dom';
import { links } from './links';
import { Overview } from './Overview/Overview';
import { ServerCache } from './Performance/ServerCache';
import { ClientCache } from './Performance/ClientCache';
import { Cdn } from './Performance/Cdn';
import { Vcl } from './Performance/Vcl';
import { Backups } from './Backups/Backups';
import { WordPressSecurity } from './WordPress/WordpressSecurity';
import { WordPressSecurityScanDetails } from './WordPress/WordpressSecurityScanDetails';
import { WordPressUpdates } from './WordPress/WordpressUpdates';
import { WordPressSettings } from './WordPress/WordPressSettings/WordPressSettings';
import { Logs } from './Logs/Logs';
import { CodeDeployments } from './Deployments/CodeDeployments';
import { CodeRepository } from './Deployments/CodeRepository';
import { SshKeys } from './SshKeys/SshKeys';
import { WebServer } from './Advanced/WebServer';
import { Features } from './Advanced/Features';
import { Environments } from './Advanced/Environments';
import { Ssl } from './Advanced/Ssl/Ssl';
import { Tasks } from './Tasks';
import { SiteSelectorBar } from './SiteSelector/SiteSelectorBar';
import { Sidebar } from '../Sidebar';
import { getSelectedSite } from './siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { SiteSelector } from './SiteSelector/SiteSelector';
import { useGetFeatureConfigQuery } from 'src/client/app/api/siteApi';
import { WebsiteFeatureConfigFields } from 'src/shared/types.g';
import { Button, NoContent } from '../../ui';
import { SiteSecurity } from './Security/SiteSecurity';
export function Sites() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data: siteConfig } = useGetFeatureConfigQuery({
        website: selectedSite.Website,
        configField: WebsiteFeatureConfigFields.WORDPRESS
    }, { skip: selectedSite.Website === undefined });
    const filteredLinks = links.filter(link => {
        if (siteConfig?.config.WORDPRESS?.isEnabled !== true &&
            link.name === 'Managed WordPress') {
            return false;
        }
        if (!selectedSite.repositoryId && link.name === 'Version Control') {
            return false;
        }
        return true;
    });
    return (_jsxs("div", { className: "flex flex-row flex-grow", children: [(selectedSite && selectedSite.Website) &&
                _jsx(Sidebar, { baseUrl: "/dashboard/sites/", links: filteredLinks, children: _jsx(Button, { role: "secondary", title: "You are at the max amount of sites allowed by your service plan. Please contact Support for help.", disabled: true, children: "+ Create New Website" }) }), _jsxs("div", { className: "grow p-4 pt-0", children: [(!selectedSite || !selectedSite.Website) &&
                        _jsx(NoContent, { icon: "websites", title: "No Website Selected", text: "Please select a website to manage.", children: _jsx(SiteSelector, { allowCancel: false, showTitle: false }) }), (selectedSite && selectedSite.Website) &&
                        _jsxs(_Fragment, { children: [_jsx(SiteSelectorBar, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "overview" }) }), _jsx(Route, { path: "overview", element: _jsx(Overview, {}) }), _jsx(Route, { path: "performance/server-cache", element: _jsx(ServerCache, {}) }), _jsx(Route, { path: "performance/client-cache", element: _jsx(ClientCache, {}) }), _jsx(Route, { path: "performance/cdn", element: _jsx(Cdn, {}) }), _jsx(Route, { path: "performance/vcl", element: _jsx(Vcl, {}) }), _jsx(Route, { path: "backups", element: _jsx(Backups, {}) }), _jsx(Route, { path: "security", element: _jsx(SiteSecurity, {}) }), _jsx(Route, { path: "wordpress/security", element: _jsx(WordPressSecurity, {}) }), _jsx(Route, { path: "wordpress/security/scan/:scanId", element: _jsx(WordPressSecurityScanDetails, {}) }), _jsx(Route, { path: "wordpress/updates", element: _jsx(WordPressUpdates, {}) }), _jsx(Route, { path: "wordpress/settings", element: _jsx(WordPressSettings, {}) }), _jsx(Route, { path: "logs", element: _jsx(Logs, {}) }), _jsx(Route, { path: "code/deployments", element: _jsx(CodeDeployments, {}) }), _jsx(Route, { path: "code/repository", element: _jsx(CodeRepository, {}) }), _jsx(Route, { path: "ssh-keys", element: _jsx(SshKeys, {}) }), _jsx(Route, { path: "advanced/webserver", element: _jsx(WebServer, {}) }), _jsx(Route, { path: "advanced/features", element: _jsx(Features, {}) }), _jsx(Route, { path: "advanced/environments", element: _jsx(Environments, {}) }), _jsx(Route, { path: "advanced/ssl", element: _jsx(Ssl, {}) }), _jsx(Route, { path: "tasks", element: _jsx(Tasks, {}) })] })] })] })] }));
}
