import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Alert, Button, Spinner } from "src/client/features/ui";
import { VclSub } from 'src/shared/types.g';
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useGetCustomVclQuery } from 'src/client/app/api/siteApi';
import { useSetCustomVclMutation } from 'src/client/app/api/siteApi';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/ext-language_tools";
const vcl = {
    VCL_RECV: `
sub vcl_recv {
    # Called at the beginning of a request, after the complete request has been received and parsed.
    # Its purpose is to decide whether or not to serve the request, possibly modify it and decide on how to process it further.

    set req.backend_hint = web_pool.backend();
}
    `,
    VCL_BACKEND_RESPONSE: `
sub vcl_backend_response {
    # Called after the response headers have been successfully retrieved from the backend.
}
    `,
    VCL_DELIVER: `
sub vcl_deliver {
    # Happens when we have all the pieces we need, and are about to send the response to the client.
}
    `,
    VCL_HIT: `
sub vcl_hit {
    # Called when a cache lookup is successful.

    return (deliver);
}
    `,
};
export function Vcl() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetCustomVclQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    data?.forEach((v) => {
        vcl[v.sub] = v.code;
    });
    const [doSetCustomVcl, { isLoading: isSaving }] = useSetCustomVclMutation();
    const [currentTab, setCurrentTab] = useState('VCL_RECV');
    const [currentVcl, setCurrentVcl] = useState(vcl);
    if (!data) {
        return _jsx(_Fragment, { children: "No VCL found" });
    }
    ;
    const onEditorChange = (val) => {
        const c = { ...currentVcl };
        c[currentTab] = val;
        setCurrentVcl(c);
    };
    const saveVcl = async () => {
        const args = {
            website: selectedSite.Website,
            vcl: currentVcl,
        };
        const resp = await doSetCustomVcl(args).unwrap();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { type: "error", title: "Modifying the settings here can immediately break your website!", children: _jsx("div", { children: "Once you have created and saved your first custom VCL, you will need to contact Support to enable deployments." }) }), isLoading && _jsx(Spinner, {}), !isLoading && (_jsxs(_Fragment, { children: [_jsx("ul", { className: "flex flex-wrap font-bold text-sm text-center text-gray-500 dark:text-gray-400", children: Object.keys(VclSub).map(s => {
                            let classes = 'inline-block cursor-pointer rounded-t-lg px-6 py-4 ';
                            classes += currentTab === s ?
                                'text-white bg-blue-500 dark:bg-blue-500 hover:text-white dark:hover:text-white' :
                                'bg-gray-50 dark:bg-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300';
                            return (_jsx("li", { className: "mr-2", children: _jsx("span", { onClick: () => setCurrentTab(s), className: classes, children: s }) }, s));
                        }) }), _jsxs("div", { className: "border border border-t-8 border-blue-500 mb-4", children: [_jsxs("div", { className: "p-4 text-white bg-black/50 text-center text-sm border-t-8 border-blue-500", children: ["The VCL code you input here will be combined with our own specialized VCL.", _jsx("br", {}), "For documentation on each Varnish subroutine,\u00A0", _jsx("a", { rel: "noreferrer noopener", className: "underline font-bold text-blue-500 hover:text-blue-800 dark:text-blue-200 dark:hover:text-white", href: "https://varnish-cache.org/docs/trunk/users-guide/vcl-built-in-subs.html", target: "_BLANK", children: "click here" }), "."] }), _jsx(AceEditor, { mode: "c_cpp", theme: "terminal", name: "UNIQUE_ID_OF_DIV", value: currentVcl[currentTab], readOnly: isSaving, onChange: onEditorChange, width: "100%", editorProps: { showLineNumbers: true } })] }), _jsxs("div", { className: "text-right space-x-4", children: [_jsxs(Button, { role: "primary", buttonSize: "large", onClick: saveVcl, children: [isSaving && _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Saving..."] }), !isSaving && _jsx(_Fragment, { children: "Save VCL" })] }), _jsx(Button, { role: "secondary", buttonSize: "large", disabled: true, title: "Please contact Support to enable VCL deployments.", children: "Deploy VCL" })] })] }))] }));
}
