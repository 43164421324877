import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Modal, Button, Input, Textarea, Spinner } from "src/client/features/ui";
import { useAddSSHPublicKeyMutation } from 'src/client/app/api/siteApi';
import { useToaster } from "src/client/features/ui/toaster/useToaster";
export function AddKeyModal(props) {
    const [key, _setKey] = useState('');
    const [keyname, setKeyname] = useState('');
    const [addKey, { isLoading, error }] = useAddSSHPublicKeyMutation();
    const toast = useToaster();
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!key || !keyname) {
            return;
        }
        addKey({
            website: props.website,
            key: {
                pubKey: key,
                keyname
            }
        })
            .unwrap()
            .then(() => {
            toast({
                'type': 'success',
                'title': 'Key added!',
                'message': 'Your SSH key has been successfully added! Please wait 2-3 minutes for it to be fully installed.'
            });
            _setKey('');
            setKeyname('');
            props.onClose();
        });
    };
    const setKey = (content) => {
        const parts = content.split(' ');
        if (parts.length >= 2 && parts[0] === 'ssh-rsa') {
            _setKey(`${parts[0]} ${parts[1]}`);
            if (parts[2] && !keyname) {
                setKeyname(parts[2]);
            }
        }
        else {
            _setKey('');
            setKeyname('');
        }
    };
    const isValid = !!(!isLoading && key && keyname && key.startsWith('ssh-rsa '));
    return (_jsx(Modal, { isOpen: props.isOpen, onClose: props.onClose, title: "Add SSH Key", subtitle: "Please enter an SSH public key and a name for the key.", children: _jsxs("form", { onSubmit: onSubmit, children: [_jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "keyname", children: _jsx("span", { className: "label-text", children: "KEY NAME" }) }), _jsx(Input, { id: "keyname", type: "text", placeholder: "Key name", value: keyname, onChange: (e) => setKeyname(e.target.value) })] }), _jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "pubkey", children: _jsx("span", { className: "label-text", children: "Public Key" }) }), _jsx(Textarea, { id: "pubkey", className: "h-24", placeholder: "ssh-rsa ....", onChange: (e) => setKey(e.target.value) })] }), error && _jsx("div", { className: "text-red-700 font-bold text-center", children: JSON.stringify(error) }), _jsxs("div", { className: "mt-4 text-right space-x-4", children: [_jsx(Button, { role: "secondary", border: false, onClick: props.onClose, children: "Cancel" }), _jsx(Button, { type: "submit", disabled: !isValid, children: isLoading ? _jsx(Spinner, {}) : 'Submit' })] })] }) }));
}
