import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Alert = function (props) {
    const styles = {
        info: 'text-blue-500 bg-blue-100 dark:bg-blue-900 dark:text-blue-100',
        error: 'text-red-500 bg-red-100 dark:bg-red-900 dark:text-red-100',
        success: 'text-green-700 bg-green-100 dark:bg-green-900 dark:text-green-200',
        warning: 'text-yellow-700 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800',
        message: 'text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-300'
    };
    const sizeStyles = props.size && props.size === 'large' ? 'text-lg' : 'text-sm';
    const classes = `
        p-4 mb-4 text-center shadow-md rounded-lg ${sizeStyles} ${styles[props.type] ?? styles.message} ${props.className}
    `;
    return (_jsxs("div", { className: classes, role: "alert", children: [props.title && _jsx("span", { className: "font-bold mr-2", children: props.title }), props.children] }));
};
