import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal, Input, Textarea, Button, Spinner } from '../ui';
import { useInviteNewUserMutation } from 'src/client/app/api/organizationApi';
import { UserRole } from 'src/shared/types.g';
import { useToaster } from '../ui/toaster/useToaster';
export function InviteUserModal(props) {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [inviteNewUser, { isLoading }] = useInviteNewUserMutation();
    const toast = useToaster();
    const onSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
        if (!email) {
            return;
        }
        try {
            const resp = await inviteNewUser({
                newUserEmail: email,
                newUserOrgId: props.organizaion.id,
                newUserRole: UserRole.USER,
                message
            })
                .unwrap();
            toast({
                title: 'Invitation sent!',
                message: `An invitation to join this organization has been sent to ${email}.`,
                type: 'success'
            });
            props.onClose();
        }
        catch (e) {
            setErrorMessage(e.data?.message ?? 'An error occured. Please refresh and try again.');
        }
    };
    return (_jsx(Modal, { isOpen: props.isOpen, onClose: props.onClose, title: "Invite New User", subtitle: "Please enter an email address to invite someone to this organization.", children: _jsxs("form", { onSubmit: onSubmit, children: [_jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "email", children: _jsx("span", { className: "label-text", children: "Email Address" }) }), _jsx(Input, { id: "email", type: "email", placeholder: "user@website.com", value: email ?? '', onChange: (e) => setEmail(e.target.value) })] }), _jsxs("p", { className: "py-4 form-control w-full", children: [_jsx("label", { className: "label", htmlFor: "message", children: _jsx("span", { className: "label-text", children: "Message (optional)" }) }), _jsx(Textarea, { id: "message", className: "h-24", placeholder: "Optional", onChange: (e) => setMessage(e.target.value) })] }), errorMessage && _jsx("div", { className: "text-red-700 text-center", children: errorMessage }), _jsxs("div", { className: "mt-4 text-right space-x-4", children: [_jsx(Button, { role: "secondary", border: false, onClick: props.onClose, children: "Cancel" }), _jsx(Button, { type: "submit", disabled: !email, children: isLoading ? _jsx(Spinner, {}) : 'Submit' })] })] }) }));
}
