import { api } from './api';
export const supportApi = api.injectEndpoints({
    endpoints: (build) => ({
        createTicket: build.mutation({
            query: (payload) => ({
                url: 'support/ticket',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Support']
        }),
        createMessage: build.mutation({
            query: (payload) => ({
                url: `support/ticket/${payload.id}/message`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Support']
        }),
        getTickets: build.query({
            query: (filter) => `support/tickets?status=${filter?.status}`,
            providesTags: ['Support']
        }),
        getTicket: build.query({
            query: (id) => `support/ticket/${id}`,
            providesTags: ['Support']
        }),
    })
});
export const { useCreateTicketMutation, useCreateMessageMutation, useGetTicketsQuery, useGetTicketQuery } = supportApi;
export default supportApi;
