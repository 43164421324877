import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Alert, Spinner } from '../ui';
import { useStartPasswordResetMutation } from '../../app/api/authApi';
import { useAppSelector } from '../../app/hooks';
import { selectIsAuthenticated } from './authSlice';
import { DEFAULT_PRIVATE_ROUTE } from 'src/client/app/app.consts';
export function ForgotPassword() {
    const [doResetPassword, { isLoading }] = useStartPasswordResetMutation();
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    if (useAppSelector(selectIsAuthenticated)) {
        return _jsx(Navigate, { replace: true, to: DEFAULT_PRIVATE_ROUTE });
    }
    const submit = async (e) => {
        e.preventDefault();
        try {
            await doResetPassword(email).unwrap();
            setSuccess(true);
        }
        catch (e) {
            console.log(e);
            setError(e.data?.message ?? 'An error occured. Please refresh and try again.');
        }
    };
    return (_jsxs("div", { className: "space-y-4", children: [_jsx("h1", { className: "text-xl font-bold leading-tight tracking-tight md:text-2xl text-white", children: "Reset Password" }), _jsx("p", { className: "text-gray-300", children: "To reset your password, please enter your email address below." }), _jsxs("form", { className: "space-y-4 md:space-y-6", onSubmit: submit, children: [(error && !success) && _jsx(Alert, { type: "error", title: "Error!", children: error }), (success && !error) && _jsx(Alert, { type: "success", title: "Success!", children: "You should receive an email shortly with instructions on how to reset your password." }), (!isLoading && !success) && _jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("label", { htmlFor: "email", className: "block mb-2 text-sm font-bold text-white", children: "Your email" }), _jsx("input", { type: "email", name: "email", id: "email", className: "border text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-800", placeholder: "name@company.com", disabled: isLoading || success, required: true, defaultValue: email, onChange: (e) => setEmail(e.target.value) })] }), _jsx("button", { type: "submit", className: " w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-800 font-medium rounded-lg text-sm px-5 py-2.5", disabled: isLoading || success, children: _jsxs("div", { className: "flex flex-row justify-center", children: [isLoading && _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Working..."] }), !isLoading && _jsx(_Fragment, { children: "Submit" })] }) })] }), _jsx("div", { className: "mt-2", children: _jsx(Link, { to: "/auth/login", className: "text-sm font-medium text-primary-500 hover:underline", children: "\u00AB Back to login" }) })] })] }));
}
