import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { api } from './api/api';
import { authSlice } from '../features/auth/authSlice';
import { uiSlice } from '../features/ui/uiSlice';
import toasterReducer from '../features/ui/toaster/toasterSlice';
import siteReducer from '../features/dashboard/sites/siteSlice';
import { PERSISTENCE_ROOT_KEY } from './app.consts';
const getPersistedReducer = (slice) => persistReducer({ key: `${PERSISTENCE_ROOT_KEY}:${slice.name}`, storage }, slice.reducer);
export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        site: siteReducer,
        ui: getPersistedReducer(uiSlice),
        auth: getPersistedReducer(authSlice),
        toaster: toasterReducer,
    },
    middleware: (gDM) => gDM({ serializableCheck: false }).concat(thunk, api.middleware)
});
export const persistedStore = persistStore(store);
