export const AMERICAN_PHONE_NUMBER_WITHOUT_AREA_CODE = /^[2-9][\d]{6}$/;
export const AMERICAN_PHONE_NUMBER_WITH_AREA_CODE = /^[2-9][\d]{2}[2-9][\d]{6}$/;
export function formatPhoneNumber(phoneNumber) {
    let n = phoneNumber.toString().replaceAll(/[^\d]/g, '').trim();
    if (n.match(AMERICAN_PHONE_NUMBER_WITHOUT_AREA_CODE)) {
        return `${n.substring(0, 3)}-${n.substring(3)}`;
    }
    if (n.match(AMERICAN_PHONE_NUMBER_WITH_AREA_CODE)) {
        return `(${n.substring(0, 3)}) ${n.substring(3, 6)}-${n.substring(6)}`;
    }
    return n;
}
