import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { NoContent } from 'src/client/features/ui';
const SHORT_DATE_FORMAT = 'LLL do';
const reduceMetric = (stats, metric) => {
    return stats.reduce((t, s) => (BigInt(t) + BigInt(s[metric] || 0)), BigInt(0));
};
export function humanFileSize(sizeBytes) {
    const UNITS = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
    const BYTES_PER_KB = 1024;
    let size = Math.abs(Number(sizeBytes));
    let u = 0;
    while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
        size /= BYTES_PER_KB;
        ++u;
    }
    return new Intl.NumberFormat([], {
        style: 'unit',
        unit: UNITS[u],
        unitDisplay: 'short',
        maximumFractionDigits: 1,
    }).format(size);
}
const getMetricDeltaMarkup = (current, previous) => {
    const [c, p] = [Number(current), Number(previous)];
    if (c === p) {
        return _jsx("div", { className: "text-gray-400 italic", children: "No Change" });
    }
    if (c > p) {
        const percentage = Math.round((c - p) / p * 100);
        return _jsxs("div", { className: "text-green-600 dark:text-green-300", children: ["\u2197\uFE0E +", percentage, "% (from ", Intl.NumberFormat().format(p), ")"] });
    }
    else {
        const percentage = Math.round((p - c) / p * 100);
        return _jsxs("div", { className: "text-red-600 dark:text-red-400", children: ["\u2198\uFE0E -", percentage, "% (from ", Intl.NumberFormat().format(p), ")"] });
    }
};
export function StatsAtAGlance(props) {
    const days = props.stats.slice(0, props.days);
    const bandwidth = reduceMetric(days, 'txBytes');
    const pageViews = reduceMetric(days, 'pageViews');
    const blocks = reduceMetric(days, 'blockedVisitors');
    const previous = props.stats.slice(props.days, props.days * 2);
    const prevPageViews = reduceMetric(previous, 'pageViews');
    const prevBlocks = reduceMetric(previous, 'blockedVisitors');
    return (_jsxs(_Fragment, { children: [props.stats.length === 0 &&
                _jsx(NoContent, { className: "mt-1", icon: "exclamationCircle", title: "Error loading stats!", text: "Please refresh and try again." }), props.stats.length > 0 &&
                _jsxs("div", { className: "w-full text-center stats my-8 space-x-2 flex flex-row", children: [_jsxs("div", { className: "text-center w-1/3 space-y-1", children: [_jsx("div", { children: "Transferred Bandwidth" }), _jsx("div", { className: "text-4xl font-extrabold dark:text-white", children: humanFileSize(bandwidth) }), _jsxs("div", { className: "text-gray-400", children: [format(new Date(days[days.length - 1].endTime), SHORT_DATE_FORMAT), "\u00A0-\u00A0", format(new Date(days[0].startTime), SHORT_DATE_FORMAT)] })] }), _jsx("div", { className: "text-center w-1/3 space-y-1", children: _jsxs(_Fragment, { children: [_jsx("div", { children: "Requests" }), _jsx("div", { className: "text-4xl font-extrabold dark:text-white", children: Intl.NumberFormat().format(pageViews) }), getMetricDeltaMarkup(pageViews, prevPageViews)] }) }), _jsx("div", { className: "text-center w-1/3 space-y-1", children: _jsxs(_Fragment, { children: [_jsx("div", { children: "Threats Blocked" }), _jsx("div", { className: "text-4xl font-extrabold dark:text-white", children: Intl.NumberFormat().format(blocks) }), getMetricDeltaMarkup(blocks, prevBlocks)] }) })] })] }));
}
