import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { getEnvTagType } from '../../SiteSelector/SiteSelectorBar';
import { Tag, Button, Modal, Input, Spinner, Alert } from 'src/client/features/ui';
import { getErrorMessage } from 'src/client/app/helpers/get-error-message';
import { useDoProvisionNewEnvironmentMutation } from 'src/client/app/api/siteApi';
export function ProvisionWebsiteModal(props) {
    const [provision, { isLoading, error }] = useDoProvisionNewEnvironmentMutation();
    const defaultHostname = useMemo(() => props.website?.hostname ?? `${props.websiteProperty.slug}-${props.environment}.client-sites.wireandbyte.net`.toLowerCase(), []);
    const [hostname, setHostname] = useState(defaultHostname);
    const startProvisioning = async () => {
        const response = await provision({
            property: props.websiteProperty,
            newEnvironment: {
                environment: props.environment,
                hostname
            }
        });
        console.log('provisioning', response);
        return null;
    };
    return (_jsx(Modal, { isOpen: props.isOpen, onClose: props.onClose, children: _jsxs("div", { className: "text-gray-600 dark:text-gray-400 flex flex-col space-y-6", children: [_jsxs("p", { className: "text-xl", children: ["Provision new ", _jsx(Tag, { type: getEnvTagType(props.environment), children: props.environment }), " environment"] }), _jsx("p", { children: "Please enter a hostname below and click \"Start Provisioning\" to set up your environment. It can take a few minutes to complete." }), _jsx(Input, { type: "text", name: "hostname", placeholder: "hostname", required: true, defaultValue: hostname, onChange: (e) => setHostname(e.target.value) }), error &&
                    _jsxs(Alert, { type: "error", children: [_jsx("strong", { children: "Error" }), " ", getErrorMessage(error)] }), _jsxs("div", { className: "mt-4 text-center space-x-4 bg-gray-200 dark:bg-gray-700/50 -m-4 rounded-b py-4 px-2", children: [_jsx(Button, { role: "secondary", border: false, onClick: props.onClose, children: "Cancel" }), _jsx(Button, { role: "primary", onClick: startProvisioning, disabled: isLoading || !hostname || !!error, children: _jsx("span", { className: "flex items-center", children: isLoading ? _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Working..."] }) : _jsx(_Fragment, { children: "Start Provisioning \u00BB" }) }) })] })] }) }, "provision-1848"));
}
