import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { UserRole, UserActionPermission } from 'src/shared/types.g';
import { Title, Radio, Tag } from '../ui';
import { getEnvTagType } from '../dashboard/sites/SiteSelector/SiteSelectorBar';
import { canUserEditUser } from 'src/client/app/helpers/can-edit-user';
export function OrganizationUserWebsitePermissions(props) {
    const userMembership = props.memberships.find(m => m.userId === props.user.id);
    const canEditUser = useMemo(() => userMembership !== undefined && canUserEditUser(props.currentUser, props.memberships, props.user, [userMembership], props.organization), [props]);
    if (props.user.isSuperAdmin) {
        return _jsx("div", { className: "italic py-4", children: "This user is an administrator and has full access to all websites." });
    }
    if ([UserRole.ORGADMIN, UserRole.ORGOWNER].some(r => r === userMembership?.role)) {
        return _jsx("div", { className: "italic py-4", children: "This user is an organization administrator and has full access to all websites." });
    }
    return _jsx(_Fragment, { children: props.websites.map(property => (_jsxs("div", { children: [_jsx(Title, { level: 4, className: "mb-4", children: property.name }), property.Websites &&
                    _jsxs("table", { className: "w-full text-sm text-left text-gray-500 dark:text-gray-400", children: [_jsx("thead", { className: "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-4", children: _jsxs("tr", { children: [_jsx("th", { children: "Environment" }), _jsx("th", { children: "Hostname" }), _jsx("th", { children: "No Access" }), _jsx("th", { children: "Read-Only" }), _jsx("th", { children: "Read + Write" })] }) }), _jsx("tbody", { children: property.Websites.map((website) => (_jsxs("tr", { className: "bg-white border-b dark:bg-gray-800 dark:border-gray-700", children: [_jsx("td", { className: "whitespace-nowrap", children: _jsx(Tag, { type: getEnvTagType(website.environment), size: "small", children: website.environment }) }), _jsx("td", { children: _jsx("div", { className: "text-xs italic whitespace-nowrap", children: website.hostname }) }), _jsx("td", { children: _jsx(Radio, { disabled: !canEditUser, name: website.id, value: "" }) }), _jsx("td", { children: _jsx(Radio, { disabled: !canEditUser, name: website.id, value: UserActionPermission.READ_ONLY }) }), _jsx("td", { children: _jsx(Radio, { disabled: !canEditUser, name: website.id, value: UserActionPermission.READ_WRITE }) })] }, website.id))) })] }), !property.Websites &&
                    _jsx("div", { className: "italic", children: "No environments found." })] }, property.id))) });
}
