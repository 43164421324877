import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useGetWordPressSecurityScanWithFindingsQuery } from 'src/client/app/api/siteApi';
import { useAppSelector } from 'src/client/app/hooks';
import { Card, Select, Title, Spinner, Alert, Icon } from 'src/client/features/ui';
import { getSelectedSite } from '../siteSlice';
import { useParams } from 'react-router-dom';
import { WebsiteScanFindingSeverity } from 'src/shared/types.g';
import { getScanFindingAggregateCounts } from './WordpressSecurity';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { format, formatDistanceToNow } from 'date-fns';
import { FindingAggregateCountBarGraph } from './FindingAggregateCountBarGraph';
export function WordPressSecurityScanDetails() {
    const { scanId } = useParams();
    const [filter, setFilter] = useState();
    const [findings, setFindings] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [aggregatedCounts, setAggregatedCounts] = useState();
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetWordPressSecurityScanWithFindingsQuery({ website: selectedSite.Website, scanId: scanId }, { skip: selectedSite.Website === undefined || scanId === undefined });
    useEffect(() => {
        if (data?.WebsiteScanOperationFinding) {
            const f = data.WebsiteScanOperationFinding
                .filter((f) => (filter ? (f.severity === filter) : true));
            setFindings(f);
        }
    }, [data, filter]);
    useEffect(() => {
        if (findings && findings.length) {
            const aggregate = getScanFindingAggregateCounts(data.WebsiteScanOperationFinding);
            setAggregatedCounts(aggregate);
        }
    }, [findings]);
    useEffect(() => {
        if (aggregatedCounts) {
            const options = [
                { label: `ALL FINDINGS (${data?.WebsiteScanOperationFinding.length})`, value: '' },
                ...Object.values(WebsiteScanFindingSeverity)
                    .filter(v => aggregatedCounts && aggregatedCounts[v])
                    .map(v => ({ label: `${v} (${aggregatedCounts ? aggregatedCounts[v] : 0})`, value: v }))
            ];
            setFilterOptions(options);
        }
        else {
            setFilterOptions([]);
        }
    }, [aggregatedCounts]);
    const getSubtitleFromDate = (date) => {
        const dateString = format(new Date(date), DEFAULT_DATE_FORMAT);
        const timeAgo = formatDistanceToNow(new Date(date), { addSuffix: true });
        return _jsxs(_Fragment, { children: ["Scanned ", _jsx("abbr", { title: dateString, children: timeAgo })] });
    };
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx(Title, { level: 2, children: "WordPress Security \u00BB Scan Details" }), isLoading && _jsx(Spinner, {}), error && _jsx(Alert, { type: "warning", children: "An error occurred. Please refresh and try again." }), data && _jsxs(_Fragment, { children: [_jsx(Card, { title: "Scan Results Overview", subtitle: getSubtitleFromDate(data.startedAt ?? data.endedAt), children: (aggregatedCounts && data.WebsiteScanOperationFinding) &&
                            _jsxs("div", { className: "flex flex-col space-y-4 text-center py-12", children: [_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs("strong", { children: ["There were ", data.WebsiteScanOperationFinding.length, " items found during this scan."] }), aggregatedCounts?.CRITICAL > 0 &&
                                                _jsxs("div", { className: "flex items-center justify-center space-x-2 mb-4 mx-auto py-2 px-8 bg-red-100 dark:bg-red-600/50 rounded", children: [_jsx(Icon, { icon: "warning", className: "w-6 h-6 inline" }), _jsxs("span", { children: [aggregatedCounts.CRITICAL, " critical", aggregatedCounts.CRITICAL === 1 ? ' item needs ' : ' items need ', "to be fixed immediately!"] })] }), _jsx("span", { className: "text-gray-400", children: "Scroll down to see details for all findings." })] }), _jsx("div", { className: "md:w-4/5 mx-auto py-4", children: _jsx(FindingAggregateCountBarGraph, { aggregateCounts: aggregatedCounts }) })] }) }), _jsx("div", { className: "mb-4 flex flex-row space-x-2 items-center align-items-start", children: _jsx("div", { children: _jsx(Select, { onChange: (e) => setFilter(e.currentTarget.value ? e.currentTarget.value : undefined), className: "w-60", options: filterOptions }) }) }), _jsx("div", { className: "space-y-4", children: findings && findings.map((finding) => _jsx(Card, { title: finding.title, subtitle: finding.severity === 'INFORMATIONAL' ? 'Informational' : `${finding.severity} PRIORITY`, children: _jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("p", { children: [(finding.componentType === 'PLUGIN' && finding.updateAvailable) && _jsxs(_Fragment, { children: ["The plugin ", finding.componentName, " needs an update!"] }), (finding.componentType === 'PLUGIN' && !finding.updateAvailable) && _jsxs(_Fragment, { children: ["The plugin ", finding.componentName, " is up to date."] })] }), finding.description && _jsx("p", { children: finding.description }), finding.remediation && _jsx("p", { children: finding.remediation }), !finding.remediation &&
                                        _jsxs("p", { children: [finding.componentVersion && _jsxs("small", { children: ["Current version installed is ", _jsx("strong", { children: finding.componentVersion }), ". "] }), finding.updateVersion && _jsxs("small", { children: ["You should upgrade this to ", _jsx("strong", { children: finding.updateVersion }), "."] })] })] }) }, finding.id)) })] })] }));
}
