import { jsx as _jsx } from "react/jsx-runtime";
const baseClasses = 'uppercase rounded-full ring-1 ring-inset';
const typeClasses = {
    regular: 'text-blue-400 bg-blue-400/10 ring-blue-400/30',
    important: 'text-red-400 bg-red-400/10 ring-red-400/30',
    success: 'text-green-400 bg-green-400/10 ring-green-400/20',
    info: 'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
};
const sizeClasses = {
    regular: 'text-sm font-medium py-2 px-4',
    small: 'text-xs font-medium text-xs p-1 px-3',
};
const defaultTagProps = {
    type: 'regular',
    size: 'regular',
};
export function Tag(props) {
    const mergedProps = { ...defaultTagProps, ...props };
    return _jsx("span", { className: `${baseClasses} ${typeClasses[mergedProps.type ?? 'regular']} ${sizeClasses[mergedProps.size ? mergedProps.size : 'regular']} ${mergedProps.className}
    `, title: props.title, children: props.children });
}
