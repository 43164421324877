import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'src/client/features/ui';
import { useState, useCallback } from 'react';
import { SiteSelector } from './SiteSelector';
const defaultProps = {
    isOpen: false,
    allowCancel: true,
    onClose: () => null,
    onSelect: () => null,
};
export function SiteSelectorModal(props = defaultProps) {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const doClose = useCallback(() => {
        setIsOpen(false);
        if (props.onClose !== undefined) {
            props.onClose();
        }
    }, []);
    return (_jsx(Modal, { isOpen: isOpen, onClose: doClose, useInnerCard: false, children: _jsx("div", { className: "md:w-[32rem]", children: _jsx(SiteSelector, { allowCancel: props.allowCancel, onClose: props.onClose, onSelect: props.onSelect }) }) }));
}
