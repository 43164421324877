import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export function Toggle(props) {
    const disabled = props.disabled === true;
    const classes = disabled ?
        'peer-checked:bg-blue-200 dark:peer-checked:bg-blue-900 peer-checked:after:border-blue-400/50 after:bg-blue-400/50 dark:peer-checked:after:border-gray-600 dark:peer-checked:after:bg-blue-950/25 peer-checked:after:bg-blue-950/25 dark:after:bg-gray-700 after:bg-gray-300 dark:after:border-gray-600 after:border-gray-100' :
        'peer-checked:bg-blue-600 peer-checked:after:border-white after:bg-white after:border-gray-300 ';
    return (_jsx(_Fragment, { children: _jsxs("label", { className: `relative inline-flex items-center ${disabled ? '' : 'cursor-pointer'}`, title: disabled ? 'This option is read-only or locked, and can not be changed' : props.label, children: [_jsxs("div", { className: "relative", children: [_jsx("input", { type: "checkbox", disabled: disabled, value: "", className: "sr-only peer", onChange: (e) => props.onChange(e.target.checked), checked: props.checked }), _jsx("div", { className: `
                        w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4
                        rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full
                        after:content-['']
                        after:absolute after:top-[2px] after:left-[2px]
                        after:border after:rounded-full after:h-5 after:w-5
                        after:transition-all dark:border-gray-600 
                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                        ${classes}
                    ` })] }), props.label &&
                    _jsxs("span", { className: "ml-3 label !mb-0", children: [props.label, props.description && _jsx("div", { className: "italic font-normal text-xs", children: props.description })] })] }) }));
}
