import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { DEFAULT_SHORT_DATE_FORMAT } from 'src/client/app/app.consts';
import { useGetSiteMonitorQuery } from 'src/client/app/api/siteApi';
import { useGetPlatformStatusQuery } from 'src/client/app/api/platformApi';
import { WebsiteMonitorStatus } from 'src/shared/types.g';
import { Spinner } from 'src/client/features/ui';
const classes = (state) => {
    switch (state) {
        case WebsiteMonitorStatus.OK:
            return 'text-green-400 bg-green-700/50';
        case WebsiteMonitorStatus.WARNING:
            return 'text-orange-400 bg-orange-400/30';
        case WebsiteMonitorStatus.ERROR:
            return 'text-rose-400 bg-rose-400/10';
    }
    return 'text-slate-400 bg-slate-400/10';
};
const getWebsiteMonitorVendorName = (monitor) => {
    if (monitor.vendor === 'UPTIME_ROBOT') {
        return 'Uptime Robot';
    }
    return 'Wire & Byte';
};
export function HealthStatus(props) {
    const { data: platformStatusData, error, isLoading: isPlatformStatusLoading } = useGetPlatformStatusQuery();
    const { data: websiteMonitorData, isLoading: isWebsiteMonitorLoading } = useGetSiteMonitorQuery(props.website);
    return _jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col space-y-6 p-4", children: [(isPlatformStatusLoading || isWebsiteMonitorLoading) && _jsx(Spinner, {}), websiteMonitorData &&
                    _jsxs("div", { className: "flex items-center space-x-2 uppercase text-sm", children: [_jsx("div", { className: `${classes(websiteMonitorData.status)} flex-none rounded-full p-1`, children: _jsx("div", { className: "h-2 w-2 rounded-full bg-current" }) }), _jsxs("div", { children: ["Website Uptime", _jsx("br", {}), _jsxs("span", { className: "text-xs block text-gray-400", children: ["Monitored by ", getWebsiteMonitorVendorName(websiteMonitorData)] }), (websiteMonitorData.WebsiteMonitorStatusLog.length > 0) &&
                                        _jsxs("span", { className: "text-xs block text-gray-400", children: ["Last changed ", format(new Date(websiteMonitorData.WebsiteMonitorStatusLog[0].createdAt), DEFAULT_SHORT_DATE_FORMAT)] })] })] }, websiteMonitorData.id), platformStatusData && Object.keys(platformStatusData).map((component, key) => (_jsxs("div", { className: "flex items-center space-x-2 uppercase text-sm", children: [_jsx("div", { className: `${classes(platformStatusData[component])} flex-none rounded-full p-1`, children: _jsx("div", { className: "h-2 w-2 rounded-full bg-current" }) }), _jsx("span", { children: component })] }, key)))] }) });
}
