import { jsx as _jsx } from "react/jsx-runtime";
export function Checkbox(props) {
    const { className, ...remainingProps } = props;
    return (_jsx("input", { type: "checkbox", className: `
            w-4 h-4 rounded 
            text-blue-600 bg-gray-100 border-gray-300
            dark:bg-gray-700 dark:border-gray-600
            focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 
            disabled:text-gray-600 disabled:bg-gray-400 
            ${className}
        `, ...remainingProps }));
}
