import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Input } from "src/client/features/ui";
export function SiteSelectorFilter(props) {
    useEffect(() => props.onChange(props.properties), [props.properties]);
    const doFilter = (e) => {
        if (e.target.value) {
            const search = e.target.value.toLowerCase();
            const filtered = props.properties.filter(p => {
                if (p.name.toLowerCase().includes(search)) {
                    return true;
                }
                return p.Websites.some((site) => (site.hostname.toLowerCase().includes(search)));
            });
            return props.onChange(filtered);
        }
        return props.onChange(props.properties);
    };
    return (_jsx(_Fragment, { children: (props.hideWhenFewerThan === undefined || props.hideWhenFewerThan < props.properties.length) &&
            _jsx("div", { className: "mt-2 bg-gray-100 dark:bg-gray-900 rounded-lg", children: _jsx(Input, { type: "text", placeholder: "Filter sites...", className: "p-2 rounded-lg", onChange: doFilter }) }) }));
}
