import { createSlice } from '@reduxjs/toolkit';
const initialState = { theme: 'dark' };
export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.theme = state.theme === 'dark' ? 'light' : 'dark';
        }
    }
});
export const { toggleTheme } = uiSlice.actions;
export const selectGetTheme = (state) => state.ui.theme;
export default uiSlice.reducer;
