import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getSelectedSite } from '../siteSlice';
import { useAppSelector } from 'src/client/app/hooks';
import { useDoPurgeServerCacheMutation } from 'src/client/app/api/siteApi';
import { Alert, Button, Card, Spinner } from 'src/client/features/ui';
import { useToaster } from 'src/client/features/ui/toaster/useToaster';
export function PurgeCache() {
    const selectedSite = useAppSelector(getSelectedSite);
    const toast = useToaster();
    const [doPurgeCache, { isLoading }] = useDoPurgeServerCacheMutation();
    const purge = async () => {
        await doPurgeCache(selectedSite.Website).unwrap();
        toast({
            title: 'Cache has been purged!',
            message: 'The server cache has been successfully purged.',
            type: 'success'
        });
    };
    return (_jsxs(Card, { title: "Purge Server Cache (Varnish)", children: [_jsx(Alert, { type: "info", title: "Please note:", children: "Purging this cache could temporary degrade performance of your website." }), _jsx("div", { className: "flex space-x-4 items-center justify-center", children: _jsxs(Button, { onClick: purge, disabled: isLoading, role: "primary", children: [!isLoading && 'Purge Server Cache', isLoading && _jsxs(_Fragment, { children: [_jsx(Spinner, {}), " Purging..."] })] }) })] }));
}
