import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Environment } from 'src/shared/types.g';
import { getEnvTagType } from '../../SiteSelector/SiteSelectorBar';
import { Tag, Icon, Dropdown, Modal, Spinner } from 'src/client/features/ui';
import { useGetTasksByTypeQuery } from 'src/client/app/api/siteApi';
import { ProvisionWebsiteModal } from './ProvisionWebsiteModal';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { format } from 'date-fns';
const GetMessageFromTask = (task) => {
    if (task.state === 'COMPLETED') {
        return _jsxs("em", { children: ["Provisioned on ", format(new Date(task.updatedAt), DEFAULT_DATE_FORMAT), "."] });
    }
    if (task.state === 'NOT_STARTED') {
        return _jsxs("div", { className: "flex items-center", children: [_jsx(Spinner, {}), " Queued for provisioning since ", format(new Date(task.createdAt), DEFAULT_DATE_FORMAT), "."] });
    }
    if (task.state === 'IN_PROGRESS') {
        return _jsxs("em", { children: [_jsx(Spinner, {}), " Provisioning in progress, since ", format(new Date(task.createdAt), DEFAULT_DATE_FORMAT), "."] });
    }
    if (task.state === 'FAILED') {
        return _jsxs(_Fragment, { children: [_jsx("strong", { children: "An error occured while provisioning!" }), " If this problem persists, please contact Support."] });
    }
    return _jsx(_Fragment, {});
};
export function EnvironmentItem(props) {
    const [isProvisioning, setIsProvisioning] = useState(false);
    const [isPurging, setIsPurging] = useState(false);
    const isProvisioned = !!props.website?.hostname;
    const onCloseProvisioningModal = () => setIsProvisioning(false);
    const { data: provisioningTasksForSite } = useGetTasksByTypeQuery({
        website: props.website,
        taskType: 'CREATE_NEW_WEBSITE'
    }, { skip: props.website === undefined });
    return (_jsxs(_Fragment, { children: [_jsx(ProvisionWebsiteModal, { ...props, isOpen: isProvisioning, onClose: onCloseProvisioningModal }, "asdf45"), _jsx(Modal, { isOpen: isPurging, onClose: () => setIsPurging(false), children: "IS PURGING!" }), _jsxs("div", { className: "my-8 p-4 last:mb-2 rounded bg-gray-100 dark:bg-gray-700/50 flex flex-row items-center justify-between", children: [_jsxs("div", { children: [_jsx(Tag, { type: getEnvTagType(props.environment), size: "small", children: props.environment }), _jsx("div", { className: "mt-2", children: isProvisioned ? _jsx(_Fragment, { children: props.website?.hostname }) : _jsx("em", { children: "This environment has not been provisioned." }) }), (provisioningTasksForSite && provisioningTasksForSite.length > 0) &&
                                _jsx(GetMessageFromTask, { ...provisioningTasksForSite[0] })] }), _jsx("div", { children: _jsx(Dropdown, { icon: "menu", title: "Actions", className: "bg-gray-200 dark:bg-gray-800", children: _jsx("div", { className: "rounded w-44", children: isProvisioned ?
                                    _jsxs(_Fragment, { children: [_jsxs("div", { className: `px-4 py-2 pr-10 flex flex-row space-x-4 items-center ${props.environment !== Environment.PRODUCTION ? 'cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-600' : 'text-gray-300 dark:text-gray-500'}`, children: [_jsx(Icon, { icon: "list", className: "w-4 h-4 mr-2" }), " Clone To..."] }), _jsxs("div", { className: "px-4 py-2 pr-10 cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-600 flex flex-row items-center", onClick: () => setIsPurging(true), children: [_jsx(Icon, { icon: "trash", className: "w-4 h-4 mr-2" }), " Purge Data"] })] }) :
                                    _jsxs("div", { className: "px-4 py-2 pr-10 cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-600 flex flex-row items-center", onClick: () => setIsProvisioning(true), children: [_jsx(Icon, { icon: "plusCircle", className: "w-4 h-4 mr-2" }), " Provision..."] }) }) }) })] })] }));
}
