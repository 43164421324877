import { jsx as _jsx } from "react/jsx-runtime";
export function Input(props) {
    const { className, ...remainingProps } = props;
    return (_jsx("input", { className: `
                border sm:text-sm focus:border-blue-500 block w-full p-2.5
                bg-white border-gray-100 placeholder-gray-400 text-gray-600 focus:ring-brand-primary
                dark:bg-gray-700 dark:border-gray-600 dark:text-white
                disabled:bg-gray-200 disabled:text-gray-500 disabled:dark:bg-gray-700/50 disabled:dark:text-gray-400 
                ${className}
            `, ...remainingProps }));
}
