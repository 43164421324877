import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, Navigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'src/client/app/api/userApi';
import { Sidebar } from '../Sidebar';
import { DEFAULT_PRIVATE_ROUTE } from 'src/client/app/app.consts';
import { AllIcons } from '../../ui/icon/AllIcons';
import { AdminTools } from './AdminTools';
import { AgentStatus } from './AgentStatus';
import { Alert } from '../../ui';
export const navLinks = [
    {
        name: 'Admin Tools',
        icon: '',
        url: 'tools',
        collapsable: false,
        children: [
            { name: 'All Tools', url: '' }
        ]
    }
];
export function Admin() {
    const { data: user, isLoading } = useGetCurrentUserQuery();
    if (!user?.isSuperAdmin) {
        return (_jsx(Navigate, { to: DEFAULT_PRIVATE_ROUTE }));
    }
    return (_jsxs("div", { className: "flex flex-row flex-grow", children: [_jsx(Sidebar, { baseUrl: "/dashboard/admin", links: navLinks }), _jsxs("div", { className: "grow p-4 pt-0", children: [_jsx("div", { className: "p-4", children: _jsx(Alert, { type: "error", title: "Warning: you are in ADMIN mode!", children: _jsx("div", { children: "You can easily break client websites. Please be very careful." }) }) }), _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "tools" }) }), _jsx(Route, { path: "tools", element: _jsx(AdminTools, {}) }), _jsx(Route, { path: "tools/agent-status", element: _jsx(AgentStatus, {}) }), _jsx(Route, { path: "tools/ui-icons", element: _jsx(AllIcons, {}) })] })] })] }));
}
