import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { rootRouter } from './router';
import { persistedStore, store } from './app/store';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.scss';
const container = document.getElementById('root');
const root = createRoot(container);
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? undefined;
const NODE_ENV = process.env.NODE_ENV ?? 'development';
if (SENTRY_DSN !== undefined && NODE_ENV === 'production') {
    Sentry.init({
        dsn: SENTRY_DSN,
        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        integrations: [new BrowserTracing()],
        environment: process.env.NODE_ENV || '',
        tracesSampleRate: 1.0,
    });
}
root.render(_jsx(React.StrictMode, { children: _jsx(GoogleOAuthProvider, { clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? '', children: _jsx(Provider, { store: store, children: _jsx(PersistGate, { loading: 'Loading...', persistor: persistedStore, children: _jsx(RouterProvider, { router: rootRouter }) }) }) }) }));
reportWebVitals();
