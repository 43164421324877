import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Icon } from "./icon/Icon";
export function Card(props) {
    const [collapsed, collapse] = useState(props.collapsed || false);
    return (_jsxs("div", { className: `card rounded shadow-md w-full h-auto p-4 bg-white dark:bg-gray-800 ${props.className}`, children: [(props.title || props.collapsable) &&
                _jsxs("div", { className: `card-title mb-2 border-gray-300 dark:border-gray-700 flex justify-between ${collapsed ? '-mb-1' : 'border-b pb-2'}`, children: [props.title && _jsx("h4", { className: "uppercase text-md font-bold w-full", children: props.title }), props.collapsable &&
                            _jsx("button", { className: `
                                rounded-full p-1 cursor-pointer border bg-gray-800/25
                                dark:text-gray-400 dark:border-gray-500
                                dark:hover:text-white dark:hover:border-white hover:shadow-xl
                                ${collapsed ? '' : 'rotate-180'}
                            `, onClick: () => collapse(!collapsed), children: _jsx(Icon, { className: "w-4 h-4", icon: "chevronDown" }) })] }), props.subtitle &&
                (_jsx("span", { className: "text-sm block text-gray-400", children: props.subtitle })), _jsx("div", { className: `transition-height duration-500 ease-in-out ${collapsed ? 'h-0 overflow-hidden' : ''}`, children: props.children })] }));
}
