import { jsx as _jsx } from "react/jsx-runtime";
export function Select(props) {
    const { options, className, ...remainingProps } = props;
    const classes = `
    border text-gray-600 sm:text-sm focus:border-blue-500 block w-full p-2.5
    bg-white border-gray-100 placeholder-gray-400 text-gray-600 focus:ring-brand-primary
    dark:bg-gray-700 dark:border-gray-600 dark:text-white ${className}
    `;
    return (_jsx("select", { className: classes, ...remainingProps, children: options && options.map((option, key) => (_jsx("option", { value: option.value, children: option.label }, key))) }));
}
