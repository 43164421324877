import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, Navigate, useLocation } from 'react-router';
import { DEFAULT_PRIVATE_ROUTE, DEFAULT_PUBLIC_ROUTE } from './app/app.consts';
import { selectIsAuthenticated } from './features/auth/authSlice';
import { selectGetTheme } from './features/ui/uiSlice';
import { useAppSelector } from './app/hooks';
import "./App.scss";
export function App() {
    const location = useLocation();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const theme = useAppSelector(selectGetTheme);
    if (location.pathname === '/') {
        const to = isAuthenticated ? DEFAULT_PRIVATE_ROUTE : DEFAULT_PUBLIC_ROUTE;
        return _jsx(Navigate, { to: to });
    }
    return (_jsx("div", { className: `App ${theme}`, children: _jsx(Outlet, {}) }));
}
