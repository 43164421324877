import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Card, Information, Button, Toggle } from "src/client/features/ui";
import { LastChanged } from '../../Advanced/LastChanged';
export function AutomaticWordPressUpdates(props) {
    const [isEnabled, _setIsEnabled] = useState(false);
    const [updatePlugins, setUpdatePlugins] = useState(false);
    const [updateThemes, setUpdateThemes] = useState(false);
    const [updateCore, setUpdateCore] = useState(false);
    useEffect(() => {
        if (props.config !== undefined) {
            const configValue = (props.config.configValue || {});
            if (configValue.updatePlugins !== undefined) {
                setUpdatePlugins(configValue.updatePlugins);
            }
            if (configValue.updateThemes !== undefined) {
                setUpdateThemes(configValue.updateThemes);
            }
            if (configValue.updateCore !== undefined) {
                setUpdateCore(configValue.updateCore);
            }
            _setIsEnabled(!!props.config?.isEnabled);
        }
    }, [props.config]);
    const submit = (v) => {
        const configValue = Object.fromEntries(Object.entries({
            updatePlugins,
            updateThemes,
            updateCore
        }).filter(([_, v]) => v != null));
        const config = {
            configField: props.configName,
            configValue: Object.keys(configValue).length > 0 ? configValue : null,
            isEnabled: v ?? isEnabled
        };
        props.onChange(config);
    };
    const setIsEnabled = (value) => {
        _setIsEnabled(value);
        submit(value);
    };
    return (_jsxs(Card, { title: "Automatic Updates", collapsable: true, children: [_jsx(Information, { children: _jsxs("div", { className: "space-y-4", children: [_jsx("p", { children: "It's very important to keep WordPress, plugins, and themes up-to-date. By enabling the options below, you can have WordPress upgrades automatically applied." }), props.website.repositoryId &&
                            _jsxs("p", { children: [_jsx("strong", { children: "Important:" }), " This website is linked to a Git repository. Automatic updates will be committed to a new branch and then merged into the branch currently running on this website."] })] }) }), _jsxs("div", { className: "space-y-4", children: [_jsx("div", { className: "flex items-center", children: _jsx(Toggle, { checked: isEnabled, onChange: setIsEnabled, label: "Enable automatic WordPress updates?", disabled: props.config !== undefined && props.config.isLocked }) }), !isEnabled &&
                        _jsxs(_Fragment, { children: [_jsx("div", { className: "bg-gray-100 dark:bg-gray-900/25 rounded rounded-lg text-gray-600 dark:text-gray-400 p-4", children: _jsx("em", { children: "Configuration options are available when this option is enabled." }) }), _jsx(LastChanged, { config: props.config })] }), isEnabled &&
                        _jsxs("div", { className: "bg-gray-100 dark:bg-gray-900/25 rounded rounded-lg text-gray-600 dark:text-gray-400", children: [_jsx("header", { className: "border-b border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-400 font-bold text-sm uppercase p-4", children: "Additional Options" }), _jsxs("div", { className: "flex flex-col space-y-6 p-8", children: [_jsx(Toggle, { checked: updatePlugins, onChange: setUpdatePlugins, label: "Automatically update plugins?", disabled: props.config !== undefined && props.config.isLocked }), _jsx(Toggle, { checked: updateThemes, onChange: setUpdateThemes, label: "Automatically update themes?", disabled: props.config !== undefined && props.config.isLocked }), _jsx(Toggle, { checked: updateCore, onChange: setUpdateCore, label: "Automatically update WordPress core?", description: "Minor versions only", disabled: props.config !== undefined && props.config.isLocked })] }), _jsxs("footer", { className: "flex flex-row justify-between bg-gray-200 dark:bg-gray-900/25 rounded-b rounded-b-lg p-4", children: [_jsx(LastChanged, { config: props.config }), _jsx(Button, { disabled: props.config !== undefined && props.config.isLocked, onClick: () => submit(), children: "Save Options" })] })] })] })] }));
}
