import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { useGetWordPressUpdateActivityQuery, useGetFeatureConfigQuery } from 'src/client/app/api/siteApi';
import { useAppSelector } from 'src/client/app/hooks';
import { Card, Spinner, NoContent } from 'src/client/features/ui';
import { getSelectedSite } from '../siteSlice';
import { DEFAULT_DATE_FORMAT } from 'src/client/app/app.consts';
import { classNames } from 'src/client/app/helpers/classNames';
import { WebsiteScanFindingSeverity, WebsiteFeatureConfigFields } from 'src/shared/types.g';
export function WordPressUpdates() {
    const selectedSite = useAppSelector(getSelectedSite);
    const { data, error, isLoading } = useGetWordPressUpdateActivityQuery(selectedSite.Website, { skip: selectedSite.Website === undefined });
    const { data: featureConfig } = useGetFeatureConfigQuery({
        website: selectedSite.Website,
        configField: WebsiteFeatureConfigFields.WORDPRESS_AUTO_UPDATES
    }, { skip: selectedSite.Website === undefined });
    return _jsxs(_Fragment, { children: [featureConfig?.config.WORDPRESS_AUTO_UPDATES?.isEnabled &&
                _jsxs(Card, { title: "Update Activity Feed", children: [isLoading && _jsx(Spinner, {}), _jsx("div", { className: "flex flex-col space-y-4", children: !isLoading && data && data.map((update, i) => _jsxs("div", { className: "flex flex-row space-x-4 items-center", children: [_jsx("div", { className: classNames(update.updatedOk ? 'text-green-400 bg-green-700/50' : 'text-rose-400 bg-rose-400/10', 'flex-none rounded-full p-1'), title: update.updatedOk ? 'Updated' : 'Error', children: _jsx("div", { className: "h-2 w-2 rounded-full bg-current" }) }), _jsxs("div", { className: "text-gray-400", children: [_jsxs("div", { className: "text-white", children: [update.updatedOk ? 'Updated' : 'Error updating', "\u00A0", update.componentType.toLowerCase(), "\u00A0", _jsx("span", { className: "truncate bg-gray-600/25 rounded font-mono", children: update.componentName }), "\u00A0 from version ", _jsx("span", { className: "truncate bg-gray-600/25 rounded font-mono", children: update.componentOldVersion }), "\u00A0 to version ", _jsx("span", { className: "truncate bg-gray-600/25 rounded font-mono", children: update.componentNewVersion }), _jsx("br", {})] }), _jsx("small", { className: "text-sm", children: format(new Date(update.updatedAt), DEFAULT_DATE_FORMAT) }), update.fixesScanFinding && update.fixesScanFinding.severity !== WebsiteScanFindingSeverity.INFORMATIONAL &&
                                                (_jsxs("div", { className: "text-sm italic", children: ["Fixes a ", _jsxs("span", { children: [update.fixesScanFinding.severity.toLowerCase(), " "] }), "security issue", update.fixesScanFinding.title && _jsxs("span", { children: [" (", update.fixesScanFinding.description, ")"] }), "."] }))] })] }, i)) })] }), !featureConfig?.config.WORDPRESS_AUTO_UPDATES?.isEnabled &&
                _jsx(NoContent, { title: "Automatic WordPress Updates", text: "Automatic updates are not enabled for this website.", icon: "wordpress", children: _jsx("div", { className: "text-center mb-8", children: "Please visit the Settings page to set up automatic updates." }) })] });
}
